<template>
    <div id="undangpenelitikeamanan">
        
          <section id="features" class="features">
      <div class="container">
        <div class="row feture-tabs">
          <div class="col-lg-12">
            <!-- <router-link class="btn btn-danger" :to="{name: 'detailProgramPemilikSistem', params:{id: localStorage.getItem("laporanpemiliksistemID")}}">Kembali</router-link> -->
            <h3>Undang Peneliti Keamanan</h3>
            <section id="detail" class="detail">
              <div class="container">
                <div class="box">
                  <multiselect 
    v-model="value" 
    :height="300"
    :options="options" 
    :multiple="true" 
    :close-on-select="false" 
    :clear-on-select="false" 
    :preserve-search="false" 
    placeholder="Pilih Peneliti" 
    label="nama" 
    track-by="user_id" 
    :preselect-first="false"
  >
    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} Peneliti Keamanan Terpilih</span></template>
  </multiselect>
  <!-- <pre class="language-json"><code>{{ value }}</code></pre> -->
  <div class="col-md-12 mt-3" v-for="listSelect in value" v-bind:key="listSelect.user_id">
    <div class="row col-lg-8">
      <div class="col-4">
    <span>{{listSelect.nama}}</span>
      </div>
      <div class="col-4">
    <button type="button"   class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="`#exampleModal`+listSelect.user_id">
      Detail 
    </button>
    <!-- <button type="button" v-for="listResearcher in researcher" v-bind:key="listResearcher.user_id" v-show="listResearcher.user_id === listSelect.user_id"  class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="`#exampleModal`">
      Detail
    </button> -->
      </div>
    </div>
  </div>
  
  <!-- <example-modal ref="modal"></example-modal> -->
  <div class="col-md-12 text-center">
    
  <button :disabled="loading === true" class="btn btn-danger mt-3" @click="cobasubmit">
    <img v-if="loading" class="loading" src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt=""/> 
    <span v-if="loading">Kirim Email</span>
    <span v-else>Kirim Email</span>
    </button>
  </div>
                </div>
              </div>
            </section>
            <table id="invited" class="table table-bordered mt-2">
              <thead class="text-white" style="background-color: #e10000">
                <tr>
                  <th>No</th>
                  <th>Nama Peneliti</th>
                  <th>Tanggal Undang</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <!-- Button trigger modal -->


<!-- Modal -->
<div v-for="listResearcher in researcher" v-bind:key="listResearcher.user_id" class="modal fade" :id="`exampleModal`+listResearcher.user_id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Detail Peneliti Keamanan</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
                <div class="row">
                  <div class="col-6">
                    <div class="row justify-content-center">
                    <img src="/img/topiputih.png" class="rounded-circle" style="width: 250px; height: 225px" />
                    </div>
                  </div>
                  <div class="col-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Nama</label>
                        <h4 class="fw-bold">  {{listResearcher.nama}}</h4>
                      </div>
                      
                      <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">
                          Alamat Surat Elektronik/<br>Nama Pengguna</label>
                        <h4  class="fw-bold">{{listResearcher.email}}</h4>
                      </div>
                      <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">
                          Jumlah Poin</label>
                        <h4 v-if="listResearcher.points" class="fw-bold">{{listResearcher.points}}</h4>
                        <h4 v-if="!listResearcher.points" class="fw-bold">0</h4>
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <table class="table">
  <thead>
    <tr>
      <th scope="col">Nama Sertifikat</th>
      <th scope="col">Tanggal Sertifikat</th>
      <th scope="col">Tipe Sertifikat</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="listCertif in cert" v-bind:key="listCertif.id" v-show="listCertif.user_id === listResearcher.user_id">
      <td>{{listCertif.nama_sertifikat}}</td>
      <td>{{listCertif.tanggal_sertifikat}}</td>
      <td>{{listCertif.tipe_sertifikat}}</td>
    </tr>
  </tbody>
</table>
                  </div>
                </div>
            
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Tutup</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div  class="modal fade" :id="`exampleModal`" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Detail Peneliti Keamanan</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
                <div class="row">
                  <div class="col-6">
                    <div class="row justify-content-center">
                    <img src="/img/topiputih.png" class="rounded-circle" style="width: 250px; height: 225px" />
                    </div>
                  </div>
                  <div class="col-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Nama</label>
                        <h4 class="fw-bold">  hthth</h4>
                      </div>
                      
                      <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">
                          Alamat Surat Elektronik/<br>Nama Pengguna</label>
                        <h4  class="fw-bold">hthth</h4>
                      </div>
                      <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">
                          Jumlah Poin</label>
                        <h4 class="fw-bold">hthth</h4>
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <!-- <table class="table">
  <thead>
    <tr>
      <th scope="col">Nama Sertifikat</th>
      <th scope="col">Tanggal Sertifikat</th>
      <th scope="col">Tipe Sertifikat</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="listCertif in cert" v-bind:key="listCertif.id" v-show="listCertif.user_id === listResearcher.user_id">
      <td>{{listCertif.cert_name}}</td>
      <td>{{listCertif.cert_date}}</td>
      <td>{{listCertif.cert_type}}</td>
    </tr>
  </tbody>
</table> -->
                  </div>
                </div>
            
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Tutup</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
     <Footer />
    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import axios from 'axios';
import $ from 'jquery';
import JSEncrypt from "jsencrypt/bin/jsencrypt";
export default {
    name: 'UndangPenelitiKeamanan',
    components: {Footer, Multiselect},
    data(){
        return{
             value: [],
             options: [],
             cert: [],
             researcher: [],
             loading: false
        }
    },
    mounted(){
        let decrypt = new JSEncrypt(); // New JSEncrypt Object
       let decrypt2 = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      let privateKeyID = `MIICWwIBAAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv9LlLFAhvMArM60egsmU4jcUL
w8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX/EesghuvDYjG1YCYIfDKm4sd
NdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5URG8Q2M7D8WRHEqpvxAgMBAAEC
gYAdzV2u7I9+gY3GzVXkoQPnUfil4oycI2zawsRb4IwIOu0hOt2mViYnEiRZY0SL
heJiGtnQroi6cHSZKkoB+xjlmUb2oK2atmIJXLQKBkmVfn7ho6lJ7Eu+ux9ZDV1H
WcT2OZchSZz7oEaargMiu6A+HNBnXKkHr8dXjtphrw/EoQJBAM3W5Fucaaaa80fI
7a5PQ6+J4e1rFOn3y8IXx9r1KGwd2eoc7Zn5LonpI9XAp2v9bNBKvKo7mtn4fScA
wXGsFgUCQQCe6RaOPXHnAiAVlZ4JsVlpRjpueUlB2gKAGqfsgHVMPQWxOsE+kwKj
oyUyQa4VyXcMX9KFwE1jFJgc/bwvu8X9AkEAmOqvBTvi5Smx2D92C4vJPexSEl39
0H8NOWkPSSPd3swiaqEJBt0ubLftrkuMVVaU8vzCwji7pnjLCSGvlDICvQJAQrKM
cAkkLJR8yPJyUZkpcQEz9zha4hPR5tjJUH+eO/CE2h1SDKSBgBMywoREwAo+OGhj
oRXUpVh4e/qXqOVENQJAHEmeKGpj9IVyBLipW9ijaRa8/TXsx62LLvydTFoDenE5
eVglVTP1p8viOTzSNXXxOw6hQdyYWdswdGClZkvirw==`;

      decrypt.setPrivateKey(privateKey);
      decrypt2.setPrivateKey(privateKeyID);
    this.getInvited()
    this.getCert()
    var self = this;
    axios
      .get("researcher/"+decrypt2.decrypt(localStorage.getItem("laporanpemiliksistemID")))
      .then(response => {
        self.options = response.data;
        console.log(self.options)
        // console.log(localStorage.getItem("laporanpemiliksistemID"))
        console.log(decrypt2.decrypt(localStorage.getItem("laporanpemiliksistemID")))
   
      })
      .catch(error => {
        alert(error);
      });
      axios
      .get("no_researcher")
      .then(response => {
        this.researcher = response.data;
        console.log(this.researcher)
   
      })
      .catch(error => {
        alert(error);
      });
    },
    methods: {
      getCert(){
        axios
      .get("cert")
      .then(response => {
        this.cert = response.data;
      })
      .catch(error => {
        alert(error);
      });
      },
        cobasubmit(){
             let decrypt = new JSEncrypt(); // New JSEncrypt Object
       let decrypt2 = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      let privateKeyID = `MIICWwIBAAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv9LlLFAhvMArM60egsmU4jcUL
w8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX/EesghuvDYjG1YCYIfDKm4sd
NdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5URG8Q2M7D8WRHEqpvxAgMBAAEC
gYAdzV2u7I9+gY3GzVXkoQPnUfil4oycI2zawsRb4IwIOu0hOt2mViYnEiRZY0SL
heJiGtnQroi6cHSZKkoB+xjlmUb2oK2atmIJXLQKBkmVfn7ho6lJ7Eu+ux9ZDV1H
WcT2OZchSZz7oEaargMiu6A+HNBnXKkHr8dXjtphrw/EoQJBAM3W5Fucaaaa80fI
7a5PQ6+J4e1rFOn3y8IXx9r1KGwd2eoc7Zn5LonpI9XAp2v9bNBKvKo7mtn4fScA
wXGsFgUCQQCe6RaOPXHnAiAVlZ4JsVlpRjpueUlB2gKAGqfsgHVMPQWxOsE+kwKj
oyUyQa4VyXcMX9KFwE1jFJgc/bwvu8X9AkEAmOqvBTvi5Smx2D92C4vJPexSEl39
0H8NOWkPSSPd3swiaqEJBt0ubLftrkuMVVaU8vzCwji7pnjLCSGvlDICvQJAQrKM
cAkkLJR8yPJyUZkpcQEz9zha4hPR5tjJUH+eO/CE2h1SDKSBgBMywoREwAo+OGhj
oRXUpVh4e/qXqOVENQJAHEmeKGpj9IVyBLipW9ijaRa8/TXsx62LLvydTFoDenE5
eVglVTP1p8viOTzSNXXxOw6hQdyYWdswdGClZkvirw==`;

      decrypt.setPrivateKey(privateKey);
      decrypt2.setPrivateKey(privateKeyID);
          this.loading=!false
          setTimeout(() =>{
            this.loading=!true
          },850000)
      axios.post('insert_user',{ input: this.value, program_id: decrypt2.decrypt(localStorage.getItem("laporanpemiliksistemID")).replace(/(^"|"$)/g, '') })
      .then(res =>{console.log(res), location.reload()})
      .catch(err => {console.log(err)})
      console.log(this.value)
    },
    getInvited(){
        let decrypt = new JSEncrypt(); // New JSEncrypt Object
       let decrypt2 = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      let privateKeyID = `MIICWwIBAAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv9LlLFAhvMArM60egsmU4jcUL
w8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX/EesghuvDYjG1YCYIfDKm4sd
NdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5URG8Q2M7D8WRHEqpvxAgMBAAEC
gYAdzV2u7I9+gY3GzVXkoQPnUfil4oycI2zawsRb4IwIOu0hOt2mViYnEiRZY0SL
heJiGtnQroi6cHSZKkoB+xjlmUb2oK2atmIJXLQKBkmVfn7ho6lJ7Eu+ux9ZDV1H
WcT2OZchSZz7oEaargMiu6A+HNBnXKkHr8dXjtphrw/EoQJBAM3W5Fucaaaa80fI
7a5PQ6+J4e1rFOn3y8IXx9r1KGwd2eoc7Zn5LonpI9XAp2v9bNBKvKo7mtn4fScA
wXGsFgUCQQCe6RaOPXHnAiAVlZ4JsVlpRjpueUlB2gKAGqfsgHVMPQWxOsE+kwKj
oyUyQa4VyXcMX9KFwE1jFJgc/bwvu8X9AkEAmOqvBTvi5Smx2D92C4vJPexSEl39
0H8NOWkPSSPd3swiaqEJBt0ubLftrkuMVVaU8vzCwji7pnjLCSGvlDICvQJAQrKM
cAkkLJR8yPJyUZkpcQEz9zha4hPR5tjJUH+eO/CE2h1SDKSBgBMywoREwAo+OGhj
oRXUpVh4e/qXqOVENQJAHEmeKGpj9IVyBLipW9ijaRa8/TXsx62LLvydTFoDenE5
eVglVTP1p8viOTzSNXXxOw6hQdyYWdswdGClZkvirw==`;

      decrypt.setPrivateKey(privateKey);
      decrypt2.setPrivateKey(privateKeyID);
      axios.get("get_invite/"+decrypt2.decrypt(localStorage.getItem("laporanpemiliksistemID")))
      .then(res => {
        $('#invited').DataTable(
        {
          data: res.data,
          columns: [
            {
              data: null,
              render: function (data,type,row,meta) {
                return meta.row + 1
              }
            },
            {data: "nama"},
            {
              data: "created_at",
              render: function(data){
                return moment(data).format("DD-MM-YYYY")
              }
            },
            {
              data: "id",
              render: function(data){
                return `<button class="btn btn-danger"  data-id="${data}" id="delete">Hapus</button>  `
              },
            }
          ],
        });
        $(document).on(`click`, '#delete', function(){
        let id = $(this).data("id");
         axios.delete(`delete_invited/`+id)
         .then(res => {location.reload(), console.log(res)})
         .catch(error => {console.log(error)})
      })
        console.log(res)})
      .catch(err => {console.log(err)})
    }
    }
}
</script>
<style scoped>
.loading{
  width: 40px;
  width: 40px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <div class="home">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <Hero :title="title" :subtitle="subtitle" :img="img"  />
    <!-- End Hero -->
    <main id="main">
      <!-- ======= Clients Section ======= -->
      <section id="clients" class="clients">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <p>Pengguna Jasa</p>
          </header>
          <swiper
            class="swiper clients-slider ml-5 align-items-center"
            :options="swiperOption"
          >
            <swiper-slide>
              <img
                src="img/clients/banklampung.jpg"
                class="img-fluid align-self-center"
                alt=""
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="img/clients/banknagari.jpg"
                class="img-fluid align-self-center"
                alt=""
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="img/clients/bankprima.jpg"
                class="img-fluid"
                alt=""
              />
            </swiper-slide>
            <swiper-slide>
              <img src="img/clients/banksulselbar.jpg" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="img/clients/banksultra.jpg" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img
                src="img/clients/banksulutgo.jpg"
                class="img-fluid"
                alt=""
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="img/clients/usahakreatif.jpg"
                class="img-fluid"
                alt=""
              />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </section>
      <!-- End Clients Section -->
      <!-- ======= Values Section ======= -->
      <section id="values" class="values">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <p>Layanan</p>
          </header>

          <div class="row">
            <div
              class="col-lg-4 mt-4 mt-lg-5"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div class="box">
                <router-link to="/vulnerability-disclosure">
                  <img src="img/icon-vul.png" class="img-fluid" alt="" />
                  <h3>Vulnerability Disclosure</h3>
                  <div style="color: #ca1001">
                    Kerja sama sukarela<br />
                    pemilik sistem dan peneliti keamanan<br />
                    untuk menemukan kerentanan sistem<br />
                    dengan penghargaan non-finansial<br />
                  </div>
                </router-link>
              </div>
            </div>

            <div
              class="col-lg-4 mt-4 mt-lg-5"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div class="box">
                <router-link to="/bug-bounty">
                  <img src="img/icon-bug.png" class="img-fluid" alt="" />
                  <h3>Bug Bounty</h3>
                  <div style="color: #ca1001">
                    Kerja sama sukarela <br />
                    pemilik sistem dan peneliti keamanan<br />
                    untuk menemukan kerentanan sistem<br />
                    dengan penghargaan finansial<br />
                    sesuai kerentanan yang dilaporkan
                  </div>
                </router-link>
              </div>
            </div>

            <div
              class="col-lg-4 mt-4 mt-lg-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="box">
                <router-link to="/penetration-testing">
                  <img src="img/icon-pentest.png" class="img-fluid" alt="" />
                  <h3>Penetration Testing</h3>
                  <div style="color: #ca1001">
                    Kerja sama formal <br />
                    pemilik sistem dan peneliti keamanan <br />
                    untuk menemukan kerentanan sistem <br />
                    secara komprehensif <br />
                    dan dengan penghargaan finansial <br />
                    yang disepakati bersama
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="counts" class="counts">
        <div class="container" data-aos="fade-up">
          <div class="row gy-4">
            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="bi bi-building"></i>
                <div>
                  <ICountUp
                    :endVal="70"
                    :options="countOption"
                  />
                  <p>Pengguna Jasa</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i
                  class="bi bi-file-earmark-arrow-down"
                  style="color: #ee6c20"
                ></i>
                <div>
                  <ICountUp
                    :endVal="521"
                    :options="countOption"
                  />
                  <p>Program</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="bi bi-headset" style="color: #15be56"></i>
                <div>
                  <ICountUp
                    :endVal="200"
                    :options="countOption"
                  />
                  <p>Kerentanan</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="bi bi-people" style="color: #bb0852"></i>
                <div>
                 <ICountUp
                    :endVal="15"
                    :options="countOption"
                  />
                  <p>Peneliti Keamanan</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Counts Section -->

      <!-- <section id="testimonials" class="testimonials">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <p>Testimoni</p>
          </header>
          <swiper
            class="swiper testimonials-slider ml-5 align-items-center"
            :options="swiperOption2"
          >
            <swiper-slide>
             <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                  ><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                  ><i class="bi bi-star-fill"></i>
                </div>
                <p>
                  Proin iaculis purus consequat sem cure digni ssim donec
                  porttitora entum suscipit rhoncus. Accusantium quam, ultricies
                  eget id, aliquam eget nibh et. Maecen aliquam, risus at
                  semper.
                </p>
                <div class="profile mt-auto">
                  <img
                    src="img/testimonials/testimonials-1.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Saul Goodman</h3>
                  <h4>Ceo &amp; Founder</h4>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                  ><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                  ><i class="bi bi-star-fill"></i>
                </div>
                <p>
                  Export tempor illum tamen malis malis eram quae irure esse
                  labore quem cillum quid cillum eram malis quorum velit fore
                  eram velit sunt aliqua noster fugiat irure amet legam anim
                  culpa.
                </p>
                <div class="profile mt-auto">
                  <img
                    src="img/testimonials/testimonials-2.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Sara Wilsson</h3>
                  <h4>Designer</h4>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
             <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                  ><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                  ><i class="bi bi-star-fill"></i>
                </div>
                <p>
                  Enim nisi quem export duis labore cillum quae magna enim sint
                  quorum nulla quem veniam duis minim tempor labore quem eram
                  duis noster aute amet eram fore quis sint minim.
                </p>
                <div class="profile mt-auto">
                  <img
                    src="img/testimonials/testimonials-3.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Jena Karlis</h3>
                  <h4>Store Owner</h4>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                  ><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                  ><i class="bi bi-star-fill"></i>
                </div>
                <p>
                  Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                  multos export minim fugiat minim velit minim dolor enim duis
                  veniam ipsum anim magna sunt elit fore quem dolore labore
                  illum veniam.
                </p>
                <div class="profile mt-auto">
                  <img
                    src="img/testimonials/testimonials-4.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Matt Brandon</h3>
                  <h4>Freelancer</h4>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
             <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                  ><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                  ><i class="bi bi-star-fill"></i>
                </div>
                <p>
                  Quis quorum aliqua sint quem legam fore sunt eram irure aliqua
                  veniam tempor noster veniam enim culpa labore duis sunt culpa
                  nulla illum cillum fugiat legam esse veniam culpa fore nisi
                  cillum quid.
                </p>
                <div class="profile mt-auto">
                  <img
                    src="img/testimonials/testimonials-5.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>John Larson</h3>
                  <h4>Entrepreneur</h4>
                </div>
              </div>
            </swiper-slide>
           
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </section> -->
      <!-- End Testimonials Section -->
    </main>

    <!-- ======= Footer ======= -->
    <Footer/>

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import Footer from '@/components/Footer.vue'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import ICountUp from "vue-countup-v2";
console.log()
export default {
  name: "Home",
  components: {
    Hero,
    Footer,
    Swiper,
    SwiperSlide,
    ICountUp
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 5,
        loop: true,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false
          },
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
         breakpoints: {
            1024: {
              slidesPerView: 4,
              spaceBetween: 40
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            }
          }
      },
      swiperOption2:{
         slidesPerView: 3,
        spaceBetween: 5,
        loop: true,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
         breakpoints: {
            1024: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            }
          }
      },
      countOption: {
          delay: 100,
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
          prefix: '',
          suffix: ''
      },
      title: "Bersama-sama meningkatkan keamanan ruang siber Indonesia",
      subtitle: "",
      img: "img/landing/home.png"
    };
  },
};
</script>
<style scoped>
.swiper-slide {
  align-items: center;
  text-align: center;
}
.img {
  align-self: center;
}

.iCountUp {
  font-size: 10em;
  margin: 0;
  color: #4d63bc;
}
</style>

<template>
  <div id="tambahsertifikatpenelitikeamanan">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->
    <section id="detail" class="detail">
      <div class="container">
        <div class="row mt-lg-4">
          <div class="col-lg-12 mt-lg-5">
          <div class="card">
            <div class="card-header text-white" style="background-color: #e10000;">
              <h3 class="text-white">Unggah Sertifikat Peneliti</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <form @submit.prevent="submitSertifikat" enctype="multipart/form-data">
                  <div class="mb-3">
                    <div class="row col-lg-12">
                      <div class="col-6">
                      <label  class="form-label">Nama Sertifikat </label><label
                      style="color: red;">*</label>
                      <input type="text" v-model="nama_sertifikat" class="form-control" required>
                      </div>
                      <div class="col-6">
                      <label  class="form-label">Tanggal Sertifikat</label><label
                      style="color: red;">*</label>
                      <input type="date" v-model="tanggal_sertifikat" class="form-control" required >
                      </div>
                    </div>
                   
                  </div>
                  <div class="mb-3">
                    <div class="row col-lg-12">
                        <div class="col-6">
                      <label  class="form-label">File Sertifikat</label><label
                      style="color: red;">*</label>
                    <input type="file" v-on:change="onChange" class="form-control" required >
                      <img v-if="url" :src="url" class="rounded mt-3"
                        style="width: 155px; height: 150px; object-fit: cover" />
                      </div>
                      <div class="col-6">
                      <label  class="form-label">Tipe Sertifikat</label><label
                      style="color: red;">*</label>
                    <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                    <select v-model="tipe_sertifikat" class="form-select">
                      <option value="penghargaan">Penghargaan</option>
                      <option value="keahlian">Keahlian</option>
                    </select>
                      </div>
                    
                    </div>
                  </div>
                  <button type="submit" class="btn btn-danger">Simpan</button>
                </form>
              </div>
            </div>
          </div>
        </div> 
        </div>
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
// import { required, minLength, sameAs } from "vuelidate/lib/validators";
// import { UpperCase, LowerCase, numberV, symbol } from "../../validator.js";
import axios from "axios";
// import JSEncrypt from "jsencrypt/bin/jsencrypt";
import { mapGetters } from "vuex";
export default {
  name: "TambahSertifikatPenelitiKeamanan",
  components: {
    Footer,
  },
  data() {
    return {
      nama_sertifikat: "",
      tanggal_sertifikat: "",
      tipe_sertifikat: "",
      berkas_sertifikat: "",
      error: "",
      url: null
    };
  },
  validations: {
  },
  methods: {
       onChange(e) {
                this.berkas_sertifikat = e.target.files[0];
                this.url = URL.createObjectURL(this.berkas_sertifikat);
            },
            
    async submitSertifikat(){
        //  let existingObj = this;

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                let data = new FormData();
                data.append('user_id', localStorage.getItem("id"));
                data.append('nama_sertifikat', this.nama_sertifikat);
                data.append('tanggal_sertifikat', this.tanggal_sertifikat);
                data.append('tipe_sertifikat', this.tipe_sertifikat);
                data.append('berkas_sertifikat', this.berkas_sertifikat);
              
         const response = await axios.post("upload_cert",data, config).then((res) => {
          console.log(res)
          this.$router.push('/pengaturan-akun-peneliti-keamanan')
        }).catch((error) => {
          console.log(error)
        })
        console.log(response)
    }
  },
  mounted() {
    this.nama = localStorage.getItem("nama");
    this.nomor_telepon = localStorage.getItem("nomor_telepon");
    
    axios.get("user/"+localStorage.getItem("id"))
    .then((res) => {
        this.nama = res.data.nama, 
        this.email = res.data.email, 
        this.nomor_telepon = res.data.nomor_telepon, 
        console.log(res.data)
        } )
    .catch(error => console.log(error))
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style scoped></style>

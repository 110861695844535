import Vue from 'vue'
import App from './App.vue'
import router from './router'
// importing AOS
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'bootstrap'
import Vuelidate from 'vuelidate'
import VueCompositionAPI from '@vue/composition-api'
import './axios'
import store from './vuex'

//dttbles
// import 'bootstrap/dist/css/bootstrap.css'
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "jszip";
import "pdfmake";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import CkEditor from "ckeditor4-vue";

import VueNumericInput from 'vue-numeric-input';
 

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueNumericInput)
Vue.use(VueCompositionAPI)
Vue.use(CkEditor)

Vue.use(AOS.init());

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
    <div id="resetpassword">
         <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->

    <section id="login" class="login">
      <div class="container">
        <div class="row mt-2 align-items-center justify-content-center">
          <div class="col-lg-5 mt-4 mt-lg-5">
            <header class="section-header mb-3">
              <p>Atur Ulang Kata Sandi</p>
            </header>
            <div class="box border">
              <div class="">
                <div class="row gy-1">
                  <div v-if="error" class="alert alert-danger" role="alert">
                    {{error}}
                  </div>
                  <form
                    class="php-email-form"
                    @submit.prevent="resetPassword"
                  >
                    <div class="row gy-4">
                      <div class="col-md-12" :class="{ 'form-group--error': $v.password.$error }" style="margin-bottom: -20px">
                        <h3 class="mb-2 text-start">Kata Sandi</h3>
                        <input
                          class="form-control"
                          style="border-radius: 8px"
                          type="password"
                           v-model="$v.password.$model"
                          required
                        />
                        <div class="ms-3 text-start text-danger" v-if="!$v.password.LowerCase || !$v.password.UpperCase || !$v.password.numberV || !$v.password.symbol || !$v.password.minLength">
                          Kata Sandi harus memiliki min.:
                        </div>
                        <ul class="text-start text-danger">
                          <li  v-if="!$v.password.LowerCase">
                            <div class="error">1 huruf kecil</div>
                          </li>
                          <li v-if="!$v.password.UpperCase">
                            <div class="error" >1 huruf kapital</div>
                          </li>
                          <li v-if="!$v.password.symbol">
                            <div class="error" >1 tanda baca / simbol</div>
                          </li>
                          <li v-if="!$v.password.numberV">
                            <div class="error" >1 angka</div>
                          </li>
                          <li v-if="!$v.password.minLength">
                            <div class="error" >panjang {{$v.password.$params.minLength.min}} karakter</div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-12" :class="{ 'form-group--error': $v.password_confirm.$error }">
                        <h3 class="mb-2 text-start">Konfirmasi Kata Sandi</h3>
                        <input
                          class="form-control"
                          style="border-radius: 8px"
                          type="password"
                           v-model="$v.password_confirm.$model"
                          required
                        />
                        <div class="error text-start text-danger" v-if="!$v.password_confirm.sameAsPassword">
                          Kata sandi tidak cocok
                        </div>
                      </div>
                      <div class="col-md-12 mt-3 text-center">
                       
                        <button type="submit" >Kirim</button>
                        <!-- <a href="">
                                            <p class="mt-3">Belum punya Akun?</p>
                                        </a> -->
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Section -->

    <!-- ======= Footer ======= -->
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import { minLength, sameAs } from 'vuelidate/lib/validators'
import {   UpperCase, LowerCase, numberV, symbol } from "../validator.js";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
import axios from 'axios'
export default {
    name: 'ResetPassword',
    components:{
        Footer
    },
     data() {
    return {
            password: '',
            password_confirm: '',
            error: '',
            token: null
    };
  },
  validations: {
     password: {  UpperCase, LowerCase, numberV, symbol, minLength: minLength(8) },
      password_confirm: { sameAsPassword: sameAs('password') }
  },
  created(){
    console.log(this.$route)
  },
  methods:{
    async resetPassword(){
      let encryptor = new JSEncrypt(); // New JSEncrypt Object

      let publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh3dKb3P9MAbMPij/CaVGmi0sg
onTq0ofRN8hx+6pfXErtXokw7Mtxns/DmDRlpsNcOZrgKdlT5OGu5XkITN7c/om3
bVnTZKYJnyABk0h3i3ecuVuyIkGFL4B2brs98+EBbaKFGTOYX2hhbwbPJV04//Vo
jFBLG0wm21UKu5N2RQIDAQAB`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
      axios.post("reset_password",{
        email: this.$route.query.email,
        password: encryptor.encrypt(this.password),
        token: this.$route.query.token
      })
      .then(response => {
        console.log(response)
        this.$route.push('/login');
        this.error = response.data.message
      })
      .catch(error =>{
        console.log(error)
         this.error = 'Token Tidak Berlaku'
      })
    
    }
  }

}
</script>
<style scoped>

</style>
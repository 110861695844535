<template>
  <div id="login">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->

    <section id="login" class="login">
      <div class="container">
        <div class="row mt-2 align-items-center justify-content-center">
          <div class="col-lg-5 mt-4 mt-lg-5">
            <header class="section-header mb-3">
              <p>Masuk</p>
            </header>
            <div class="box border">
              <div class="">
                <div class="row gy-1">
                  <div v-if="error" class="alert alert-danger" role="alert">
                    {{ error }}
                  </div>
                  <form class="php-email-form" @submit.prevent="handleSubmit">
                    <div class="row gy-4">
                      <div class="col-md-12">
                        <h3 class="mb-2 text-start">
                          Alamat Surat Elektronik / <br />Nama Pengguna
                        </h3>
                        <input
                          type="email"
                          class="form-control"
                          style="border-radius: 8px"
                          v-model="email"
                          required
                        />
                      </div>
                      <div class="col-md-12">
                        <h3 class="mb-2 text-start">Kata Sandi</h3>
                        <input
                          type="password"
                          class="form-control"
                          style="border-radius: 8px"
                          v-model="password"
                          required
                        />
                        <router-link to="/lupa-kata-sandi">
                          <p class="mt-2 text-end">Lupa kata sandi?</p>
                        </router-link>
                      </div>
                      <div class="col-md-12 mt-3 text-center">
                        <div class="loading">Loading</div>
                        <vue-recaptcha
                          class="row justify-content-center mb-3"
                          style="padding-right: 15px"
                          sitekey="6LfVWpknAAAAAMLIukx-Y2jdeoDqrhNbccdkfo2L"
                          ref="recaptcha"
                          @verify="onCaptchaVerified"
                          @expired="onCaptchaExpired"
                        >
                        </vue-recaptcha>
                        <button
                          type="submit"
                          :disabled="this.isVerified === false"
                        >
                          Masuk
                        </button>
                        <!-- <a href="">
                                            <p class="mt-3">Belum punya Akun?</p>
                                        </a> -->
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Section -->

    <!-- ======= Footer ======= -->
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import { VueRecaptcha } from "vue-recaptcha";
// import router from '../router'
import axios from "axios";
import JSEncrypt from "jsencrypt/bin/jsencrypt";

export default {
  name: "Login",
  components: {
    Footer,
    VueRecaptcha,
  },
  data() {
    return {
      email: "",
      password: "",
      isVerified: false,
      captcha: "",
      error: "",
    };
  },
  methods: {
    onCaptchaVerified(response) {
      // console.log(response);
      this.captcha = response;
      this.isVerified = true;
      console.log(this.isVerified);
    },
    onCaptchaExpired: function (error) {
      this.captcha = null;
      this.isVerified = false;
      console.log(error);
      this.$refs.recaptcha.reset();
    },
    async handleSubmit() {
      let encryptor = new JSEncrypt(); // New JSEncrypt Object

      let publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh3dKb3P9MAbMPij/CaVGmi0sg
onTq0ofRN8hx+6pfXErtXokw7Mtxns/DmDRlpsNcOZrgKdlT5OGu5XkITN7c/om3
bVnTZKYJnyABk0h3i3ecuVuyIkGFL4B2brs98+EBbaKFGTOYX2hhbwbPJV04//Vo
jFBLG0wm21UKu5N2RQIDAQAB`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
      const password = this.password;
      await axios
        .post("login", {
          epass: encryptor.encrypt(this.email) + encryptor.encrypt(password),
          // email: encryptor.encrypt(this.email),
          // password: encryptor.encrypt(password),
          is_verified: this.captcha,
        })
        .then((response) => {
          var now = new Date();
          var time = now.getTime();
          var expireTime = time + 360 * 60 * 1000;
          // console.log(this.isVerified);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("roles", response.data.user.roles);
          localStorage.setItem("time", expireTime);
          this.$store.dispatch("user", response.data.user);
          if (response.data.user.roles === "pemilik-sistem") {
            // this.$router.push('/dashboard-company');
            window.location = "/halaman-utama-pemilik-sistem";
          } else {
            // this.$router.push('/dashboard-researcher');
            window.location = "/halaman-utama-peneliti-keamanan";
          }
          console.log(response.data.message);
          // this.error = response.data.message;
        })
        .catch((error) => {
          this.error = "Email atau Password Salah";
          console.log(error);
        });
    },
  },
};
</script>
<style scoped></style>

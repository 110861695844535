<template>
    <div id="vulnerabilitydisclosure">
            <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <Hero :title="title" :subtitle="subtitle" :img="img" />
    <!-- End Hero -->
      <section id="features" class="features">

    <div class="container" data-aos="fade-up">

      <header class="section-header">
        <p>Apa itu Vulnerability Disclosure?</p>
      </header>


      <!-- Feature Tabs -->
      <div class="row feture-tabs" data-aos="fade-up">
        <div class="col-lg-6">

          <!-- Tabs -->
          <ul class="nav nav-pills mb-3">
            <li>
              <a class="nav-link active" id="btn1">Latar Belakang</a>
            </li>
            <li>
              <a class="nav-link "  id="btn2" >Cara Kerja</a>
            </li>
            <li>
              <a class="nav-link "  id="btn3" >Standarisasi</a>
            </li>
          </ul><!-- End Tabs -->

          <!-- Tab Content -->
          <div class="tab-content">

            <div class="tab-pane fade show active" id="tab1">
              <p>Program ini melaporkan temuan kerentanan kepada pemilik sistem dengan tujuan agar kerentanan tersebut
                dapat segera diperbaiki. Peneliti Keamanan akan melaporkan setiap celah dan kerentanan yang nantinya para
                Peneliti Keamanan akan mendapatkan sertifikat penghargaan dari perusahaan.</p>
            </div><!-- End Tab 1 Content -->
            <div class="tab-pane fade show" id="tab2">
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>#1. Peneliti Keamanan mencari kerentanan dalam sistem</h4>
              </div>
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>#2. Peneliti Keamanan mengirim temuan kerentanan dan celah pada topiputih.id</h4>
              </div>
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>#3. topiputih.id meninjau laporan yang dikirim Peneliti Keamanan</h4>
              </div>
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>#4. topiputih.id mengirimkan laporan yang sudah dipilih kepada perusahaan</h4>
              </div>
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>#5. Perusahaan menerima laporan kemudian melakukan perbaikan sistem</h4>
              </div>
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>#6. Peneliti Keamanan menerima sertifikat penghargaan dari perusahaan</h4>
              </div>
            </div><!-- End Tab 2 Content -->

            <div class="tab-pane fade show" id="tab3">
              <p>Laporan dari Peneliti Keamanan akan ditinjau oleh topiputih.id. Laporan harus berisi
                kerentanan yang ditemukan beserta solusi untuk mengatasi kerentanan itu. Peneliti Keamanan juda dilarang
                untuk menyebarkan atau mempublikasikan kerentananyang dimiliki oleh sistem.</p>
            </div><!-- End Tab 3 Content -->

          </div>

        </div>

        <div class="col-lg-6">
          <img src="img/features-2.png" class="img-fluid" alt="">
        </div>

      </div><!-- End Feature Tabs -->

      
    </div>

  </section>
    <!-- ======= Footer ======= -->
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import Footer from '@/components/Footer.vue'
import $ from "jquery";
$(document).ready(function(){
//   var btn1 = document.getElementById('btn1');
//   var btn2 = document.getElementById('btn2');
//   var btn3 = document.getElementById('btn3');
$('#btn1').click(function(){
   $("#tab1").show();
    $("#tab2").hide();
    $("#tab3").hide();
    $("#btn1").addClass("active");
    $("#btn2").removeClass("active");
    $("#btn3").removeClass("active");
});
$('#btn2').click(function(){
    $("#tab2").show();
    $("#tab1").hide();
    $("#tab3").hide();
    $("#btn2").addClass("active");
    $("#btn1").removeClass("active");
    $("#btn3").removeClass("active");
});
$('#btn3').click(function(){
      $("#tab3").show();
    $("#tab1").hide();
    $("#tab2").hide();
    $("#btn3").addClass("active");
    $("#btn2").removeClass("active");
    $("#btn1").removeClass("active");
});
  
 
});

export default {
  name: 'VulnerabilityDisclosure',
  components:{
    Hero,
    Footer,
  },
  data() {
      return {
          
              title: "Vulnerability Disclosure",
              subtitle: `Kerja sama sukarela pemilik sistem dan peneliti keamanan
              untuk menemukan kerentanan sistem
              dengan penghargaan non-finansial`,
              img: "img/icon-vul.png"
          
      }
  }
}
</script>

<style scoped>

</style>
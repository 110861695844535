<template>
    <div id="pembayaranpenelitikeamanan">

  <section id="detail" class="detail">
    <div class="container">
      <div class="row mt-lg-4">
        <div class="row col-lg-12 mt-lg-5">
          <div class="col-8">
            <h2 class="fw-bolder mb-4">Pembayaran Peneliti Keamanan</h2>
            <h5 class="fw-bolder">Daftar Pembayaran Program Mendatang</h5>
            <table id="paymentResercherProses" class="table table-bordered mt-2">
              <thead class="text-white"  style="background-color: #E10000;">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Nama Program</th>
                  <th scope="col">Tanggal</th>
                  <th scope="col">Jumlah Hadiah</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
          <div class="col-4">
            <div class="card rounded text-center rounded" style="width: 18rem; margin-top: 130px">
              <div class="card-body text-white" style="background-color: #E10000;">
                <h5 class="card-title">Total Hadiah Diterima:</h5>
                <h3 class="text-white">Rp. {{formatPrice(payments)}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
  <a
  href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i>
  </a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import moment from "moment";
import axios from 'axios'
import $ from 'jquery'
import JSEncrypt from "jsencrypt/bin/jsencrypt";
export default {
    name: 'PembayaranPenelitiKeamanan',
    components:{
        Footer
    },
     data() {
    return {
      payments: '',
      paymentsReport: []
    }
  },
  mounted(){
    this.getpaymentResercherProses()
    this.getPaymentTotal()
  },
  methods:{
      formatPrice(value) {
        let val = (value/1).toFixed(0).replace('.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getpaymentResercherProses() {
      axios
        .get("payment_researcher_process/" + localStorage.getItem("id"))
        .then((res) => {
          $("#paymentResercherProses").DataTable({
            "lengthMenu": [[5, 10, 25, -1], [5, 10, 25, "All"]],
            "order": [[0, 'asc']],
            data: res.data,
            columns: [
              {
                data: null,
                render: function (data, type, row, meta) {
                  return meta.row + 1;
                },
              },
              { data:"program_name" },
              {
                data: "updated_at",
                render: function (data) {
                  return moment(data).format("DD-MM-YYYY");
                },
              },
              { data: "reward",
                render: function(data){
                  let val = (data/1).toFixed(0).replace('.', ',')
                  return `Rp. `+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }              
              },
              { data: "status_reward" },
            ],
          });
          console.log(res.data);
        })
        .catch((error) => console.log(error));
    },
    getPaymentTotal(){
       let decrypt = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      decrypt.setPrivateKey(privateKey);
      axios.get("payment_researcher_total/" + localStorage.getItem("id"))
      // .then(res => this.payments = res.data)
      .then((res) => {
          (this.payments = decrypt.decrypt(res.data)) , console.log(res.data);
        })
      .catch(err => console.log(err))
    }
  }
}
</script>
<template>
    <div id="laporanpenelitikeamanan">
    
    
    <section id="features" class="features">

    <div class="container">
      <!-- Feature Tabs -->
      <div class="row feture-tabs ">
        <div class="col-lg-12 ">
          <h3>Laporan Peneliti Keamanan</h3>
          <!-- Tabs -->
          <!-- <ul class="nav nav-pills mb-3 justify-content-center">
            <li>
              <a class="nav-link active" data-bs-toggle="pill" href="#tab1">All</a>
            </li>
            <li>
              <a class="nav-link" data-bs-toggle="pill" href="#tab2">Tertunda</a>
            </li>
            <li>
              <a class="nav-link" data-bs-toggle="pill" href="#tab3">Diterima</a>
            </li>
            <li>
              <a class="nav-link" data-bs-toggle="pill" href="#tab4">Tanpa Hadiah</a>
            </li>
          </ul> -->
          <!-- End Tabs -->

          <!-- Tab Content -->
          <div class="tab-content">
    <!-- Modal -->
<div v-for="listReport in reportUser" v-bind:key="listReport.id"  class="modal fade" :id="`exampleModal`+listReport.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Keterangan Status</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
                <div class="row">
                  <div class="col-6">
                    <div class="row justify-content-center">
                   <h4 class="text-black">{{listReport.status_causes}}</h4>
                    </div>
                  </div>

                </div>
            
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Tutup</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
            <div class="tab-pane fade show active" >
               <table id="myTable10" class="table table-bordered mt-2">
                    <thead class="text-white" style="background-color: #E10000;">
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Nama Program</th>
                        <th scope="col">Tanggal Mulai</th>
                        <th scope="col">Target</th>
                        <th scope="col">Status</th>
                        <th scope="col">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                       <!-- <tr
                        v-for="itemListProgram in listProgram"
                        v-bind:key="itemListProgram.id">
                        <td>{{ itemListProgram.program_name }}</td>
                        <td>{{ itemListProgram.company_name }}</td>
                        <td>{{ itemListProgram.price_5.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
                        <td>
                            <router-link class="btn btn-warning me-2" v-bind:to="`/edit-program-company/`+itemListProgram.id">Ubah</router-link>
                            <button class="btn btn-danger"  @click="deleteProgram(itemListProgram.id)">Hapus</button>
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
            </div><!-- End Tab 1 Content -->

            <!-- <div class="tab-pane fade show" id="tab2">
              <table class="table mb-5">
                <thead class="text-white" style="background-color: #E10000;">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>

                </tbody>
              </table>
            </div> -->
            <!-- End Tab 2 Content -->

            <!-- <div class="tab-pane fade show" id="tab3">
              <table class="table mb-5">
                <thead class="text-white" style="background-color: #E10000;">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <th scope="row">3</th>
                    <td colspan="2">Larry the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </table>
            </div> -->
            <!-- End Tab 3 Content -->
            <!-- <div class="tab-pane fade show" id="tab4">
              <table class="table mb-5">
                <thead class="text-white" style="background-color: #E10000;">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>

                </tbody>
              </table>
            </div> -->
            <!-- End Tab 3 Content -->

          </div>
          <div class="row mt-3 justify-content-end">
            <div class="card" style="width: 16rem;">
              <div class="card-header text-center text-white" style="background-color: #e10000;">
                Jumlah Poin
              </div>
              <div class="card-body text-center">
                <h5 class="card-title">{{point}}</h5>
              </div>
            </div>
          </div>
          <div class="row mt-3 justify-content-end">
            <div class="card" style="width: 16rem;">
              <div class="card-header text-center text-white" style="background-color: #e10000;">
                Jumlah Hadiah
              </div>
              <div class="card-body text-center">
                <h5 class="card-title">Rp. {{ formatPrice(reward_researcher)}}</h5>
              </div>
            </div>
          </div>

        </div>

      </div><!-- End Feature Tabs -->



    </div>

  </section>
     <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import axios from 'axios'
import $ from 'jquery'
import router from '../../router'
import moment from 'moment'
import JSEncrypt from "jsencrypt/bin/jsencrypt";
export default {
    name: 'LaporanPenelitiKeamanan',
    components:{
        Footer
    },
    data(){
      return {
        point: '',
        reportUser: [],
        reward_researcher: ''
      }
    },
   created(){
        this.getReport()
        this.getPoint()
        this.getReportUser()
        this.getReward_researcher()
    },
    methods: {
      formatPrice(value) {
        let val = (value/1).toFixed(0).replace('.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
      getReportUser(){
        axios.get("report_user/" + localStorage.getItem("id"))
        .then(res => {this.reportUser = res.data})
        .catch(err => {console.log(err)})
      },
        async getReport(){
        axios
      .get("report_user/" + localStorage.getItem("id"))
      .then((res) => {
        $('#myTable10').DataTable(
        {
          data: res.data,
          columns: [
            {
              data: null,
              render: function (data,type,row,meta) {
                return meta.row + 1
              }
            },
            {data: "program_name"},
            {
              data: "date",
              render: function(data){
                return moment(data).format("DD-MM-YYYY")
              }
            },
            {
              data: "scope_report"
            },
            {
              data: "id",
              render: function(data,type,row){
                if(row.status_report === 'Ditolak'){
                return  `<button type="button" class="btn btn-danger"  data-bs-toggle="modal" data-bs-target="#exampleModal${data}">${row.status_report}</button>`
                }else{
                  return  `<h4>${row.status_report}</h4>`
                }
              }
            },
            {
              data: "id",
              render: function(data){
                return `<button class="btn btn-primary text-white" data-id=${data}" id="ubah">Detail</button> `
              },
            }
          ],
        });
        console.log(res.data);
      })
      .catch((error) => console.log(error));
      //delete
      $(document).on(`click`, '#delete', function(){
        let id = $(this).data("id");
        
         axios.delete(`program/`+id)
         .then(res => {location.reload(), console.log(res)})
         .catch(error => {console.log(error)})
      })
      $(document).on(`click`, '#ubah', function(){
        let encryptor = new JSEncrypt(); // New JSEncrypt Object

      let publicKey = `MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv
9LlLFAhvMArM60egsmU4jcULw8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX
/EesghuvDYjG1YCYIfDKm4sdNdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5UR
G8Q2M7D8WRHEqpvxAgMBAAE=`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
        let id = $(this).data("id");
        localStorage.setItem('laporanpenelitiID', encryptor.encrypt(String(id)))
        router.push('/detail-laporan-peneliti-keamanan')
      })
     
        },
        getPoint(){
          axios.get("point_user/"+localStorage.getItem("id"))
          .then(res => {this.point = res.data,console.log(res.data)})
          .catch(err => {console.log(err)})
        },
        getReward_researcher(){
          axios.get("reward_researcher/"+localStorage.getItem("id"))
          .then(res => {this.reward_researcher = res.data,console.log(res.data)})
          .catch(err => {console.log(err)})
        }
    }
}
</script>
<style scoped>

</style>
<template>
      <footer id="footer" class="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-12 footer-info">
              <router-link to="/" class="logo d-flex align-items-center">
                <img src="img/topiputih.png" alt="" />
                <span>topiputih.id</span>
              </router-link>
              <p>Bersama-sama meningkatkan keamanan ruang siber Indonesia</p>
              <div class="social-links mt-3">
                <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                <a href="#" class="instagram"
                  ><i class="bi bi-instagram"></i
                ></a>
                <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
              </div>
            </div>

            <div class="col-lg-8 col-6 footer-links">
              <div class="row">
                <div class="col-lg-4 col-6 footer-links">
                  <h4>Layanan</h4>
                  <ul>
                    <li>
                      <i class="bi bi-chevron-right"></i>
                      <router-link to="/vulnerability-disclosure"
                        >Vulnerability Disclosure</router-link
                      >
                    </li>
                    <li>
                      <i class="bi bi-chevron-right"></i>
                      <router-link to="/bug-bounty">Bug Bounty</router-link>
                    </li>
                    <li>
                      <i class="bi bi-chevron-right"></i>
                      <router-link to="/penetration-testing">Penetration Testing</router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-4 col-6 footer-links">
                  <h4>Tautan Menu</h4>
                  <ul>
                    <li>
                      <i class="bi bi-chevron-right"></i>
                      <router-link to="/program">Program</router-link>
                    </li>
                    <li>
                      <i class="bi bi-chevron-right"></i>
                      <router-link to="/tentang-kami">Tentang Kami</router-link>
                    </li>
                    <li>
                      <i class="bi bi-chevron-right"></i>
                      <router-link to="/hubungi-kami">Hubungi Kami</router-link>
                    </li>
                  </ul>
                </div>
                <div
                  class="col-lg-4 col-md-12 footer-contact text-end text-md-start"
                >
                  <h4>Hubungi Kami</h4>
                  <p>
                    Graha Bumiputera Lt.8, <br />
                    Jl. Raya Darmo No.155-159<br />
                    Surabaya, 60241 <br /><br />
                    <strong>Phone:</strong> (031) 561 5175<br />
                    <strong>Email:</strong> aditasi@adita.si<br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright">
          &copy; Copyright
          <strong
            ><a href="https://adita.si" target="_blank"
              >PT Adikarya Tata Informasi
            </a></strong
          >2022
        </div>
        <div class="credits">
          <!-- All the links in the footer should remain intact. -->
          <!-- You can delete the links only if you purchased the pro version. -->
          <!-- Licensing information: https://bootstrapmade.com/license/ -->
          <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/flexstart-bootstrap-startup-template/ -->
        </div>
      </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    components:{}
}
</script>
<style scoped>

</style>
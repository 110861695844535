<template>
    <div id="laporanpemiliksistem">
     
        <section id="features" class="features">
        <div class="container">
            <!-- Feature Tabs -->
            <div class="row feture-tabs ">
                <div class="col-lg-12 ">
                    <h3>Laporan </h3>
                    
                    <!-- Tab Content -->
                    <div class="tab-content">
                            <table id="programLaporan" class="table table-hover" style="width:100%">
                                <thead  class="text-white" style="background-color: #E10000;">
                                    <tr>
                                        <th scope="col">No</th>
                                        <th scope="col">Nama Program</th>
                                        <th scope="col">Tanggal Mulai</th>
                                        <th scope="col">Tanggal Selesai</th>
                                        <th scope="col">Jumlah Laporan</th>
                                        <th scope="col">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                    </div>
                </div>
            </div><!-- End Feature Tabs -->
        </div>
    </section>
      <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import path from "../../path";
import router from '../../router'
import axios from 'axios' 
import moment from 'moment'
import $ from 'jquery'
export default {
    name: 'LaporanPemilikSistem',
    components:{
        Footer,
    },
    data(){
        return {
            path: path,
        }
    },
    mounted(){
    this.getProgram()
    // this.getInvited()
    // location.reload()
     
    },
    methods:{
         getProgram(){
      axios.get("count_report/" + localStorage.getItem("id"))
      .then(response => {
        $('#programLaporan').DataTable(
        {
            "lengthMenu": [[5, 10, 25, -1], [5, 10, 25, "All"]],
            "order": [[0, 'asc']],
          data: response.data,
         columns: [
           {
              data: null,
              render: function (data,type,row,meta) {
                return meta.row + 1
              }
            },
           { data: 'program_name' },
           { 
               data: 'date_start',
                render: function(data){
                return moment(data).format("DD-MM-YYYY")
              }
         },
           { 
               data: 'date_end',
                render: function(data){
                return moment(data).format("DD-MM-YYYY")
              }
             },
            { data: 'count_report' },
            {
                data: "program_id",
              render: function(data){
                return `<button class="btn btn-primary text-white" data-id=${data}" id="ubah">Detail Laporan</button> `
              },
            }
           ],
          });
           $(document).on(`click`, '#ubah', function(){
                let id = $(this).data("id");
                 localStorage.setItem('daftar-laporan-program',id)
                 router.push('/daftar-laporan-program')
                 })
          console.log(response.data);
            this.programL = response.data
      })
      .catch(error => {
        console.log(error.response)
      })
    },
    }
}
</script>
<style scoped>

</style>
<template>
  <div id="tentangkami">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <Hero :title="title" :subtitle="subtitle" :img="img" />
    <!-- End Hero -->

    <section id="about" class="about">
      <div class="container" data-aos="fade-up">
        <div class="row gx-0">
          <div
            class="col-lg-6 d-flex flex-column justify-content-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="content">
              <h2>topiputih.id</h2>
              <p>
                topiputih.id adalah platform yang menggelar sejumlah Bug
                Bounty,Vulnerability Disclosure,Penetration Testing yang
                bekerja sama dengan berbagai perusahaan. topiputih.id merupakan
                wadah bagi para peretas putih untuk menemukan kerentanan yang
                dimiliki perusahaan yang telah bekerjasama dengan topiputih.id
              </p>
            </div>
          </div>
          <div
            class="col-lg-6 d-flex align-items-center"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <img src="img/topiputih.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section id="features" class="features">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <p>Benefit bekerja dengan Tim Kami</p>
        </header>

        <div class="row">
          <div class="col-lg-6">
            <img src="img/features.png" class="img-fluid" alt="" />
          </div>

          <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
            <div class="row align-self-center gy-4">
              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Hasil temuan kerentanan bervariatif</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Menggunakan tools paling baru</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Mengacu pada standar keamanan internasional</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Memberikan rekomendasi penanganan yang efektif</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Peneliti keamanan bersertifikasi</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Penawaran harga terbaik</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- / row -->

        <!-- Feature Tabs -->
        <div class="row feture-tabs" data-aos="fade-up">
          <div class="col-lg-6">
            <h3>
              Alasan, Manfaat, dan kepentingan melakukan pemeriksaan keamanan
            </h3>

            <!-- Tabs -->
            <ul class="nav nav-pills mb-3">
              <li>
                <a class="nav-link active" data-bs-toggle="pill" href="#tab1"
                  >Alasan</a
                >
              </li>
              <li>
                <a class="nav-link" data-bs-toggle="pill" href="#tab2"
                  >Manfaat</a
                >
              </li>
              <li>
                <a class="nav-link" data-bs-toggle="pill" href="#tab3"
                  >Kepentingan</a
                >
              </li>
            </ul>
            <!-- End Tabs -->

            <!-- Tab Content -->
            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab1">
                <p>
                  Sistem anda kurang aman. Sistem anda mengalami serangan atau
                  insiden yang merugikan perusahaan. Peneliti keamanan akan
                  menemukan kerentanan dari serangan atau insiden tersebut.
                </p>

                <p>
                  Perubahan signifikan pada sistem anda. Terdapat perubahan
                  besar yang cukup signifikan pada sistem anda dan perlu
                  dilakukan pemeriksaan keamanan untuk mengurangi resiko
                  terjadingnya serangan. Peneliti keamanan akan membantu
                  mengurangi resiko adanya kerentanan pada sistem yang baru.
                </p>
              </div>
              <!-- End Tab 1 Content -->

              <div class="tab-pane fade show" id="tab2">
                <p>
                  Sistem yang aman. Dengan melakukan pemeriksaan keamanan,
                  sistem dapat terhindar kerentanan yang merugikan sistem dan
                  risiko serangan pihak yang tidak bertanggung jawab.
                </p>

                <p>
                  Kepercayaan pengguna. Dengan sistem yang aman, pengguna dapat
                  terhindar dari risiko sistem yang tidak aman. Kepercayaan
                  pengguna pada sistem akan meningkat sejalan dengan sistem yang
                  aman.
                </p>
              </div>
              <!-- End Tab 2 Content -->

              <div class="tab-pane fade show" id="tab3">
                <p>
                  Mengamankan sistem anda. Mengidentifikasi dan memantau risiko
                  pada sistem anda. Peneliti keamanan akan menemukan kerentanan
                  dan kelemahan dari sistem anda.
                </p>

                <p>
                  Mencegah serangan pada sistem anda. Memperbaiki kelemahan dan
                  melakukan langkah-langkah pencegahan untuk sistem anda.
                  Peneliti keamanan akan memberikan solusi dari kelemahan sistem
                  anda.
                </p>
              </div>
              <!-- End Tab 3 Content -->
            </div>
          </div>

          <div class="col-lg-6">
            <img src="img/features-2.png" class="img-fluid" alt="" />
          </div>
        </div>
        <!-- End Feature Tabs -->

        <!-- Feature Icons -->
        <div class="row feature-icons" data-aos="fade-up">
          <h3>Ada apa saja di topiputih.id ?</h3>

          <div class="row">
            <div
              class="col-xl-4 text-center"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src="img/features-3.png" class="img-fluid p-4" alt="" />
            </div>

            <div class="col-xl-8 d-flex content">
              <div class="row align-self-center gy-4">
                <div class="col-md-6 icon-box" data-aos="fade-up">
                  <i class="ri-line-chart-line"></i>
                  <div>
                    <h4>Keamanan Aplikasi</h4>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i class="ri-stack-line"></i>
                  <div>
                    <h4>Manajemen Resistensi Serangan</h4>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i class="ri-brush-4-line"></i>
                  <div>
                    <h4>Keamanan Cloud</h4>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i class="ri-magic-line"></i>
                  <div>
                    <h4>Tindak Lanjut Uji Keamanan</h4>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i class="ri-command-line"></i>
                  <div>
                    <h4>Manajemen Kerentanan</h4>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <i class="ri-radar-line"></i>
                  <div>
                    <h4>Keamanan Jaringan Komunikasi</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Feature Icons -->
      </div>
    </section>

    <!-- ======= Footer ======= -->
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Hero from "@/components/Hero.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Tentangkami",
  components: {
    Hero,
    Footer,
  },
  data() {
    return {
      title: "Tentang Kami",
      subtitle: "Kami adalah solusi dari kerentanan anda",
      img: "img/landing/tentang.png",
    };
  },
};
</script>
<style scoped></style>

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);

const state = {
    user:null,
    program:[]
};

const store = new Vuex.Store({
    state,
    getters: {
        user: (state) => {
            return state.user;
        },
        getProgram: (state) => {
            return state.getProgram
        }
    },
    actions: { 
        user(context, user){
            context.commit('user', user);
        },
        getProgram({ commit }) {
            //get data sliders ke server
            axios.get("program")
              .then((response) => {
                //commit ke mutation GET_PRODUCTS dengan response data
                commit("getProgram", response.data);
              })
              .catch((error) => {
                //show error log dari response
                console.log(error);
              });
          },
    },
    mutations: {
        user(state, user) {
            state.user = user;
        },
        getProgram(state, getProgram){
            state.getProgram = getProgram
        }
    }
});

export default store;
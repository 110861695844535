<template>
  <div id="penarikansaldopenelitikeamanan">
    <section id="features" class="features">
      <div class="container">
        <div class="row feture-tabs">
          <div class="col-lg-12">
            <h3>Penarikan Saldo Peneliti Keamanan</h3>
            <section id="detail" class="detail">
              <div class="container">
                 <div class="row">
                  <form @submit.prevent="submitSaldo">
                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-3">
                          <label class="form-label">Nama Bank </label>
                          <input
                            type="text"
                            v-model="nama_bank"
                            class="form-control"
                            required
                            readonly
                          />
                        </div>
                        <div class="col-3">
                          <label class="form-label">Nama Rekening</label>
                          <input
                            type="text"
                            v-model="nama_rekening"
                            class="form-control"
                            required
                            readonly
                          />
                        </div>
                        <div class="col-3">
                          <label class="form-label">Nomor Rekening</label>
                          <input
                            type="text"
                            v-model="nomor_rekening"
                            class="form-control"
                            required
                            readonly
                          />
                        </div>
                        <div class="col-3">
                          <label class="form-label">Jumlah Transfer</label>
                          <input  class="form-control" type="number" v-model="payment_amount" v-on:change="price()">
                        </div>
                      </div>
                    </div>

                    <!-- <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-12">
                          <label class="form-label">Bukti Transfer</label>
                          <input
                            type="file"
                            v-on:change="onChange"
                            class="form-control"
                            required
                          />
                          <img
                            v-if="url"
                            :src="url"
                            class="rounded mt-3"
                            style="
                              width: 155px;
                              height: 150px;
                              object-fit: cover;
                            "
                          />
                        </div>
                      </div>
                    </div> -->
                    <button type="submit" class="btn btn-danger">Simpan</button>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import $ from "jquery";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
// import VueNumeric from 'vue-numeric'
import axios from "axios";
$(document).ready(function () {
  $("#myTable").DataTable();
});
export default {
  name: "PenarikanSaldoPenelitiKeamanan",
  components: {
    Footer,
    // VueNumeric
  },
  data() {
    return {
      nama_bank: "",
      nama_rekening: "",
      nomor_rekening: "",
      payment_amount: 0,
      max: 0
    //   image_transfer: "",
    //   url: null,
        // bank: []
    };
  },
  mounted(){
      this.getBank()
      this.getPaymentTotal()
  },
  methods: {
    price(){
      if(this.payment_amount > this.max){
        this.payment_amount = this.max
      }
    },
      async getBank(){
let decrypt = new JSEncrypt() // New JSEncrypt Object
          let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
 decrypt.setPrivateKey(privateKey)

// let getWord = decrypt.decrypt(secretWord) // Decrypt
    // this.name = localStorage.getItem("name");
    // this.phone_number = localStorage.getItem("phone_number");
    // console.log(localStorage.getItem("id"))
    axios.get("bank/"+localStorage.getItem("id"))
    .then((res) =>{
        // this.bank = decrypt.decrypt(res.data.id), 
        this.nama_bank = res.data.nama_bank, 
        this.nama_rekening = decrypt.decrypt(res.data.nama_rekening), 
        this.nomor_rekening = decrypt.decrypt(res.data.nomor_rekening)
      } )
    .catch(error => console.log(error))
    },
    getPaymentTotal(){
      let decrypt = new JSEncrypt() // New JSEncrypt Object
          let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
 decrypt.setPrivateKey(privateKey)

      axios.get("payment_researcher_total/" + localStorage.getItem("id"))
      .then((res) =>{this.max = decrypt.decrypt(res.data),  console.log(this.max)} )
      .catch(err => console.log(err))
    },
    // onChange(e) {
    //   this.image_transfer = e.target.files[0];
    //   this.url = URL.createObjectURL(this.image_transfer);
    // },
    async submitSaldo() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let data = new FormData();
      data.append("user_id", localStorage.getItem("id"));
      data.append("nama_bank", this.nama_bank);
      data.append("nama_rekening", this.nama_rekening);
      data.append("nomor_rekening", this.nomor_rekening);
      data.append("payment_amount", this.payment_amount);
      data.append("image_transfer", this.image_transfer);
      await axios
        .post("payment", data, config)
        .then((res) => {
          console.log(res);
          this.$router.push("/payment-company");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped></style>

<template>
  <div id="tambahsaldopemiliksistem">
    <section id="features" class="features">
      <div class="container">
        <div class="row feture-tabs">
          <div class="col-lg-12">
            <h3>Pembayaran</h3>
            <section id="detail" class="detail">
              <div class="container">
                 <div class="row">
                  <form @submit.prevent="submitSaldo">
                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-3">
                          <label class="form-label">Nama Bank </label>
                          <input
                            type="text"
                            v-model="nama_bank"
                            class="form-control"
                            required
                          />
                        </div>
                        <div class="col-3">
                          <label class="form-label">Nama Rekening</label>
                          <input
                            type="text"
                            v-model="nama_rekening"
                            class="form-control"
                            required
                          />
                        </div>
                        <div class="col-3">
                          <label class="form-label">Nomor Rekening</label>
                          <input
                            type="text"
                            v-model="nomor_rekening"
                            class="form-control"
                            required
                          />
                        </div>
                        <div class="col-3">
                          <label class="form-label">Jumlah Transfer</label>
                           <!-- <vue-numeric  class="form-control" v-bind:minus="false" currency="Rp." separator="." v-model="payment_amount"></vue-numeric> -->
                           <input
                            type="number"
                            v-model="total_bayar"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-12">
                          <label class="form-label">Bukti Transfer</label>
                          <input
                            type="file"
                            v-on:change="onChange"
                            class="form-control"
                            required
                          />
                          <img
                            v-if="url"
                            :src="url"
                            class="rounded mt-3"
                            style="
                              width: 155px;
                              height: 150px;
                              object-fit: cover;
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-danger">Simpan</button>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import $ from "jquery";
// import VueNumeric from 'vue-numeric'
import axios from "axios";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
$(document).ready(function () {
  $("#myTable").DataTable();
});
export default {
  name: "TambahSaldoPemilikSistem",
  components: {
    Footer,
    // VueNumeric
  },
  data() {
    return {
      nama_bank: "",
      nama_rekening: "",
      nomor_rekening: "",
      total_bayar: "",
      bukti_transfer: "",
      url: null,
    };
  },
  methods: {
    onChange(e) {
      this.bukti_transfer = e.target.files[0];
      this.url = URL.createObjectURL(this.bukti_transfer);
    },
    async submitSaldo() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let encryptor = new JSEncrypt(); // New JSEncrypt Object
        let publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh3dKb3P9MAbMPij/CaVGmi0sg
onTq0ofRN8hx+6pfXErtXokw7Mtxns/DmDRlpsNcOZrgKdlT5OGu5XkITN7c/om3
bVnTZKYJnyABk0h3i3ecuVuyIkGFL4B2brs98+EBbaKFGTOYX2hhbwbPJV04//Vo
jFBLG0wm21UKu5N2RQIDAQAB`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
      let data = new FormData();
      data.append("user_id", encryptor.encrypt(localStorage.getItem("id")));
      data.append("nama_bank", encryptor.encrypt(this.nama_bank));
      data.append("nama_rekening", encryptor.encrypt(this.nama_rekening));
      data.append("nomor_rekening", encryptor.encrypt(this.nomor_rekening));
      data.append("total_bayar", encryptor.encrypt(this.total_bayar));
      data.append("bukti_transfer", this.bukti_transfer);
      await axios
        .post("payment", data, config)
        .then((res) => {
          console.log(res);
          this.$router.push("/pembayaran-pemilik-sistem");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped></style>

<template>
    <div id="detaillaporanpenelitikeamanan">
    <section id="detail" class="detail">
      <div class="container">
        <div class="row mt-lg-4">
          <div class="col-lg-12 mt-lg-5">
            <div class="card">
              <div class="card-header" style="background-color: #e10000">
                <h3 class="text-white">Detail Laporan</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  
                  <div class="col-8">
                    <div class="row">
                      <div class="col-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label"
                            >Nama Program</label
                          >
                          <h5 class="fw-bold">{{report.program_name}}</h5>
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword1" class="form-label"
                            >Kategori</label
                          >
                          <h5 class="fw-bold">{{report.category}} - {{report.detail}}</h5>
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword1" class="form-label"
                            >Cakupan</label
                          >
                          <h5 class="fw-bold">{{report.scope_report}}</h5>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label"
                            >Tanggal Program</label
                          >
                          <h5 class="fw-bold">{{moment(report.date_start).format("DD-MM-YYYY")}} - {{moment(report.date_end).format("DD-MM-YYYY")}}</h5>
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword1" class="form-label"
                            >Tanggal Laporan</label
                          >
                          <h5 class="fw-bold">
                          {{moment(report.date).format("DD-MM-YYYY")}}
                          </h5>
                        </div>
                        
                      </div>
                    </div>
                    <div class="mt-4">
                      <router-link
                       
                        class="btn btn-danger mt-3"
                        >Ubah Detail Program</router-link
                      >
                    </div>
                  </div>
                  <div class="col-lg-4">
                  </div>
                  <div class="card-body border-top mt-2">
                    <p class="card-text fw-bold">Ringkasan</p>
                    <p>{{report.summary}}</p>
                  </div>
                  <div class="card-body border-top mt-2">
                    <p class="card-text fw-bold">Dampak</p>
                    <p>{{report.impact}}</p>
                  </div>
                  <div class="card-body border-top mt-2">
                    <p class="card-text fw-bold">Catatan</p>
                    <p>{{report.note}}</p>
                  </div>
                  <div class="card-body border-top mt-2">
                    <p class="card-text fw-bold">Deskripsi</p>
                    <p v-html="report.description_report"></p>
                  </div>
                  <div class="card-body border-top mt-2">
                    <p class="card-text fw-bold">File</p>
                    <a :href="path+`file/report/`+report.file" target="_blank">{{report.file}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    

    <Footer />
    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import axios from 'axios'
import moment from 'moment'
import path from "../../path";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
export default {
    name: 'DetailLaporanPenelitiKeamanan',
    components:{
        Footer
    },
    data() {
      return {
        
              report: [],
              moment: moment,
              path: path
          
      }
  },
  created(){
    this.getReport()
  },
  methods:{
    getReport(){
         let decrypt = new JSEncrypt(); // New JSEncrypt Object
       let decrypt2 = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      let privateKeyID = `MIICWwIBAAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv9LlLFAhvMArM60egsmU4jcUL
w8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX/EesghuvDYjG1YCYIfDKm4sd
NdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5URG8Q2M7D8WRHEqpvxAgMBAAEC
gYAdzV2u7I9+gY3GzVXkoQPnUfil4oycI2zawsRb4IwIOu0hOt2mViYnEiRZY0SL
heJiGtnQroi6cHSZKkoB+xjlmUb2oK2atmIJXLQKBkmVfn7ho6lJ7Eu+ux9ZDV1H
WcT2OZchSZz7oEaargMiu6A+HNBnXKkHr8dXjtphrw/EoQJBAM3W5Fucaaaa80fI
7a5PQ6+J4e1rFOn3y8IXx9r1KGwd2eoc7Zn5LonpI9XAp2v9bNBKvKo7mtn4fScA
wXGsFgUCQQCe6RaOPXHnAiAVlZ4JsVlpRjpueUlB2gKAGqfsgHVMPQWxOsE+kwKj
oyUyQa4VyXcMX9KFwE1jFJgc/bwvu8X9AkEAmOqvBTvi5Smx2D92C4vJPexSEl39
0H8NOWkPSSPd3swiaqEJBt0ubLftrkuMVVaU8vzCwji7pnjLCSGvlDICvQJAQrKM
cAkkLJR8yPJyUZkpcQEz9zha4hPR5tjJUH+eO/CE2h1SDKSBgBMywoREwAo+OGhj
oRXUpVh4e/qXqOVENQJAHEmeKGpj9IVyBLipW9ijaRa8/TXsx62LLvydTFoDenE5
eVglVTP1p8viOTzSNXXxOw6hQdyYWdswdGClZkvirw==`;

      decrypt.setPrivateKey(privateKey);
      decrypt2.setPrivateKey(privateKeyID);
      axios.get("report/"+ decrypt2.decrypt(localStorage.getItem('laporanpenelitiID')))
          .then((res) => {
          (this.report = {
            id: decrypt.decrypt(res.data.id),
            user_id: decrypt.decrypt(res.data.user_id),
            program_name: decrypt.decrypt(res.data.program_name),
            summary: decrypt.decrypt(res.data.summary),
            scope_report: decrypt.decrypt(res.data.scope_report),
            impact: decrypt.decrypt(res.data.impact),
            date: decrypt.decrypt(res.data.date),
            date_start: decrypt.decrypt(res.data.date_start),
            date_end: decrypt.decrypt(res.data.date_end),
            category: decrypt.decrypt(res.data.category),
            detail: decrypt.decrypt(res.data.detail),
            note: decrypt.decrypt(res.data.note),
            description_report: res.data.description_report,
            file: res.data.file,

            }) 
      , console.log(res.data[0])})
      .catch(error =>{ console.log(error)})
    }
  }
}
</script>
<template>
    <section id="hero" class="hero d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up">
              {{ title }}
              
            </h1>
            <h5 data-aos="fade-up" data-aos-delay="400">
            <div>
              {{ subtitle }}
            </div>
          </h5>
            <div data-aos="fade-up" data-aos-delay="600">
              <div class="text-center text-lg-start">
                <router-link
                  to="/pilih-registrasi"
                  class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span> Bergabung</span>
                  <i class="bi bi-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 hero-img"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <img :src="img" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'Hero',
    components: {

    },
    props: {
        title: String,
        subtitle: String,
        img: String
    }
}
</script>
<style scoped>
</style>
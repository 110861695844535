import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Program from "../views/Program.vue";
import TentangKami from "../views/TentangKami.vue";
import HubungiKami from "../views/HubungiKami.vue";
import PilihRegistrasi from "../views/PilihRegistrasi.vue";
import VulDis from "../views/VulDis.vue";
import BugBounty from "../views/BugBounty.vue";
import PenTest from "../views/PenTest.vue";
import DetailProgram from "../views/DetailProgram.vue";
import ListPointProgram from "../views/ListPointProgram.vue";

import Login from "../views/Login.vue";
import LupaKataSandi from "../views/LupaKataSandi.vue";
import ResetKataSandi from "../views/ResetKataSandi.vue";
//company
import HalamanUtamaPemilikSistem from "../views/company/HalamanUtamaPemilikSistem.vue";
import RegisterPemilikSistem from "../views/RegisterPemilikSistem.vue";
import PengaturanAkunPemilikSistem from "../views/company/PengaturanAkunPemilikSistem.vue";
import PerbaruiProfilPemilikSistem from "../views/company/PerbaruiProfilPemilikSistem.vue";
import DaftarProgramPemilikSistem from "../views/company/DaftarProgramPemilikSistem.vue";
import EditProgramPemilikSistem from "../views/company/EditProgramPemilikSistem.vue";
import DetailProgramPemilikSistem from "../views/company/DetailProgramPemilikSistem.vue";
import TambahProgramPemilikSistem from "../views/company/TambahProgramPemilikSistem.vue";
import PembayaranPemilikSistem from "../views/company/PembayaranPemilikSistem.vue";
import TambahSaldoPemilikSistem from "../views/company/TambahSaldoPemilikSistem.vue";
import LaporanPemilikSistem from "../views/company/LaporanPemilikSistem.vue";
import DaftarLaporanPemilikSistem from "../views/company/DaftarLaporanPemilikSistem.vue";
import DetailLaporanProgramPemilikSistem from "../views/company/DetailLaporanProgramPemilikSistem.vue";
import UndangPenilitiKeamanan from "../views/company/UndangPenilitiKeamanan.vue";

//reseacher
import RegisterPenelitiKeamanan from "../views/RegisterPenelitiKeamanan.vue";
import HalamanUtamaPenelitiKeamanan from "../views/researcher/HalamanUtamaPenelitiKeamanan.vue";
import PengaturanAkunPenelitiKeamanan from "../views/researcher/PengaturanAkunPenelitiKeamanan.vue";
import AkunBankPenelitiKeamanan from "../views/researcher/AkunBankPenelitiKeamanan.vue";
import PerbaruiProfilPenelitiKeamanan from "../views/researcher/PerbaruiProfilPenelitiKeamanan.vue";
import TambahSertifikatPenelitiKeamanan from "../views/researcher/TambahSertifikatPenelitiKeamanan.vue";
import DaftarSertifikatPenelitiKeamanan from "../views/researcher/DaftarSertifikatPenelitiKeamanan.vue";
import PembayaranPenelitiKeamanan from "../views/researcher/PembayaranPenelitiKeamanan.vue";
import LaporanPenelitiKeamanan from "../views/researcher/LaporanPenelitiKeamanan.vue";
import TambahLaporanPenelitiKeamanan from "../views/researcher/TambahLaporanPenelitiKeamanan.vue";
import DetailLaporanPenelitiKeamanan from "../views/researcher/DetailLaporanPenelitiKeamanan.vue";
import PenarikanSaldoPenelitiKeamanan from "../views/researcher/PenarikanSaldoPenelitiKeamanan.vue";
import PeringkatPenelitiKeamanan from "../views/researcher/PeringkatPenelitiKeamanan.vue";


Vue.use(VueRouter);

const routes = [

  //static
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      guest: true
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/program",
    name: "Program",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Program,
  },
  {
    path: "/tentang-kami",
    name: "Tentang Kami",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TentangKami
  },
  {
    path: "/hubungi-kami",
    name: "Hubungi Kami",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HubungiKami
  },
  {
    path: "/pilih-registrasi",
    name: "Pilih Registrasi",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PilihRegistrasi
  },
  {
    path: "/vulnerability-disclosure",
    name: "Vulnerability Disclosure",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: VulDis
  },
  {
    path: "/bug-bounty",
    name: "Bug Bounty",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: BugBounty
  },
  {
    path: "/penetration-testing",
    name: "Penetration Testing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PenTest
  },
  {
    path: "/detail-program",
    props: true,
    name: "DetailProgram",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DetailProgram,
     //chek is loggedIn
  },
  {
    path: "/list-point-program/:id",
    props: true,
    name: "List Point Program",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ListPointProgram,
     //chek is loggedIn
  },



  //req guest
  {
    path: "/registrasi-pemilik-sistem",
    name: "Register Pemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: RegisterPemilikSistem,
    meta: {
      guest: true
    }
  },
  {
    path: "/registrasi-peneliti-keamanan",
    name: "Register Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: RegisterPenelitiKeamanan,
    meta: {
      guest: true
    }
  },
  {
    path: "/masuk",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: "/reset-kata-sandi",
    name: "Reset Kata Sandi",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ResetKataSandi,
    meta: {
      guest: true
    }
  },
  {
    path: "/lupa-kata-sandi",
    name: "Lupa Kata Sandi",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LupaKataSandi,
    meta: {
      guest: true
    }
  },


  //company
  {
    path: "/halaman-utama-pemilik-sistem",
    name: "Halaman Utama Pemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HalamanUtamaPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/pengaturan-akun-pemilik-sistem",
    name: "Pengaturan Akun Pemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PengaturanAkunPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/perbarui-pemilik-sistem",
    name: "Perbarui Profil Pemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PerbaruiProfilPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/daftar-program-pemilik-sistem",
    name: "Daftar ProgramPemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DaftarProgramPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/ubah-program-pemilik-sistem",
    props: true,
    name: "Edit Program Pemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: EditProgramPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: '/detail-program-pemilik-sistem',
    name: "Detail Program Pemilik Sistem",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DetailProgramPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/tambah-program-pemilik-sistem",
    name: "Tambah Program Pemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TambahProgramPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/pembayaran-pemilik-sistem",
    name: "Pembayaran Pemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PembayaranPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/tambah-saldo-pemilik-sistem",
    name: "Tambah Saldo Pemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TambahSaldoPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/laporan-pemilik-sistem",
    name: "Laporan Pemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LaporanPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/daftar-laporan-pemilik-sistem",
    props: true,
    name: "Daftar Laporan Pemilik Sistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DaftarLaporanPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/detail-laporan-program-pemilik-sistem",
    props: true,
    name: "DetailLaporanProgramPemilikSistem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DetailLaporanProgramPemilikSistem,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  {
    path: "/undang-peneliti-keamanan",
    name: "Undang Peniliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: UndangPenilitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_company: true
    },
  },
  
  



  //researcher
  {
    path: "/halaman-utama-peneliti-keamanan",
    name: "Halaman Utama Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HalamanUtamaPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    },
  },
  {
    path: "/pengaturan-akun-peneliti-keamanan",
    name: "Pengaturan Akun Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PengaturanAkunPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    },
  },
  {
    path: "/akun-bank-peneliti-keamanan",
    name: "Akun Bank Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AkunBankPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    },
  },
  {
    path: "/perbarui-peneliti-keamanan",
    name: "Perbarui Profil Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PerbaruiProfilPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    },
  },

  {
    path: "/tambah-sertifikat-peneliti-keamanan",
    name: "Tambah Sertifikat Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TambahSertifikatPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    },
  },
  {
    path: "/daftar-sertifikat-peneliti-keamanan",
    name: "Daftar Sertifikat Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DaftarSertifikatPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    },
  },
  {
    path: "/pembayaran-peneliti-keamanan",
    name: "Pembayaran Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PembayaranPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    },
  },
  {
    path: "/laporan-peneliti-keamanan",
    name: "Laporan Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LaporanPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    },
  },
  {
    path: "/tambah-laporan-peneliti-keamanan",
    name: "Tambah Laporan Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TambahLaporanPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    },
  },
  {
    path: "/detail-laporan-peneliti-keamanan",
    props: true,
    name: "Detail Laporan Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DetailLaporanPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    },
  },
  {
    path: "/penarikan-saldo-peneliti-keamanan",
    name: "Penarikan Saldo Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PenarikanSaldoPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    }, 
  },
  {
    path: "/peringkat-peneliti-keamanan",
    name: "Peringkat Peneliti Keamanan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PeringkatPenelitiKeamanan,
     //chek is loggedIn
     meta: {
      requiresAuth: true,
      is_researcher: true
    }, 
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});
//define route for handle authentication
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      next({
        path: '/masuk',
        params: { nextUrl: to.fullPath }
      })
    } else {
      const user =  localStorage.getItem('roles')
      if (to.matched.some(record => record.meta.is_company)) {
        if(user === 'pemilik-sistem') {
          next()
          // console.log(user);
        } else  {
          next({
            path: '/halaman-utama-peneliti-keamanan'
          })
        }
      } else {
        next()
      }

      if (to.matched.some(record => record.meta.is_researcher)) {
        if(user === 'peneliti-keamanan') {
          next()
          // console.log(user);
        } else  {
          next({
            path: '/halaman-utama-pemilik-sistem'
          })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    const user =  localStorage.getItem('roles')
    if (localStorage.getItem('token') == null) {
      next()
    } else {
      if(user === 'pemilik-sistem'){
        next({
          path: '/halaman-utama-pemilik-sistem',
          params: {nextUrl: to.fullPath}
        })
      }else if(user === 'peneliti-keamanan'){
        next({
          path: '/halaman-utama-peneliti-keamanan',
          params: {nextUrl: to.fullPath}
        })
      }
    }
  } else {
    next()
  }
})
export default router;
<template>
    <div id="HalamanUtamaPemilikSistem">
            <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->
<section id="detail" class="detail">
        <div class="container">
            <div class="row mt-lg-4">
                <div class="col-lg-12 mt-lg-5">
                    <div class="box mb-3">
                        <div class="row ">
                            <div class="col-md-4 justify-content-center border-end">
                                <div class="row justify-content-center">
                                    <img v-if="user.foto_pengguna == 'default'" src="img/topiputih.png" class="img-fluid detail-img-3 rounded-circle" alt="" />
                                    <img v-if="user.foto_pengguna != 'default'" :src="path+`img/profile_user/`+user.foto_pengguna" class="img-fluid detail-img-3 rounded-circle" alt="" />
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class=" row mt-lg-3">
                                    <h3 v-if="user"> Selamat Datang, {{user.nama}}</h3>
                                    <h3 v-if="!user"> Tidak Ada Login</h3>
                                    <div class="col-6 border-end">
                                        <h5>Program Terdaftar</h5>
                                        <h3 v-if="programL.length > 0">{{programL.length}}</h3>
                                        <h3 v-if="programL.length <= 0">{{programL.length}}</h3>
                                        <router-link class="btn btn-danger" to="/tambah-program-pemilik-sistem">Buat Program</router-link>
                                    </div>
                                    <div class="col-6">
                                        <h5>Saldo Anda</h5>
                                        <h3>Rp. {{formatPrice(payments)}}</h3>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row mt-lg-4">
                    <div class="col-lg-3">
                        <div class="row">
                            <div class="box">
                                <div class="row align-items-center ">
                                    <div class="mt-2 mb-2" style="text-align: center;">
                                        <h5> Laporan Diterima</h5>
                                        <h2>{{totalReport}}</h2>
                                        <!-- <Button type="button" class="border">Detail Laporan</Button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="box">
                            <div class="mt-2 mb-2" style="text-align: center;">
                                <h3>Program Pemilik Sistem</h3>
                            </div>
                            <table id="programDashboard" class="table table-bordered mt-2" style="width:100%">
                                <thead>
                                    <tr>
                                        <th >No</th>
                                        <th >Nama Program</th>
                                        <th >Tanggal Mulai</th>
                                        <th >Tanggal Selesai</th>
                                        <th >Tipe Program</th>
                                        <!-- <th >Aksi</th> -->
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

     <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
import path from "../../path";
import axios from 'axios' 
import moment from 'moment'
import $ from 'jquery'
import {mapGetters} from 'vuex';
// setTimeout(function(){
//        location.reload();
//    },10000);
export default {
    name: 'HalamanUtamaPemilikSistem',
    components:{
        Footer
    },
    data() {
        return {
            path: path,
            userL: [],
            payments: [],
            programL: [],
            totalReport: ''
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
     mounted(){
    this.getProgram(),
    this.getUserL()
    this.getPaymentTotal()
    this.getTotalReport()
    // this.getInvited()
    // location.reload()
     
    },
    methods: {
        formatPrice(value) {
        let val = (value/1).toFixed(0).replace('.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getPaymentTotal(){
          let decrypt = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      decrypt.setPrivateKey(privateKey);
      axios.get("payment_total/" + localStorage.getItem("id"))
      .then(res => this.payments = decrypt.decrypt(res.data.balance))
      .catch(err => console.log(err))
    },
    getTotalReport(){
        axios.get("total_report/" + localStorage.getItem("id"))
      .then(res => this.totalReport = res.data)
      .catch(err => console.log(err))
    },
        
        getProgram(){
      axios.get("program_list/" + localStorage.getItem("id"))
      .then(response => {
        $('#programDashboard').DataTable(
        {
            "lengthMenu": [[5, 10, 25, -1], [5, 10, 25, "All"]],
            "order": [[0, 'asc']],
          data: response.data,
         columns: [
           {
              data: null,
              render: function (data,type,row,meta) {
                return meta.row + 1
              }
            },
           { data: 'program_name' },
           { 
               data: 'date_start',
                render: function(data){
                return moment(data).format("DD-MM-YYYY")
              }
         },
           { 
               data: 'date_end',
                render: function(data){
                return moment(data).format("DD-MM-YYYY")
              }
             },
            { data: 'type' },
           ],
          });
          console.log(response.data);
            this.programL = response.data
      })
      .catch(error => {
        console.log(error.response)
      })
    },
    async getUserL() {
      let decrypt = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      decrypt.setPrivateKey(privateKey);
      axios
        .get("user/" + localStorage.getItem("id"))
        .then((res) => {
          (this.userL = [
            (this.userL = decrypt.decrypt(res.data.nama)),
            (this.userL = res.data.foto_pengguna),
            (this.userL = decrypt.decrypt(res.data.nama_pengguna)),
          ]),
            console.log(this.userL);
        })
        .catch((error) => console.log(error));
    }
    }

}
</script>

<style scoped>

</style>
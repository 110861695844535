<template>
  <div id="daftarsertifikatpenelitikeamanan">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->
    <section id="detail" class="detail">
      <div class="container">
        <div class="row mt-lg-4">
          <div class="col-lg-12 mt-lg-5">
            <router-link to="/tambah-sertifikat-peneliti-keamanan" 
            class="btn btn-danger mb-3">Unggah Sertifikat</router-link>
            <div class="card">
              <div class="card-header text-white"
                style="background-color: #e10000">
                <h3 class="text-white">Daftar Sertifikat Peneliti Keamanan</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <table id="myTable" class="table table-bordered mt-2">
                    <thead>
                      <tr>
                        <th >No</th>
                        <th >Nama Sertifikat</th>
                        <th >Tanggal Sertifikat</th>
                        <th >Tipe Sertifikat</th>
                        <th >File</th>
                        <th >Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr
                        v-for="itemListCertif in listCertif"
                        v-bind:key="itemListCertif.id">
                        <td>{{ itemListCertif.cert_name }}</td>
                        <td>{{ itemListCertif.cert_date }}</td>
                        <td>{{ itemListCertif.cert_type }}</td>
                        <td>
                          <img :src="path+`img/certificate/`+itemListCertif.cert_file"
                            width="100px"
                            height="100px"
                            alt=""
                          />
                        </td>
                        <td>
                          <button class="btn btn-danger" @click="deleteCertif(itemListCertif.id)">Hapus</button>
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
                <!-- <div class="row" v-if="listCertif.length <= 0">
                    <h5>Belum Unggah Sertifikat</h5>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
// import { required, minLength, sameAs } from "vuelidate/lib/validators";
// import { UpperCase, LowerCase, numberV, symbol } from "../../validator.js";
import axios from "axios";
import path from "../../path";
import moment from 'moment';
import $ from 'jquery'
import { mapGetters } from "vuex";
export default {
  name: "DaftarSertifikatPenelitiKeamanan",
  components: {
    Footer,
  },
  data() {
    return {
      id: "",
      listCertif: [],
      error: "",
      path: path
    };
  },
  validations: {},
  created(){
    this.getCertif()
  },
  methods: {
    
   
    async getCertif()
    {
       axios
      .get("cert/" + localStorage.getItem("id"))
      .then((res) => {
        $('#myTable').DataTable(
        {
          data: res.data,
          columns: [
            {
              data: null,
              render: function (data,type,row,meta) {
                return meta.row + 1
              }
            },
            {data: "nama_sertifikat"},
            {
              data: "tanggal_sertifikat", 
              render: function(data){
                return moment(data).format("DD-MM-YYYY")
              }},
            {data: "tipe_sertifikat"},
            {
              data: "berkas_sertifikat",
              render: function(data){
                return `<img src="`+path+`img/certificate/${data}" width="100px" height="100px" alt=""/>`
              },
            },
            {
              data: "id",
              render: function(data){
                return `<button class="btn btn-danger"  data-id="${data}" id="delete">Hapus</button>`
              },
            }
          ]
        });
        console.log(res.data);
        //delete
      $(document).on(`click`, '#delete', function(){
        let id = $(this).data("id");
        
         axios.delete(`cert/`+id)
         .then(res => {location.reload(), console.log(res)})
         .catch(error => {console.log(error)})
      })
      })
      .catch((error) => console.log(error));
    },
    // async deleteCertif(id) {
    //   try {
    //     await axios.delete(`cert/${id}`);
    //     this.getCertif();
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style scoped></style>

<template>
<div id="listpointprogram">
  <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->
    <section id="detail" class="detail">
      <div class="container">
        <div class="row mt-lg-4">
          <div class="col-lg-12 mt-lg-5">
            <div class="box">
              <div class="row">
                <div class="col-md-4 justify-content-center border-end">
                  <div class="row justify-content-center">
                    <img
                      :src="
                        program.program_image === 'default'
                          ? path + 'img/program_image/topiputih.png'
                          : path + 'img/program_image/' + program.program_image
                      "
                      class="img-fluid detail-img-2"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="mt-lg-3">
                    <h3>{{ program.program_name }}</h3>
                    <div class="row">
                      <div class="col-lg-5 border-end">
                        <h5 class="bi bi-building">
                          {{ program.nama }}
                        </h5>
                        <h5
                          v-if="program.type === 'Bug Bounty'"
                          class="bi bi-cash-coin"
                        >
                          Rp.{{
                            program.price_1
                              .toString()
                              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                          }}
                          - Rp.{{
                            program.price_5
                              .toString()
                              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                          }}
                        </h5>
                      </div>
                      <div class="col-lg-7">
                        <h5 class="bi bi-calendar2-check">
                          {{ moment(program.date_start).format("DD-MM-YYYY") }}
                          - {{ moment(program.date_end).format("DD-MM-YYYY") }}
                        </h5>
                        <h5 class="bi bi-globe2">
                          {{ program.category }} Program
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <hr />
      <div class="container">
        <div class="row">
          <div v-for="pointItem in listPoint" v-bind:key="pointItem.user_id"  class="col-lg-3 d-flex flex-column justify-content-center mb-3">
             <div  class="card ms-4" style="width: 14rem">
                      <img
                        :src="
                        pointItem.user.profile_picture === 'default'
                          ? path + 'img/profile_user/topiputih.png'
                          : path + 'img/profile_user/' + pointItem.user.profile_picture
                      "
                        class="card-img-top rounded-circle p-3"
                        width="50px"
                        height="220px"
                        style="object-fit: cover;, padding: 10px"
                        alt="..."/>
                      <div class="card-body text-center">
                        <h3 class="card-title">{{pointItem.user.name}}</h3>
                        <p class="card-text">{{pointItem.points}} Poin</p>
                      </div>
                    </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= Footer ======= -->
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import axios from "axios";
import moment from "moment";
import path from "../path";
export default {
  name: "ListPointProgram",
  components: {
    Footer,
  },
  data() {
    return {
      program: [],
      listPoint: [],
      moment: moment,
      category: '',
      path: path,
    };
  },
  mounted() {
    // console.log(this.program.program_name)
      this.getProgram()
      this.getListPoint()
    
  },
  methods: {
    getProgram(){
      axios
        .get("program/" + this.$route.params.id)
        .then((res) => {
          (this.program = res.data)
          if(localStorage.getItem("id") != null){
            if(res.data.category === 'Privat'){
              this.getResearcher()
              console.log(res.data.category)
            }else{
              console.log(res.data.category)
            }
          }
           console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getResearcher(){
      axios.post("researcher",{
        user_id: localStorage.getItem("id"),
        program_id:  this.$route.params.id
      })
      .then((res) => {
        if(res.data.length <= 0){
          this.$router.push('/program')
        }
       console.log(res.data)})
      .catch((err) => {console.log(err)})
    },
    getListPoint(){
        axios.get("point_program/"+this.$route.params.id)
        .then(res => {this.listPoint = res.data, console.log(res.data)})
        .catch(err => {console.log(err)})
    }
  },
};
</script>
<style scoped></style>
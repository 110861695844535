<template>
    <div id="peringkatpenelitikeamanan">
<section id="features" class="features">
      <div class="container">
        <div class="row feture-tabs">
          <div class="col-lg-12">
            <h3>Papan Peringkat Peneliti Keamanan</h3>
            <section id="detail" class="detail">
              <div class="container">
                <div class="row">
          <!-- <div class="col-md-8 offset-md-2">
            <select v-model="searchQuery" class="form-control" >
              <option v-for="item3 in typeList" v-bind:key="item3.id" :value="item3.name">
                  {{ item3.name }}
              </option>
            </select>
          </div> -->
          <div class="col-md-8 offset-md-2">
            <div id="nav-tabContents" class="tab-content">
              <div id="last-month" role="tabpanel" aria-labelledby="nav-home-tab" class="tab-pane fade active show">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Peringkat</th>
                      <th>Pemain</th>
                      <th>Point</th>
                    </tr>
                  </thead>
                    <tbody>
                        <tr v-for="(listleaderboard, itemk) in leaderboard" v-bind:key="listleaderboard.id">
                            <td>{{itemk+1}}</td>
                            <td><span class="ms-3">{{listleaderboard.user.nama}}</span></td>
                            <td>{{listleaderboard.points}}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import axios from 'axios'
export default {
    name: 'PeringkatPenelitiKeamanan',
    components: {Footer},
    data(){
        return {
            leaderboard: [],
            searchQuery: 'Bug Bounty',
              // type: "",
              typeList: [
                      { id: 1, name: "All" },
                      { id: 2, name: "Six" }
                        ],
        }
    },
    mounted(){
        this.getLeaderboard()
    },
    methods:{
        getLeaderboard(){
            axios.get("point")
            .then(res => this.leaderboard = res.data)
            .catch(err => console.log(err))
        },
        getLeaderboard6mounth(){
            axios.get("list_point")
            .then(res => this.leaderboard = res.data)
            .catch(err => console.log(err))
        },
    }
}
</script>
<style scoped>

</style>
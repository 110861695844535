<template>
  <div id="pentest">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <Hero :title="title" :subtitle="subtitle" :img="img" />
    <!-- End Hero -->
    <section id="features" class="features">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <p>Kenapa dilakukan Penetration Testing ?</p>
        </header>

        <!-- Feature Tabs -->
        <div class="row feture-tabs" data-aos="fade-up">
          <div class="col-lg-6">
            <!-- Tabs -->
            <ul class="nav nav-pills mb-3">
              <li>
                <a class="nav-link active" id="btn1">Latar Belakang</a>
              </li>
              <li>
                <a class="nav-link" id="btn2">Tahapan</a>
              </li>
              <li>
                <a class="nav-link" id="btn3">Standarisasi</a>
              </li>
            </ul>
            <!-- End Tabs -->

            <!-- Tab Content -->
            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab1">
                <p>
                  Perusahaan besar tentu saja telah memiliki tenaga atau
                  karyawan yang handal di bidang IT (ahli dalam pemograman,
                  Jaringan, dan peralatan lainnya). Perusahaan anda memiliki
                  beberapa cabang di seluruh Indonesia dan semuanya terkoneksi
                  dengan jaringan fiber optik. Segala sesuatunya terkoneksi
                  dengan server pusat sehingga data yang di kelola selalu
                  terkini.Namun hal ini tidak menjamin, jika perusahaan menilai
                  dari segi berikut:
                </p>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <p>
                    Apakah ada jaminan bahwa karyawan anda tidak menyalahgunakan
                    wewenang yang dimiliki (serangan dari dalam)
                  </p>
                </div>

                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <p>
                    Apakah sistem perusahaan sepenuhnya aman dari serangan
                    tangan-tangan jahil? (Hacker, Cracker, Phreaker, Defacer)
                  </p>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <p>
                    Bagaimana Jika pesaing anda mencoba masuk ke system anda
                    untuk mengetahui semua sistem perusahaan anda?
                  </p>
                </div>
              </div>
              <!-- End Tab 1 Content -->

              <div class="tab-pane fade show" id="tab2">
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h4>#1. Planning and Reconnaissance</h4>
                </div>
                <p>
                  Menentukan ruang lingkup dan tujuan pengujian, termasuk sistem
                  yang akan ditangani dan metode pengujian yang akan digunakan
                </p>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h4>#2. Scanning</h4>
                </div>
                <p>
                  Memindai target menggunakan berbagai "tool" yang ada untuk
                  mencari celah keamanan yang bisa digunakan untuk masuk ke
                  dalam sistem
                </p>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h4>#3. Gaining Access</h4>
                </div>
                <p>
                  Percobaan untuk menerobos masuk ke dalam sistem setelah
                  memindai yang dilakukan menemukan celah keamanan
                </p>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h4>#4. Maintaining access</h4>
                </div>
                <p>
                  Hak akses yang telah dimiliki oleh peneliti keamanan tadi
                  apakah tetap bisa menjadi penyerang atau apakah bisa ditutup
                </p>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h4>#5. Analysis</h4>
                </div>
                <p>
                  Hasil Penetration Testing kemudian dikompilasi menjadi laporan
                  dengan rincian, celah keamanan yang dapat dieksploitasi, data
                  yang dapat diambil atau diakses, waktu yang digunakan untuk
                  menerobos
                </p>
              </div>
              <!-- End Tab 2 Content -->

              <div class="tab-pane fade show" id="tab3">
                <p>
                  Untuk melakukan Penetration Testing tentu tidak sembarang.
                  Sekarang ada lembaga yang melakukan standarisasi dalam
                  Penetration Testing. Lembaga itu adalah general service
                  administration atau GSA yang telah menstandarisasi layanan
                  Penetration Testing sebagai layanan dukungan yang telah
                  diperiksa sebelumnya.
                </p>
                <p>
                  Langkah ini sebagai upaya untuk mengidentifikasi penyedia
                  layanan Penetration Testing untuk ditinjau dan diperiksa
                  secara teknis apakah sudah sesuai standar atau tidak.
                </p>
                <p>
                  132-45A Penetration Testing adalah pengujian keamanan di mana
                  penilai layanan meniru serangan dunia nyata untuk
                  mengidentifikasi metode untuk menghindari fitur keamanan
                  aplikasi, sistem, atau jaringan
                </p>
              </div>
              <!-- End Tab 3 Content -->
            </div>
          </div>

          <div class="col-lg-6">
            <img src="img/features-2.png" class="img-fluid" alt="" />
          </div>
        </div>
        <!-- End Feature Tabs -->
      </div>
    </section>
    <Footer />
    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Footer from "@/components/Footer.vue";
// import $ from "jquery";
// $(document).ready(function(){
// //   var btn1 = document.getElementById('btn1');
// //   var btn2 = document.getElementById('btn2');
// //   var btn3 = document.getElementById('btn3');
// $('#btn1').click(function(){
//    $("#tab1").show();
//     $("#tab2").hide();
//     $("#tab3").hide();
//     $("#btn1").addClass("active");
//     $("#btn2").removeClass("active");
//     $("#btn3").removeClass("active");
// });
// $('#btn2').click(function(){
//     $("#tab2").show();
//     $("#tab1").hide();
//     $("#tab3").hide();
//     $("#btn2").addClass("active");
//     $("#btn1").removeClass("active");
//     $("#btn3").removeClass("active");
// });
// $('#btn3').click(function(){
//       $("#tab3").show();
//     $("#tab1").hide();
//     $("#tab2").hide();
//     $("#btn3").addClass("active");
//     $("#btn2").removeClass("active");
//     $("#btn1").removeClass("active");
// });

// });
export default {
  name: "PenetrationTesting",
  components: {
    Hero,
    Footer,
  },
  data() {
    return {
      title: "Penetration Testing",
      subtitle: `Kerja sama formal
              pemilik sistem dan peneliti keamanan
              untuk menemukan kerentanan sistem
              secara komprehensif
              dan dengan penghargaan finansial
              yang disepakati bersama`,
      img: "img/icon-pentest.png",
    };
  },
};
</script>
<style scoped></style>

<template>
  <div id="pembayaranpemiliksistem">
    <section id="features" class="features">
      <div class="container">
        <div class="row feture-tabs">
          <div class="col-lg-12">
            <h3>Pembayaran</h3>
            <section id="detail" class="detail">
              <div class="container">
                <div class="box">
                  <div class="row mt-2 justify-content-center">
                    <div class="col-md-4 text-center">
                      <h4>Saldo Tersisa</h4>
                      <h2>Rp. {{formatPrice(payments.balance)}}</h2>
                    </div>
                    <div class="col-md-4 text-center border-start border-end">
                      <h4>Saldo Digunakan</h4>
                      <h2>Rp. {{formatPrice(payments.used)}}</h2>
                    </div>
                    <div class="col-md-4 text-center">
                      <h4>Total</h4>
                      <h2>Rp. {{formatPrice(payments.total)}}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <h3>Rekap Transaksi</h3>
            <router-link class="btn btn-danger mb-2" to="/tambah-saldo-pemilik-sistem" >Tambah Saldo</router-link>
            <table id="paymentTopUpList" class="table table-bordered display mt-2">
              <thead class="text-white" style="background-color: #e10000">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Tanggal Transaksi</th>
                  <th scope="col">Jumlah</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
            <h3 class="mt-5">Riwayat Pembayaran</h3>
            <!-- <router-link class="btn btn-danger mb-2" to="/add-saldo-company" >Tambah Saldo</router-link> -->
            <div  v-for="listPaymentReport in paymentsReport" v-bind:key="listPaymentReport.id"  class="modal fade" :id="`exampleModal`+listPaymentReport.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Detail Riwayat Pembayaran</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
                <div class="row">
                  <div class="col-6">
                    <div class="row justify-content-center">
                   <h4 class="text-black">{{listPaymentReport.id}}</h4>
                    </div>
                  </div>

                </div>
            
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Tutup</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
            <table id="paymentProgram" class="table table-bordered display mt-2">
              <thead class="text-white" style="background-color: #e10000">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Nama Program</th>
                  <th scope="col">Jumlah Hadiah</th>
                  <th scope="col">Status</th>
                  <th scope="col">Tanggal</th>
                  <!-- <th scope="col">Aksi</th> -->
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import moment from "moment";
import axios from 'axios'
import $ from "jquery";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
$(document).ready(function () {
  $("#myTable").DataTable();
});
export default {
  name: "PembayaranPemilikSistem",
  components: {
    Footer,
  },
  data() {
    return {
      payments: [],
      paymentsReport: []
    }
  },
  mounted(){
    this.getPaymentTopUp()
    this.getPaymentProgram()
    this.getPaymentProgramReport()
    this.getPaymentTotal()
  },
  methods:{
      formatPrice(value) {
        let val = (value/1).toFixed(0).replace('.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getPaymentTopUp() {
      axios
        .get("payment/" + localStorage.getItem("id"))
        .then((res) => {
          $("#paymentTopUpList").DataTable({
            "lengthMenu": [[5, 10, 25, -1], [5, 10, 25, "All"]],
            "order": [[0, 'asc']],
            data: res.data.history,
            columns: [
              {
                data: null,
                render: function (data, type, row, meta) {
                  return meta.row + 1;
                },
              },
              {
                data: "tanggal_pembayaran",
                render: function (data) {
                  return moment(data).format("DD-MM-YYYY");
                },
              },
              { data: "total_bayar",
                render: function(data){
                  let val = (data/1).toFixed(0).replace('.', ',')
                  return `Rp. `+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }              
              },
              { data: "status" },
            ],
          });
          console.log(res.data);
        })
        .catch((error) => console.log(error));
      
    },
    getPaymentProgram() {
      axios
        .get("payment_program_company/" + localStorage.getItem("id"))
        .then((res) => {
          $("#paymentProgram").DataTable({
            "lengthMenu": [[5, 10, 25, -1], [5, 10, 25, "All"]],
            "order": [[0, 'asc']],
            data: res.data,
            dom: 'Bfrtip',
             buttons: [ 'csv', 'excel', 'print'
              ],
            columns: [
              {
                data: null,
                render: function (data, type, row, meta) {
                  return meta.row + 1;
                },
              },
              { data: "program_name" },
               { data: "reward",
                render: function(data){
                  let val = (data/1).toFixed(0).replace('.', ',')
                  return `Rp. `+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }              
              },
              { data: "status_reward" },
              {
                data: "updated_at",
                render: function (data) {
                  return moment(data).format("DD-MM-YYYY");
                },
              },
            //   {
            //   data: "report_userid",
            //   render: function(data){
            //     // if(row.status_report === 'Ditolak'){
            //     return  `<button type="button" class="btn btn-danger"  data-bs-toggle="modal" data-bs-target="#exampleModal${data}">Detail</button>`
            //     // }else{
            //     //   return  `<h4>${row.status_report}</h4>`
            //     // }
            //   }
            // },
             
            ],
          });
          console.log(res.data);
        })
        .catch((error) => console.log(error));
      
    },
    getPaymentProgramReport(){
      axios.get("report")
      .then(res => this.paymentsReport = res.data)
      .catch(err => console.log(err))
    },
    getPaymentTotal(){
             let decrypt = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      decrypt.setPrivateKey(privateKey);
      axios.get("payment_total/" + localStorage.getItem("id"))
      .then((res) => {
          (this.payments = {
            balance: decrypt.decrypt(res.data.balance),
            used: decrypt.decrypt(res.data.used),
            total: decrypt.decrypt(res.data.total)
          }) , console.log(res.data);
        })
      .catch(err => console.log(err))
    }
  }
};
</script>
<style scoped></style>

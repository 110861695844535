<template>
  <div id="perbaruiprofilpemiliksistem">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->
    <section id="detail" class="detail">
      <div class="container">
        <div class="row mt-lg-4">
          <div class="col-lg-12 mt-lg-5">
          <div class="card">
            <div class="card-header text-white" style="background-color: #e10000;">
              <h3 class="text-white">Data Profil Pemilik Sistem</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <form @submit.prevent="submitUser">
                  <div class="mb-3">
                    <label  class="form-label">Nama</label>
                    <input type="text" v-model="nama" class="form-control" required>
                  </div>
                  <div class="mb-3">
                    <label  class="form-label">Nama Kontak </label>
                    <input type="text" v-model="nama_pengguna" class="form-control" required>
                  </div>
                  <div class="mb-3">
                    <label  class="form-label">Alamat Surat Elektronik/Nama Pengguna</label>
                    <input type="text" v-model="email" class="form-control" required >
                  </div>
                  <div class="mb-3">
                    <label  class="form-label">Nomor HP</label>
                    <input type="number" v-model="nomor_telepon" class="form-control" required >
                  </div>
                  <button type="submit" class="btn btn-danger">Simpan</button>
                </form>
              </div>
            </div>
          </div>
        </div> 
        </div>
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
// import { required, minLength, sameAs } from "vuelidate/lib/validators";
// import { UpperCase, LowerCase, numberV, symbol } from "../../validator.js";
import axios from "axios";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
import { mapGetters } from "vuex";
export default {
  name: "PerbaruiProfilPemilikSistem",
  components: {
    Footer,
  },
  data() {
    return {
      id: "",
      nama: "",
      nama_pengguna: "",
      email: "",
      nomor_telepon: '',
      error: "",
    };
  },
  validations: {
  },
  methods: {
    
    async submitUser(){
       let encryptor = new JSEncrypt(); // New JSEncrypt Object

      let publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh3dKb3P9MAbMPij/CaVGmi0sg
onTq0ofRN8hx+6pfXErtXokw7Mtxns/DmDRlpsNcOZrgKdlT5OGu5XkITN7c/om3
bVnTZKYJnyABk0h3i3ecuVuyIkGFL4B2brs98+EBbaKFGTOYX2hhbwbPJV04//Vo
jFBLG0wm21UKu5N2RQIDAQAB`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
         await axios.post("edit_user",{
          id: encryptor.encrypt(localStorage.getItem("id")),
          nama: encryptor.encrypt(this.nama),
          nama_pengguna: encryptor.encrypt(this.nama_pengguna),
          email: encryptor.encrypt(this.email),
          nomor_telepon: encryptor.encrypt(this.nomor_telepon)
        }).then((res) => {
          console.log(res)
          this.$router.push('/pengaturan-akun-pemilik-sistem')
        }).catch((error) => {
          console.log(error)
        })
        // console.log(response)
    }
  },
  mounted() {
     let decrypt = new JSEncrypt() // New JSEncrypt Object
          let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
 decrypt.setPrivateKey(privateKey)
    
    axios.get("user/"+localStorage.getItem("id"))
    .then((res) => {
        this.nama = decrypt.decrypt(res.data.nama), 
        this.nama_pengguna = decrypt.decrypt(res.data.nama_pengguna), 
        this.email = decrypt.decrypt(res.data.email), 
        this.nomor_telepon = decrypt.decrypt(res.data.nomor_telepon), 
        console.log(res.data)
        } )
    .catch(error => console.log(error))
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style scoped></style>

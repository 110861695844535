<template>
  <div id="registerpemiliksistem">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->
    <section id="login" class="login">
      <div class="container">
        <div class="row mt-2 align-items-center justify-content-center">
          <div class="col-lg-5 mt-4 mt-lg-5">
            <header class="section-header mb-3">
              <p>Pendaftaran <br />Pemilik Sistem</p>
            </header>
            <div class="box">
              <div class="">
                <div class="row gy-1">
                    <div v-if="error" class="alert alert-danger" role="alert">
                    {{error}}
                  </div>
                  <form
                    class="php-email-form"
                    @submit.prevent="submit"
                  >
                    <div class="row gy-4">
                      <div class="col-md-12" :class="{ 'form-group--error': $v.nama.$error }">
                        <h3 class="mb-2 text-start">Nama Pemilik Sistem</h3>
                        <input
                          type="text"
                          class="form-control"
                          style="border-radius: 8px"
                          name="subject"
                          v-model.trim="$v.nama.$model"
                          required
                        />
                      </div>
                      <div class="clas text-start text-danger" v-if="!$v.nama.minLength">
                          Minimal {{$v.nama.$params.minLength.min}} karakter
                        </div>
                      <div class="col-md-12" :class="{ 'form-group--error': $v.nama_pengguna.$error }">
                        <h3 class="mb-2 text-start">Nama Kontak</h3>
                        <input
                          type="text"
                          class="form-control"
                          style="border-radius: 8px"
                          name="subject"
                           v-model.trim="$v.nama_pengguna.$model"
                          required
                        />
                      </div>
                      <div class="col-md-12" :class="{ 'form-group--error': $v.nomor_telepon.$error }">
                        <h3 class="mb-2 text-start">Nomor HP Kontak</h3>
                        <input
                          type="number"
                          class="form-control"
                          style="border-radius: 8px"
                          name="subject"
                           v-model.trim="$v.nomor_telepon.$model"
                          required
                        />
                      </div>
                      <div class="col-md-12" :class="{ 'form-group--error': $v.email.$error }" style="margin-bottom: -20px:">
                        <h3 class="mb-2 text-start">
                          Alamat Surat Elektronik / <br />Nama Pengguna
                        </h3>
                        <input
                          type="text"
                          class="form-control"
                          style="border-radius: 8px"
                          name="subject"
                           v-model="$v.email.$model"
                          required
                        />
                        <div class="clas text-start text-danger" v-if="!$v.email.email">
                          Masukkan Alamat Surat Elektronik
                        </div>
                        <div class="clas text-start text-danger" v-if="!$v.email.notGmail">
                          Alamat harus alamat perusahaan
                        </div>
                        <div class="clas text-start text-danger" v-if="!$v.email.notYahoo">
                          Alamat harus alamat perusahaan
                        </div>
                      </div>

                      <div class="col-md-12" :class="{ 'form-group--error': $v.password.$error }" style="margin-bottom: -20px">
                        <h3 class="mb-2 text-start">Kata Sandi</h3>
                        <input
                          class="form-control"
                          style="border-radius: 8px"
                          type="password"
                           v-model="$v.password.$model"
                          required
                        />
                        <div class="ms-3 text-start text-danger" v-if="!$v.password.LowerCase || !$v.password.UpperCase || !$v.password.numberV || !$v.password.symbol || !$v.password.minLength">
                          Kata Sandi harus memiliki min.:
                        </div>
                        <ul class="text-start text-danger">
                          <li  v-if="!$v.password.LowerCase">
                            <div class="error">1 huruf kecil</div>
                          </li>
                          <li v-if="!$v.password.UpperCase">
                            <div class="error" >1 huruf kapital</div>
                          </li>
                          <li v-if="!$v.password.symbol">
                            <div class="error" >1 tanda baca / simbol</div>
                          </li>
                          <li v-if="!$v.password.numberV">
                            <div class="error" >1 angka</div>
                          </li>
                          <li v-if="!$v.password.minLength">
                            <div class="error" >panjang {{$v.password.$params.minLength.min}} karakter</div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-12" :class="{ 'form-group--error': $v.password_confirm.$error }">
                        <h3 class="mb-2 text-start">Konfirmasi Kata Sandi</h3>
                        <input
                          class="form-control"
                          style="border-radius: 8px"
                          type="password"
                           v-model="$v.password_confirm.$model"
                          required
                        />
                        <div class="error text-start text-danger" v-if="!$v.password_confirm.sameAsPassword">
                          Kata sandi tidak cocok
                        </div>
                      </div>
                      <p><a href="" target="_blank">Syarat dan Ketentuan</a></p>
                      <div class="col-md-12 mt-2 text-center">
                        <vue-recaptcha
                        class="row justify-content-center mb-3"
                        style="padding-right: 15px"
                          sitekey="6Ldsgb0dAAAAALJdjxCz5CCv1nEDGRGF7OgxIIz8"
                          ref="recaptcha"
                          @verify="onCaptchaVerified"
                          @expired="onCaptchaExpired"
                        >
                        </vue-recaptcha>
                        <button type="submit" :disabled="this.$v.$invalid || this.isVerified === false">Daftar</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Section -->

    <!-- ======= Footer ======= -->
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import { VueRecaptcha } from "vue-recaptcha";
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import {  notGmail, notYahoo, UpperCase, LowerCase, numberV, symbol } from "../validator.js";
import axios from 'axios'
import JSEncrypt from "jsencrypt/bin/jsencrypt";
export default {
  name: "RegisterPemilikSistem",
  components: {
    Footer,
    VueRecaptcha,
  },
  data() {
    return {
            nama: '',
            nama_pengguna: '',
            nomor_telepon: '',
            email: '',
            password: '',
            password_confirm: '',
            roles: 'pemilik-sistem',
            isVerified: false,
            error: ''
    };
  },
  validations: {
      nama: { required,  minLength: minLength(8) },
      nama_pengguna: { required },
      nomor_telepon: { required },
      email: { required, email, notGmail, notYahoo },
     password: {  UpperCase, LowerCase, numberV, symbol, minLength: minLength(8) },
      password_confirm: { sameAsPassword: sameAs('password') }
  },
  methods: {
    onCaptchaVerified() {
      console.log("wes dicentang");
      this.isVerified = true;
    },
    onCaptchaExpired: function () {
      this.isVerified = false;
      console.log("drung dicentang");
      this.$refs.recaptcha.reset();
    },
    async submit(){
           let encryptor = new JSEncrypt(); // New JSEncrypt Object

      let publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh3dKb3P9MAbMPij/CaVGmi0sg
onTq0ofRN8hx+6pfXErtXokw7Mtxns/DmDRlpsNcOZrgKdlT5OGu5XkITN7c/om3
bVnTZKYJnyABk0h3i3ecuVuyIkGFL4B2brs98+EBbaKFGTOYX2hhbwbPJV04//Vo
jFBLG0wm21UKu5N2RQIDAQAB`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
        const password =this.password;
        this.$v.$touch()
      try{
            await axios.post('register', {
              nama : this.nama,
              email : encryptor.encrypt(this.email),
              nama_pengguna: this.nama_pengguna,
              nomor_telepon: this.nomor_telepon,
              foto_pengguna: 'default',
              password : encryptor.encrypt(password),
              roles: encryptor.encrypt(this.roles)
        });
        this.$router.push('/login');
      }catch(e){
          this.error = e;
      }
    }
  },
};
</script>
<style scoped></style>

<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <HeaderLanding />

    <router-view />
  </div>
</template>

<script>
import HeaderLanding from "@/components/HeaderLanding.vue";
import axios from "axios";
// import $ from 'jquery'
import JSEncrypt from "jsencrypt/bin/jsencrypt";

export default {
  name: "App",
  components: {
    HeaderLanding,
  },
  async created() {
    let decrypt = new JSEncrypt() // New JSEncrypt Object
          let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
 decrypt.setPrivateKey(privateKey)
    const response = await axios.get("user");
    const userL = {
         nama:  decrypt.decrypt(response.data.nama),
         nama_pengguna:  decrypt.decrypt(response.data.nama_pengguna),
         email:  decrypt.decrypt(response.data.email),
         roles:  decrypt.decrypt(response.data.roles),
         nomor_telepon:  decrypt.decrypt(response.data.nomor_telepon),
         foto_pengguna:  response.data.foto_pengguna
    }
    this.$store.dispatch("user", userL);
    console.log(response)
    // console.log("Here", localStorage.getItem("token"));
    // if(response === null){
    // }
    // console.log("siap")
    
   var wktu = localStorage.getItem('time');
      var now = new Date();
var time = now.getTime();
if(wktu != null){
   if(time > wktu){
       localStorage.removeItem('token');
        localStorage.removeItem('time');
        localStorage.removeItem('id');
        localStorage.removeItem('roles');
        this.$store.dispatch('user', null);
        window.location = '/'
   }else{
     console.log("masih ada")
   }
   }
//    if(wktu != null){
//      var now = new Date();
// var time = now.getTime();
// // var expireTime = wktu + (1000*60*60*6);
// now.setTime(wktu);
// document.cookie = 'cookie=ok;expires='+now.toUTCString()+';path=/';
// console.log(wktu)
// // console.log(wktu)
// console.log(time)
//    }
   
  },
  data: function (){
    return {
      events: ['click', 'mousemove','mousedown','scroll','keypress','load','onload'],
      warningTimer: null,
      logoutTimer: null,
      // warningZone: false
    }
  },
  mounted(){
    if(localStorage.getItem("token") != null){
      this.events.forEach(function (event){
      window.addEventListener(event, this.resetTimer);
    }, this);

    this.setTimers();
    }
    console.log("siap")
  },
  // destroyed(){
  //   this.events.forEach(function (event){
  //     window.removeEventListener(event, this.resetTimer);
  //   }, this)

  //   this.resetTimer();
  // },

  methods:{
    setTimers: function(){
      this.warningTimer = setTimeout(this.warningMessage, 60 * 60 * 1000); // 4 * 60 * 1000 = 4 menit
      // this.logoutTimer = setTimeout(this.logoutUSer, 10 * 1000);
    
  //console.log(document.cookie);  // 'Wed, 31 Oct 2012 08:50:17 UTC'
      // this.warningZone = false;
    },

    warningMessage: function(){
      localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('roles');
        this.$store.dispatch('user', null);
         window.location = '/'
    },

    resetTimer: function(){
      clearTimeout(this.warningTimer);

      this.setTimers()
    }

  }
};

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50; */
}

/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>

<template>
    <div id="hubungikami">
        <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= --> 
      <Hero :title="title" :subtitle="subtitle" :img="img"  />
    <!-- End Hero -->
    
    <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">
      <header class="section-header">
        <p>Kontak Kami</p>
      </header>

      <div class="row gy-4">
        <div class="col-lg-6">
          <div class="row gy-4">
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-geo-alt"></i>
                <h3>Alamat</h3>
                <p>
                  Graha Bumiputera Lt.8 <br />Jl. Raya Darmo No.155-159<br />Surabaya, 60241
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-telephone"></i>
                <h3>Hubungi Kami</h3>
                <p>(031) 561 5175</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-envelope"></i>
                <h3>Email Kami</h3>
                <p>admin@topiputih.id</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-clock"></i>
                <h3>Jam Kerja</h3>
                <p>Senin - Jumat<br />08:00 WIB - 17:00 WIB</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <form action="forms/contact.php" method="post" class="php-email-form">
            <div class="row gy-4">
              <div class="col-md-6">
                <input type="text" name="name" class="form-control" placeholder="Your Name" required />
              </div>

              <div class="col-md-6">
                <input type="email" class="form-control" name="email" placeholder="Your Email" required />
              </div>

              <div class="col-md-12">
                <input type="text" class="form-control" name="subject" placeholder="Subject" required />
              </div>

              <div class="col-md-12">
                <textarea class="form-control" name="message" rows="6" placeholder="Message" required></textarea>
              </div>

              <div class="col-md-12 text-center">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Pesanmu Sudah Terkirim. Terima Kasih!
                </div>

                <button type="submit">Kirim Pesan</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

    <!-- ======= Footer ======= -->
    <Footer/>

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Hero from '@/components/Hero.vue'
import Footer from '@/components/Footer.vue'
export default {
    name: 'HubungiKami',
    components: {
    Hero,
    Footer,
    },
  data() {
      return {
          
              title: "Hubungi Kami",
              subtitle: "Jika Anda membutuhkan informasi lebih lanjut, silahkan hubungi kami pada kontak di bawah ini.",
              img: "img/landing/kontak.png"
          
      }
  }
}
</script>
<style scoped>

</style>
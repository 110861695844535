<template>
    <div id="tambahlaporanpenelitikeamanan">
     <section id="detail" class="detail">
    <div class="container">
      <div class="row mt-lg-4">
        <div class="col-lg-12 mt-lg-5">
          <div class="box ">
            <div class="row ">
              <div class="col-md-4 justify-content-center border-end">
                <div class="row justify-content-center">
                   <img
                      :src="
                        program.program_image === 'default'
                          ? path + 'img/program_image/topiputih.png'
                          : path + 'img/program_image/' + program.program_image
                      "
                      class="img-fluid detail-img-2"
                      alt=""
                    />
                </div>
              </div>
              <div class="col-md-8">
                <div class="mt-lg-3">
                  <h3>{{program.program_name}}</h3>
                   <div class="row">
                      <div class="col-lg-5 border-end">
                        <h5 class="bi bi-building">
                          {{ program.nama }}
                        </h5>
                        <h5
                          v-if="program.type === 'Bug Bounty'"
                          class="bi bi-cash-coin"
                        >
                          Rp.{{
                            program.price_1
                              .toString()
                              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                          }}
                          - Rp.{{
                            program.price_5
                              .toString()
                              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                          }}
                        </h5>
                      </div>
                      <div class="col-lg-7">
                        <h5 class="bi bi-calendar2-check">
                          {{ moment(program.date_start).format("DD-MM-YYYY") }}
                          - {{ moment(program.date_end).format("DD-MM-YYYY") }}
                        </h5>
                        <h5 class="bi bi-globe2">
                          {{ program.category }} Program
                        </h5>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-3">
      <div class="row">
        <div class="col-lg-12 d-flex flex-column justify-content-center mb-3">
           <div class="card">
              <div
                class="card-header text-white"
                style="background-color: #e10000"
              >
                <h3 class="text-white">Kirim Laporan</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <form @submit.prevent="submitReport">
                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-12">
                          <label class="form-label">Kerentanan</label
                          >
                          <input
                            type="text"
                            v-model="summary"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                     <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-12">
                          <label class="form-label">Objek</label >
                      <input
                        type="text"
                        v-model="scope_report"
                        class="form-control"
                        required
                      />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-6">
                          <label class="form-label">Kategori</label
                          >
                          <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                          <select
                            v-model="category_id"
                            class="form-control"
                            required>
                            <option v-for="listCat in listCategory" v-bind:key="listCat.id" :value="listCat.id">{{listCat.category}} - {{listCat.detail}}</option>
                          </select>
                        </div>
                        <div class="col-6">
                          <label class="form-label">Dampak</label
                          >
                          <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                         <input
                        type="text"
                        v-model="impact"
                        class="form-control"
                        required
                      />
                        </div>
                      </div>
                    </div>
                      <div class="mb-3">
                     <div class="row col-lg-12">
                       <div class="col-12">
                          <label class="form-label">Deskripsi</label>
                    
                       <ckeditor v-model="description_report"  required></ckeditor>
                       </div>
                     </div>
                    </div>
                     
                    
                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-12">
                          <label class="form-label">File Laporan</label
                      >
                      <input
                        type="file"
                        v-on:change="onChange"
                        class="form-control"
                        accept="application/pdf"
                        required
                      />
                      <object v-if="fileview" :data="fileview" width="100%" height="350"/>
                        </div>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-danger">Kirim Laporan</button>
                  </form>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
    
    
     <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import path from "../../path";
import moment from "moment";
import axios from 'axios'
import JSEncrypt from "jsencrypt/bin/jsencrypt";
export default {
    name: 'TambahLaporanPenelitiKeamanan',
    components:{
        Footer
    },
    data(){
      return {
        summary: '',
        scope_report: '',
        category_id: '',
        impact: '',
        description_report: '',
        file: '',
        program: [],
        moment: moment,
        path: path,
        fileview: null,
        listCategory: []
      }
    },
    mounted(){
      this.getProgram()
      this.getListCategory()
    },
    methods:{
      onChange(event){
        const fileview = event.target.files[0]
        this.file = event.target.files[0]
        this.fileview = URL.createObjectURL(fileview)
        console.log(fileview)
      }, 
    getListCategory(){
      axios.get("category_report")
      .then(res => {this.listCategory = res.data})
      .catch(err => {console.log(err)})
    },
      async submitReport(){
        const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
         let encryptor = new JSEncrypt(); // New JSEncrypt Object
        let publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh3dKb3P9MAbMPij/CaVGmi0sg
onTq0ofRN8hx+6pfXErtXokw7Mtxns/DmDRlpsNcOZrgKdlT5OGu5XkITN7c/om3
bVnTZKYJnyABk0h3i3ecuVuyIkGFL4B2brs98+EBbaKFGTOYX2hhbwbPJV04//Vo
jFBLG0wm21UKu5N2RQIDAQAB`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
       let decrypt = new JSEncrypt(); // New JSEncrypt Object
       let decrypt2 = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      let privateKeyID = `MIICWwIBAAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv9LlLFAhvMArM60egsmU4jcUL
w8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX/EesghuvDYjG1YCYIfDKm4sd
NdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5URG8Q2M7D8WRHEqpvxAgMBAAEC
gYAdzV2u7I9+gY3GzVXkoQPnUfil4oycI2zawsRb4IwIOu0hOt2mViYnEiRZY0SL
heJiGtnQroi6cHSZKkoB+xjlmUb2oK2atmIJXLQKBkmVfn7ho6lJ7Eu+ux9ZDV1H
WcT2OZchSZz7oEaargMiu6A+HNBnXKkHr8dXjtphrw/EoQJBAM3W5Fucaaaa80fI
7a5PQ6+J4e1rFOn3y8IXx9r1KGwd2eoc7Zn5LonpI9XAp2v9bNBKvKo7mtn4fScA
wXGsFgUCQQCe6RaOPXHnAiAVlZ4JsVlpRjpueUlB2gKAGqfsgHVMPQWxOsE+kwKj
oyUyQa4VyXcMX9KFwE1jFJgc/bwvu8X9AkEAmOqvBTvi5Smx2D92C4vJPexSEl39
0H8NOWkPSSPd3swiaqEJBt0ubLftrkuMVVaU8vzCwji7pnjLCSGvlDICvQJAQrKM
cAkkLJR8yPJyUZkpcQEz9zha4hPR5tjJUH+eO/CE2h1SDKSBgBMywoREwAo+OGhj
oRXUpVh4e/qXqOVENQJAHEmeKGpj9IVyBLipW9ijaRa8/TXsx62LLvydTFoDenE5
eVglVTP1p8viOTzSNXXxOw6hQdyYWdswdGClZkvirw==`;

      decrypt.setPrivateKey(privateKey);
      decrypt2.setPrivateKey(privateKeyID);
      let data = new FormData();
       const today = new Date();
       const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      data.append("user_id", encryptor.encrypt(localStorage.getItem("id")));
      data.append("program_id", encryptor.encrypt(decrypt2.decrypt(localStorage.getItem('detail-program'))));
      data.append("summary", encryptor.encrypt(this.summary));
      data.append("scope_report", encryptor.encrypt(this.scope_report));
      data.append("date", encryptor.encrypt(date));
      data.append("category_id", this.category_id);
      data.append("impact",encryptor.encrypt(this.impact) );
      data.append("status_report", encryptor.encrypt('Diterima'));
      data.append("description_report", this.description_report);
      data.append("file", this.file);
      await axios
        .post("report", data, config)
        .then((res) => {
          console.log(res);
          this.$router.push("/laporan-peneliti-keamanan");
        })
        .catch((error) => {
          console.log(error);
        });
      },
      getProgram(){
          let decrypt = new JSEncrypt(); // New JSEncrypt Object
       let decrypt2 = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      let privateKeyID = `MIICWwIBAAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv9LlLFAhvMArM60egsmU4jcUL
w8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX/EesghuvDYjG1YCYIfDKm4sd
NdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5URG8Q2M7D8WRHEqpvxAgMBAAEC
gYAdzV2u7I9+gY3GzVXkoQPnUfil4oycI2zawsRb4IwIOu0hOt2mViYnEiRZY0SL
heJiGtnQroi6cHSZKkoB+xjlmUb2oK2atmIJXLQKBkmVfn7ho6lJ7Eu+ux9ZDV1H
WcT2OZchSZz7oEaargMiu6A+HNBnXKkHr8dXjtphrw/EoQJBAM3W5Fucaaaa80fI
7a5PQ6+J4e1rFOn3y8IXx9r1KGwd2eoc7Zn5LonpI9XAp2v9bNBKvKo7mtn4fScA
wXGsFgUCQQCe6RaOPXHnAiAVlZ4JsVlpRjpueUlB2gKAGqfsgHVMPQWxOsE+kwKj
oyUyQa4VyXcMX9KFwE1jFJgc/bwvu8X9AkEAmOqvBTvi5Smx2D92C4vJPexSEl39
0H8NOWkPSSPd3swiaqEJBt0ubLftrkuMVVaU8vzCwji7pnjLCSGvlDICvQJAQrKM
cAkkLJR8yPJyUZkpcQEz9zha4hPR5tjJUH+eO/CE2h1SDKSBgBMywoREwAo+OGhj
oRXUpVh4e/qXqOVENQJAHEmeKGpj9IVyBLipW9ijaRa8/TXsx62LLvydTFoDenE5
eVglVTP1p8viOTzSNXXxOw6hQdyYWdswdGClZkvirw==`;

      decrypt.setPrivateKey(privateKey);
      decrypt2.setPrivateKey(privateKeyID);
        axios
        .get("program/" + decrypt2.decrypt(localStorage.getItem('detail-program')))
        .then((res) => {
           (this.program = {
            id: decrypt.decrypt(res.data.id),
            nama: decrypt.decrypt(res.data.nama),
            user_id: decrypt.decrypt(res.data.user_id),
            program_name: decrypt.decrypt(res.data.program_name),
            date_start: decrypt.decrypt(res.data.date_start),
            date_end: decrypt.decrypt(res.data.date_end),
            scope: decrypt.decrypt(res.data.scope),
            status: decrypt.decrypt(res.data.status),
            type: decrypt.decrypt(res.data.type),
            category: decrypt.decrypt(res.data.category),
            price_1: decrypt.decrypt(res.data.price_1),
            price_2: decrypt.decrypt(res.data.price_2),
            price_3: decrypt.decrypt(res.data.price_3),
            price_4: decrypt.decrypt(res.data.price_4),
            price_5: decrypt.decrypt(res.data.price_5),
            program_image: res.data.program_image,
            description: res.data.description
            }) 
           console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      }
    }
}
</script>
<style scoped>
</style>
<template>
  <div id="login">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->

    <section id="login" class="login">
      <div class="container">
        <div class="row mt-2 align-items-center justify-content-center">
          <div class="col-lg-5 mt-4 mt-lg-5">
            <header class="section-header mb-3">
              <p>Kirim Konfirmasi Lupa Kata Sandi</p>
            </header>
            <div class="box border">
              <div class="">
                <div class="row gy-1">
                  <div v-if="loading" class="alert alert-danger" role="alert">
                    {{error}}
                  </div>
                  <!-- <form
                    class="php-email-form"
                    @submit.prevent="kirimEmail"> -->
                    <div class="row gy-4">
                      <div class="col-md-12">
                        <h3 class="mb-2 text-start">
                          Alamat Surat Elektronik
                        </h3>
                        <input
                          type="email"
                          class="form-control"
                          style="border-radius: 8px"
                          v-model="email"
                          required/>
                      </div>
                      <div class="col-md-12 mt-3 text-center">
                       
                        <!-- <button type="submit">Kirim</button> -->
                        <div class="col-md-12 text-center">
                        <button :disabled="loading === true" class="btn btn-danger mt-3" @click="kirimEmail">
    <!-- <img v-if="loading"  class="loading" src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt=""/>  -->
    <span v-if="loading">Tunggu Sebentar</span>
    <span v-else>Kirim Email</span>
    </button>
                        </div>
                        <!-- <a href="">
                                            <p class="mt-3">Belum punya Akun?</p>
                                        </a> -->
                      </div>
                    </div>
                  <!-- </form> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Section -->

    <!-- ======= Footer ======= -->
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
// import { VueRecaptcha } from "vue-recaptcha";
import axios from 'axios';
import JSEncrypt from "jsencrypt/bin/jsencrypt";
export default {
  name: "LupaPassword",
  components: {
    Footer,
    // VueRecaptcha,
  },
  data() {
    return {
        email: '',
        error: '',
        loading: false
    };
  },
  methods: {
    //   onCaptchaVerified() {
    //   console.log("wes dicentang");
    //   this.isVerified = true;
    // },
    // onCaptchaExpired: function () {
    //   this.isVerified = false;
    //   console.log("drung dicentang");
    //   this.$refs.recaptcha.reset();
    // },
   
    async kirimEmail(){
      this.loading=!false
          setTimeout(() =>{
            this.loading=!true
          },8000)
       let encryptor = new JSEncrypt(); // New JSEncrypt Object

      let publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh3dKb3P9MAbMPij/CaVGmi0sg
onTq0ofRN8hx+6pfXErtXokw7Mtxns/DmDRlpsNcOZrgKdlT5OGu5XkITN7c/om3
bVnTZKYJnyABk0h3i3ecuVuyIkGFL4B2brs98+EBbaKFGTOYX2hhbwbPJV04//Vo
jFBLG0wm21UKu5N2RQIDAQAB`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
      await axios.post('forgot_password',{
              email: this.email,
          })
      .then((res) => {
        this.error = "Berhasil";
        console.log(res);
        // this.$router.push('reset-password');
      })
      .catch((error) => {
        this.error = error;
        console.log(error);
      })
    }
  },
};
</script>
<style scoped>
.loading{
  width: 40px;
  width: 40px;
}
</style>

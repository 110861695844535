<template>
     <header id="header" class="header fixed-top">
      <div
        class="container-fluid container-xl d-flex align-items-center justify-content-between"
      >
      <div v-if="user">
        <router-link  v-if="user.roles === 'pemilik-sistem'" to="/halaman-utama-pemilik-sistem" class="logo d-flex align-items-center">
          <img src="img/topiputih.png" alt="" />
          <span>topiputih.id</span>
        </router-link>
        <router-link  v-if="user.roles === 'peneliti-keamanan'" to="/halaman-utama-peneliti-keamanan" class="logo d-flex align-items-center">
          <img src="img/topiputih.png" alt="" />
          <span>topiputih.id</span>
        </router-link>
        </div>
        <div v-if="!user">
          <router-link   to="/" class="logo d-flex align-items-center">
          <img src="img/topiputih.png" alt="" />
          <span>topiputih.id</span>
        </router-link>
        </div>

        <nav id="navbar" class="navbar" >
          <ul v-if="!user">
            <li class="dropdown">
              <a href="#"  @click="toogle3" :class="{'drop2': isActive2}"
                ><span>Layanan</span> <i class="bi bi-chevron-down"></i
              ></a>
              <ul>
                <li>
                  <a href="/vulnerability-disclosure"
                    >Vulnerability Disclosure</a
                  >
                </li>
                <li><a href="/bug-bounty">Bug Bounty</a></li>
                <li>
                  <a href="/penetration-testing">Penetration Testing</a>
                </li>
              </ul>
            </li>
            <li>
              <router-link class="nav-link scrollto" to="/program">Program</router-link>
            </li>
            <li>
              <router-link class="nav-link scrollto" to="/tentang-kami"
                >Tentang Kami</router-link>
            </li>
            <li>
              <router-link class="nav-link scrollto" to="/hubungi-kami">Hubungi Kami</router-link>
            </li>
            <li><a class="getstarted scrollto text-white" href="/masuk">Masuk</a></li>
          </ul>
           <ul v-if="user">
                   
                    <li>
                        <router-link  class="nav-link scrollto" to="/program">Program</router-link>
                    </li>
                    <li><a class="nav-link scrollto" v-if="user.roles === 'pemilik-sistem'" href="/daftar-laporan-pemilik-sistem">Laporan</a></li>
                    <li><a class="nav-link scrollto" v-if="user.roles === 'peneliti-keamanan'" href="/peringkat-peneliti-keamanan">Peringkat</a></li>
                    <li><a class="nav-link scrollto" v-if="user.roles === 'peneliti-keamanan'" href="/laporan-peneliti-keamanan">Laporan</a></li>
                    <li><a v-if="user.roles === 'pemilik-sistem'" class="nav-link scrollto" href="/pembayaran-pemilik-sistem">Pembayaran</a></li>
                    <li><a v-if="user.roles === 'peneliti-keamanan'" class="nav-link scrollto" href="/pembayaran-peneliti-keamanan">Pembayaran</a></li>
                    <li class="dropdown">
                        <a class="getstarted scrollto text-white" @click="toogle2" :class="{'drop': isActive1}"><span>Akun</span><i class="bi bi-chevron-down" ></i></a>
                        <ul>
                            <li><a  v-if="user.roles === 'peneliti-keamanan'" href="/pengaturan-akun-peneliti-keamanan">Pengaturan Akun</a></li>
                            <li><a  v-if="user.roles === 'pemilik-sistem'" href="/pengaturan-akun-pemilik-sistem">Pengaturan Akun</a></li>
                            <li><a href="javascript:void(0)" @click="handleClick">Keluar</a></li>
                        </ul>
                    </li>
                </ul>
          <i @click="toogle" class="bi mobile-nav-toggle bi-list" :class="{'bi-list': isBiList}"></i>
        </nav>
        <!-- .navbar -->
      </div>
    </header>
</template>

<script>
import $ from "jquery";
import {mapGetters} from 'vuex'

//   $(".bi-list").click(function(){
//     $(".navbar").addClass("navbar-mobile");
//     $(".bi").removeClass("bi-list")
//     $(".bi").addClass("bi-x")
//   });
//  $(".bi-x").click(function(){
//     console.log("aaaaaaaa")
//     $(".navbar").removeClass("navbar-mobile");
//     $(".bi").addClass("bi-list")
//     $(".bi").removeClass("bi-x")
//   })
// if($('.navbar')){
//   $(document).ready(function(){
//     $('.mobile-nav-toggle.bi-list').on('click', function(){
//       console.log('aaa')
//         // $(this).addClass('bi-x').siblings().removeClass('bi-list');
//         $(".navbar").addClass("navbar-mobile");
//       $(".bi").removeClass("bi-list")
//       $(".bi").addClass("bi-x")
//     });

//   })
// }else if($('.navbar.navbar-mobile')){
//   $(document).ready(function(){
    
//     $('.mobile-nav-toggle.bi-x').on('click', function(){
//       console.log('bbb')
//         // $(this).addClass('bi-x').siblings().removeClass('bi-list');
//             $(".navbar").removeClass("navbar-mobile");
//       $(".bi").addClass("bi-list")
//       $(".bi").removeClass("bi-x")
//     });
//   })
// }


export default {
    name: 'HeaderLanding',
    components:{ 
    },
    data(){
      return{
        isBiList: true,
        isActive1: true,
        isActive2: true,
      }
    },
    methods:{
      handleClick(){
        localStorage.removeItem('token');
        localStorage.removeItem('time');
        localStorage.removeItem('id');
        localStorage.removeItem('roles');
        this.$store.dispatch('user', null);
         window.location = '/'
      },
      toogle(){
        if(this.isBiList){
          this.isBiList = false;
            $(".navbar").addClass("navbar-mobile");
            $(".mobile-nav-toggle").addClass("bi-x")
             $(".mobile-nav-toggle").removeClass("bi-list")
        }else{
           this.isBiList = true;
                $(".navbar").removeClass("navbar-mobile");
              $(".mobile-nav-toggle").addClass("bi-list")
            $(".mobile-nav-toggle").removeClass("bi-x")
        }
      },
      toogle2(){
        if(this.isActive1){
          this.isActive1 = false;
            $("ul").addClass("dropdown-active");
        }else{
           this.isActive1 = true;
            $("ul").removeClass("dropdown-active");
        }
      },
       toogle3(){
        if(this.isActive2){
          this.isActive2 = false;
            $("ul").addClass("dropdown-active");
        }else{
           this.isActive2 = true;
            $("ul").removeClass("dropdown-active");
        }
      }
    },
    
    computed: {
        ...mapGetters(['user'])
    },
};
$(document).on("scroll", function () {
  if ($(window).scrollTop() > 10) {
    $(".header").addClass("backgroud-scrolling");
  } else {
    $(".header").removeClass("backgroud-scrolling");
  }
});


// $(document).ready(function () { 
//   // $(".bi-list").click(function(){
//   //   $(".navbar").addClass("navbar-mobile");
//   //   $(".mobile-nav-toggle").addClass("bi-x")
//   //   $(".mobile-nav-toggle").removeClass("bi-list")
//   // })
//  $(".bi-x").click(function(){
//     console.log("aaaaaaaa")
//     $(".navbar").removeClass("navbar-mobile");
//     $(".mobile-nav-toggle").addClass("bi-list")
//     $(".mobile-nav-toggle").removeClass("bi-x")
//   })
// });
 
 

</script>
<style scoped>
.backgroud-scrolling {
  background: white;
  padding: 15px;
  box-shadow: 0px 2px 20px rgb(1 41 112 / 10%);
}
#navbar a {
  color: #840000;
}

#navbar a.router-link-exact-active {
  color: #e10000;
}
.mobile-nav-toggle {
  color: #ca1001;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(1, 22, 61, 0.9);
  transition: 0.3s;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #ca1001;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #fb2e02;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #fb2e02;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

</style>
<template>
  <div id="pengaturanakunpenelitikeamanan">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->
    <section id="detail" class="detail">
      <div class="container">
        <div class="row mt-lg-4">
          <div class="col-lg-12 mt-lg-5">
            <div class="card">
              <div class="card-header" style="background-color: #e10000">
                <h3 class="text-white">Profil Peneliti Keamanan</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-4">
                    <div class="row justify-content-center">
                    <img v-if="userL[3] == 'default'" src="img/topiputih.png" class="rounded-circle" style="width: 250px; height: 225px" />
                  <img v-if="userL[3] != 'default'" :src="path+`img/profile_user/`+userL[3]" class="rounded-circle" style="width: 255px; height: 250px; object-fit: cover" />

                    <form @submit.prevent="submitUpload" enctype="multipart/form-data">
                  <div class="mb-3">
                    <label  class="form-label">Unggah Foto Profil</label>
                    <input type="file" v-on:change="onChange" class="form-control" required >
                  </div>
                  <button type="submit" class="btn btn-danger" style="margin-top:12px">Simpan Foto Profil</button>
                </form>
                    </div>
                  </div>
                  <div class="col-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label"
                          >Nama</label
                        >
                        <h4  class="fw-bold">{{userL[0]}}</h4>
                      </div>
                      <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label"
                          >Nomor HP</label
                        >
                        <h4  class="fw-bold">{{userL[2]}}</h4>
                      </div>
                      <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label"
                          >Alamat Surat Elektronik/<br>Nama Pengguna</label
                        >
                        <h4  class="fw-bold">{{userL[1]}}</h4>
                      </div>
                      <div class="mt-5">
                        <input type="hidden" name="" id="">
                      </div>
                      <div class="mt-5">
                          <router-link to="/perbarui-peneliti-keamanan" class="btn btn-danger" style="margin-top: 60px;">Ubah Profil</router-link>
                      </div>
                  </div>
                  <div class="col-4">
                      
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <div class="row col-lg-12 mt-lg-5">
          <div class="col-9">
            <div class="card">
              <div class="card-header" style="background-color: #e10000">
                <h3 class="text-white">Kata Sandi </h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div v-if="error" class="alert alert-danger" role="alert">
                    {{ error }}
                  </div>
                  <form @submit.prevent="submitPassword">
                    <div
                      class="mb-3"
                      :class="{ 'form-group--error': $v.passwordold.$error }"
                    >
                      <label for="exampleInputEmail1" class="form-label"
                        >Kata Sandi Saat Ini</label
                      >
                      <input
                        type="password"
                        required
                        class="form-control"
                        v-model.trim="$v.passwordold.$model"
                        
                      />
                    </div>
                    <div
                      class="mb-3"
                      :class="{ 'form-group--error': $v.password.$error }"
                    >
                      <label for="exampleInputPassword1" class="form-label"
                        >Kata Sandi Baru</label
                      >
                      <input
                        class="form-control"
                        type="password"
                        v-model.trim="$v.password.$model"
                        required
                      />
                      <div
                        class="ms-3 text-start text-danger"
                        v-if="
                          !$v.password.LowerCase ||
                          !$v.password.UpperCase ||
                          !$v.password.numberV ||
                          !$v.password.symbol ||
                          !$v.password.minLength
                        "
                      >
                        Kata Sandi harus memiliki min.:
                      </div>
                      <ul class="text-start text-danger">
                        <li v-if="!$v.password.LowerCase">
                          <div class="error">1 huruf kecil</div>
                        </li>
                        <li v-if="!$v.password.UpperCase">
                          <div class="error">1 huruf kapital</div>
                        </li>
                        <li v-if="!$v.password.symbol">
                          <div class="error">1 tanda baca/simbol</div>
                        </li>
                        <li v-if="!$v.password.numberV">
                          <div class="error">1 angka</div>
                        </li>
                        <li v-if="!$v.password.minLength">
                          <div class="error">
                            panjang
                            {{ $v.password.$params.minLength.min }} karakter
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="mb-3"
                      :class="{
                        'form-group--error': $v.password_confirm.$error,
                      }"
                    >
                      <label for="exampleInputPassword1" class="form-label"
                        >Konfirmasi Kata Sandi</label
                      >
                      <input
                        class="form-control"
                        type="password"
                        v-model.trim="$v.password_confirm.$model"
                        required
                      />
                      <div
                        class="error text-start text-danger"
                        v-if="!$v.password_confirm.sameAsPassword"
                      >
                        Kata sandi tidak cocok
                      </div>
                    </div>
                    <button type="submit" class="btn btn-danger" :disabled="this.$v.$invalid">Simpan</button>
                  </form>
                </div>
              </div>
            </div>
          
          </div>
          <div class="col-3">
            <div class="card text-center mb-4" style="width: 18rem;">
              <div class="card-body">
                <h3 class="card-title mb-4" style="color: #e10000;">Rekening Bank</h3>
                <p  v-if="bank[0]" class="card-text mb-1" >
                  {{bank[1]}}
                </p>
                <p  v-if="bank[0]" class="card-text mb-1" >
                  {{bank[2]}}
                </p>
                <p  v-if="bank[0]" class="card-text">
                  {{bank[3]}}
                </p>
                <p v-if="!bank[0]" class="card-text">Segera Lengkapi Informasi Rekening</p>
                <router-link to="/akun-bank-peneliti-keamanan" class="btn btn-danger" v-if="bank[0]">Ganti Rekening</router-link>
                <router-link to="/akun-bank-peneliti-keamanan" class="btn btn-danger" v-if="!bank[0]">Tambah Rekening</router-link>
              </div>
            </div>
            <div class="card text-center" style="width: 18rem;">
              <div class="card-body">
                <h3 class="card-title mb-4" style="color: #e10000">Sertifikat Keahlian <br>dan Penghargaan</h3>
                <p  v-if="certKeahlian.length > 0" class="card-text mb-1">
                  {{certKeahlian.length}} Sertifikat Keahlian
                </p>
                <p  v-if="certPenghargaan.length > 0" class="card-text">
                  {{certPenghargaan.length}} Sertifikat Penghargaan
                </p>
                <p  v-if="certKeahlian.length <= 0" class="card-text mb-1">
                  Belum Unggah Sertifikat Keahlian
                </p>
                <p  v-if="certPenghargaan.length <= 0" class="card-text">
                  Belum Unggah Sertifikat Penghargaan
                </p>
                <router-link to="/daftar-sertifikat-peneliti-keamanan" class="btn btn-danger mt-2">Unggah Sertifikat Baru</router-link>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { UpperCase, LowerCase, numberV, symbol } from "../../validator.js";
import axios from "axios";
import path from "../../path";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
import { mapGetters } from "vuex";
export default {
  name: "PengaturanAkunPenelitiKeamanan",
  components: {
    Footer,
  },
  data() {
    return {
      nama: "",
      nomor_telepon: "",
      passwordold: "",
      password: "",
      password_confirm: "",
      error: "",
      image:'',
      bank: [],
      certKeahlian: [],
      certPenghargaan: [],
      userL: [],
      listCertif: [],
      path: path
    };
  },
  validations: {
    passwordold: { required },
    password: {
      UpperCase,
      LowerCase,
      numberV,
      symbol,
      minLength: minLength(8),
    },
    password_confirm: { sameAsPassword: sameAs("password") },
  },
  created(){
    this.getBank(),
    this.getUserL(),
    this.getCert(),
    this.getCertKeahlian(),
    this.getCertPenghargaan()
  },
  methods: {
    onChange(e) {
                this.image = e.target.files[0];
            },
            
    async submitUpload(){
        //  let existingObj = this;

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                let data = new FormData();
                data.append('id', localStorage.getItem("id"));
                data.append('foto_pengguna', this.image);
              
         const response = await axios.post("update_profile",data, config).then((res) => {
          console.log(res)
          location.reload()
        }).catch((error) => {
          console.log(error)
        })
        console.log(response)
    },
    async submitPassword() {
      this.$v.$touch();
      let encryptor = new JSEncrypt(); // New JSEncrypt Object
      const password = this.passwordold;
      const password_new = this.password;
      let publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh3dKb3P9MAbMPij/CaVGmi0sg
onTq0ofRN8hx+6pfXErtXokw7Mtxns/DmDRlpsNcOZrgKdlT5OGu5XkITN7c/om3
bVnTZKYJnyABk0h3i3ecuVuyIkGFL4B2brs98+EBbaKFGTOYX2hhbwbPJV04//Vo
jFBLG0wm21UKu5N2RQIDAQAB`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
      // const password =this.password;

       await axios.post("edit_password", {
          id: encryptor.encrypt(localStorage.getItem("id")),
          password: encryptor.encrypt(password),
          password_new: encryptor.encrypt(password_new),
        })
        .then(res => {
          this.error = "Berhasil Ganti Password",
           (this.passwordold = ""),
          (this.password = ""),
          (this.password_confirm = "");
          console.log(res);
        })
        .catch(error => {
          this.error = "Password Lama Salah";
           console.log(error);
        })
    },
    async getBank(){
let decrypt = new JSEncrypt() // New JSEncrypt Object
          let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
 decrypt.setPrivateKey(privateKey)

// let getWord = decrypt.decrypt(secretWord) // Decrypt
    this.nama = localStorage.getItem("nama");
    this.nomor_telepon = localStorage.getItem("nomor_telepon");
    // console.log(localStorage.getItem("id"))
    axios.get("bank/"+localStorage.getItem("id"))
    .then((res) =>{
      this.bank = [
        this.bank = decrypt.decrypt(res.data.id), 
        this.bank = res.data.nama_bank, 
        this.bank = decrypt.decrypt(res.data.nama_rekening), 
        this.bank = decrypt.decrypt(res.data.nomor_rekening),
      ], 
       
      console.log(this.bank)} )
    .catch(error => console.log(error))
    },
    async getUserL(){
      let decrypt = new JSEncrypt() // New JSEncrypt Object
          let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
 decrypt.setPrivateKey(privateKey)
        axios.get("user/"+localStorage.getItem("id"))
    .then((res) =>{
      this.userL = [
        this.userL = decrypt.decrypt(res.data.nama),
        this.userL = decrypt.decrypt(res.data.email),
        this.userL = decrypt.decrypt(res.data.nomor_telepon),
        this.userL = res.data.foto_pengguna,
        this.userL = decrypt.decrypt(res.data.nama_pengguna)
      ],
      console.log(this.userL)
      } )
    .catch(error => console.log(error))
    },
    async getCert(){
       axios.get("cert/"+localStorage.getItem("id"))
    .then((res) =>{this.listCertif = res.data} )
    .catch(error => console.log(error))
    },
    async getCertKeahlian(){
   axios.get("cert_keahlian/"+localStorage.getItem("id"))
    .then((res) =>{this.certKeahlian = res.data} )
    .catch(error => console.log(error))
    },
    async getCertPenghargaan(){
 axios.get("cert_penghargaan/"+localStorage.getItem("id"))
    .then((res) =>{this.certPenghargaan = res.data} )
    .catch(error => console.log(error))
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style scoped></style>

<template>
  <div id="bugbounty">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <Hero :title="title" :subtitle="subtitle" :img="img" />
    <!-- End Hero -->
    <section id="features" class="features">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <p>Apa itu BUG BOUNTY PROGRAM ?</p>
        </header>

        <!-- Feature Tabs -->
        <div class="row feture-tabs" data-aos="fade-up">
          <div class="col-lg-6">
            <!-- Tabs -->
            <ul class="nav nav-pills mb-3">
              <li>
                <a class="nav-link active" id="btn1">Latar Belakang</a>
              </li>
              <li>
                <a class="nav-link" id="btn2">Tipe Program</a>
              </li>
              <li>
                <a class="nav-link" id="btn3">Cara Kerja</a>
              </li>
            </ul>
            <!-- End Tabs -->

            <!-- Tab Content -->
            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab1">
                <p>
                  Bug bounty adalah suatu program pencarian kerentanan / celah
                  keamanan pada suatu website/aplikasi yang diselenggarakan oleh
                  suatu perusahaan, dimana peneliti keamanan yang berhasil
                  menemukan dan melalui proses validasi akan diberikan sebuah
                  reward baik berupa sertifikat, hall of fame, hingga berupa
                  uang.
                </p>
              </div>
              <!-- End Tab 1 Content -->

              <div class="tab-pane fade show" id="tab2">
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h4>#1. Public Program</h4>
                </div>
                <p>
                  Program Bug Bounty Anda akan diterbitkan ke semua peneliti
                  keamanan. Ini akan memberikan kesempatan bagi ratusan peneliti
                  keamanan untuk menemukan kerentanan pada aplikasi Anda.
                </p>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h4>#2. Private Program</h4>
                </div>
                <p>
                  Program Bug Bounty Anda akan diterbitkan ke peneliti keamanan
                  yang mendapatkan undangan. Kami menyeleksi peneliti keamanan
                  yang berkompentensi untuk mengikuti bug bounty program pada
                  aplikasi yang menyimpan data sensitif.
                </p>
              </div>
              <!-- End Tab 2 Content -->

              <div class="tab-pane fade show" id="tab3">
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h5>
                    Peneliti keamanan mencari kerentanan pada aplikasi di
                    platform topiputih.id
                  </h5>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h5>
                    Peneliti keamanan mengirimkan laporan kerentanan ke
                    topiputih.id
                  </h5>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h5>topiputih.id segera melakukan validasi laporan</h5>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h5>
                    topiputih.id menyeleksi temuan yang kritis dan memberikan
                    rekomendasi
                  </h5>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h5>
                    Perusahaan menerima laporan dan rekomendasi dari tim
                    topiputih.id
                  </h5>
                </div>
              </div>
              <!-- End Tab 3 Content -->
            </div>
          </div>

          <div class="col-lg-6">
            <img src="assets/img/features-2.png" class="img-fluid" alt="" />
          </div>
        </div>
        <!-- End Feature Tabs -->
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Footer from "@/components/Footer.vue";
// import $ from "jquery";
// $(document).ready(function(){
// //   var btn1 = document.getElementById('btn1');
// //   var btn2 = document.getElementById('btn2');
// //   var btn3 = document.getElementById('btn3');
// $('#btn1').onclick(function(){
//    $("#tab1").show();
//     $("#tab2").hide();
//     $("#tab3").hide();
//     $("#btn1").addClass("active");
//     $("#btn2").removeClass("active");
//     $("#btn3").removeClass("active");
// });
// $('#btn2').onclick(function(){
//     $("#tab2").show();
//     $("#tab1").hide();
//     $("#tab3").hide();
//     $("#btn2").addClass("active");
//     $("#btn1").removeClass("active");
//     $("#btn3").removeClass("active");
// });
// $('#btn3').click(function(){
//       $("#tab3").show();
//     $("#tab1").hide();
//     $("#tab2").hide();
//     $("#btn3").addClass("active");
//     $("#btn2").removeClass("active");
//     $("#btn1").removeClass("active");
// });

// });
export default {
  name: "BugBounty",
  components: {
    Hero,
    Footer,
  },
  data() {
    return {
      title: "Bug Bounty",
      subtitle: `Kerja sama sukarela
             pemilik sistem dan peneliti keamanan
             untuk menemukan kerentanan sistem
             dengan penghargaan finansial
             sesuai kerentanan yang dilaporkan`,
      img: "img/icon-bug.png",
    };
  },
};
</script>
<style scoped></style>

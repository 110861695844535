<template>
    <div id="daftarlaporanpemiliksistem">
     
        <section id="features" class="features">
        <div class="container">
            <!-- Feature Tabs -->
            <div class="row feture-tabs ">
                <div class="col-lg-12 ">
                    <h3>Laporan</h3>
                    
                    <!-- Tab Content -->
                    <div class="tab-content">
                            <table id="programReport" class="table table-hover" style="width:100%">
                                <thead  class="text-white" style="background-color: #E10000;">
                                    <tr>
                                        <th scope="col">No</th>
                                        <th scope="col">Nama Peneliti Keamanan</th>
                                        <th scope="col">Tanggal </th>
                                        <th scope="col">Target</th>
                                        <th scope="col">Kategori Laporan</th>
                                        <th scope="col">Nama Program</th>
                                        <th scope="col">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                    </div>
                </div>
            </div><!-- End Feature Tabs -->
        </div>
    </section>
      <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import path from "../../path";
import router from '../../router'
import axios from 'axios' 
import moment from 'moment'
import JSEncrypt from "jsencrypt/bin/jsencrypt";
import $ from 'jquery'
export default {
    name: 'DaftarLaporanPemilikSistem',
    components:{
        Footer,
    },
    data(){
        return {
            path: path,
            program: []
        }
    },
    mounted(){
    this.getReportProgram()
    // this.getProgram()
    // this.getInvited()
    console.log(localStorage.getItem('id'))
    // location.reload()
     
    },
    methods:{
         getReportProgram(){
      axios.get("report_program/" + localStorage.getItem('id'))
      .then(response => {
        $('#programReport').DataTable(
        {
            "lengthMenu": [[5, 10, 25, -1], [5, 10, 25, "All"]],
            "order": [[0, 'asc']],
          data: response.data,
         columns: [
           {
              data: null,
              render: function (data,type,row,meta) {
                return meta.row + 1
              }
            },
           { data: 'nama' },
           { 
               data: 'date',
                render: function(data){
                return moment(data).format("DD-MM-YYYY")
              }
         },
           { 
               data: 'scope_report'
             },
            { 
              data: 'category',
              render: function(data,type,row){
                return data+`  -  `+row.detail
              }
              
            },
            {
              data: 'program_name'
            },
            {
                data: "id",
              render: function(data){
                return `<button class="btn btn-primary text-white" data-id=${data}" id="ubah">Detail</button> `
              },
            }
           ],
          });
           $(document).on(`click`, '#ubah', function(){
              let encryptor = new JSEncrypt(); // New JSEncrypt Object

      let publicKey = `MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv
9LlLFAhvMArM60egsmU4jcULw8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX
/EesghuvDYjG1YCYIfDKm4sdNdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5UR
G8Q2M7D8WRHEqpvxAgMBAAE=`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
                let id = $(this).data("id");
                  localStorage.setItem('detail-laporan-program-pemilik-sistem',encryptor.encrypt(String(id)))
                 router.push('/detail-laporan-program-pemilik-sistem')
                 })
          console.log(response.data);
            this.programL = response.data
      })
      .catch(error => {
        console.log(error.response)
      })
    },
//     getProgram(){
//        let decrypt = new JSEncrypt(); // New JSEncrypt Object
//       let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
// eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
// J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
// AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
// wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
// gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
// /usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
// e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
// wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
// YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
// 481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
// P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
// DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
//       decrypt.setPrivateKey(privateKey);
//           axios
//         .get("program/" +localStorage.getItem('id'))
//         .then((res) => {
//           (this.program = {
//             id: decrypt.decrypt(res.data.id),
//             name: decrypt.decrypt(res.data.name),
//             user_id: decrypt.decrypt(res.data.user_id),
//             program_name: decrypt.decrypt(res.data.program_name),
//             date_start: decrypt.decrypt(res.data.date_start),
//             date_end: decrypt.decrypt(res.data.date_end),
//             scope: decrypt.decrypt(res.data.scope),
//             status: decrypt.decrypt(res.data.status),
//             type: decrypt.decrypt(res.data.type),
//             category: decrypt.decrypt(res.data.category),
//             price_1: decrypt.decrypt(res.data.price_1),
//             price_2: decrypt.decrypt(res.data.price_2),
//             price_3: decrypt.decrypt(res.data.price_3),
//             price_4: decrypt.decrypt(res.data.price_4),
//             price_5: decrypt.decrypt(res.data.price_5),
//             program_image: res.data.program_image,
//             description: res.data.description
//             }) , console.log(res.data);
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
    }
}
</script>
<style scoped>

</style>
<template>
  <div id="daftarprogrampemiliksistem">
    <section id="detail" class="detail">
      <div class="container">
        <div class="row mt-lg-4">
          <div class="col-lg-12 mt-lg-5">
            <a href="/tambah-program-pemilik-sistem" class="btn btn-danger mb-3"
              >Tambah Program</a
            >
            <div class="card">
              <div
                class="card-header text-white"
                style="background-color: #e10000"
              >
                <h3 class="text-white">Daftar Program Pemilik Sistem</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <table id="programList" class="table table-bordered mt-2">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Nama Program</th>
                        <th scope="col">Tanggal Mulai</th>
                        <th scope="col">Tanggal Selesai</th>
                        <th scope="col">Tipe Program</th>
                        <th scope="col">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr
                        v-for="itemListProgram in listProgram"
                        v-bind:key="itemListProgram.id">
                        <td>{{ itemListProgram.program_name }}</td>
                        <td>{{ itemListProgram.company_name }}</td>
                        <td>{{ itemListProgram.price_5.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
                        <td>
                            <router-link class="btn btn-warning me-2" v-bind:to="`/edit-program-company/`+itemListProgram.id">Ubah</router-link>
                            <button class="btn btn-danger"  @click="deleteProgram(itemListProgram.id)">Hapus</button>
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
                <!-- <div class="row" v-if="listProgram.length <= 0">
                    <h5>Belum Unggah Sertifikat</h5>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import axios from "axios";
import router from "../../router";
import moment from "moment";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
import $ from "jquery";
export default {
  name: "DaftarProgramPemilikSistem",
  components: {
    Footer,
  },
  data() {
    return {
      listProgram: [],
    };
  },
  created() {
    this.getProgram();
  },
  methods: {
    async getProgram() {
      axios
        .get("program_list/" + localStorage.getItem("id"))
        .then((res) => {
          $("#programList").DataTable({
            data: res.data,
            columns: [
              {
                data: null,
                render: function (data, type, row, meta) {
                  return meta.row + 1;
                },
              },
              { data: "program_name" },
              {
                data: "date_start",
                render: function (data) {
                  return moment(data).format("DD-MM-YYYY");
                },
              },
              {
                data: "date_end",
                render: function (data) {
                  return moment(data).format("DD-MM-YYYY");
                },
              },
              { data: "type" },
              {
                data: "id",
                render: function (data) {
                  return `<button class="btn btn-danger"  data-id="${data}" id="delete">Hapus</button> <button class="btn btn-primary text-white" data-id=${data}" id="ubah">Detail</button> `;
                },
              },
            ],
          });
          console.log(res.data);
        })
        .catch((error) => console.log(error));
      //delete
      $(document).on(`click`, "#delete", function () {
        var con = confirm("Apa Yakin ingin menghapus");
        if (con) {
          let id = $(this).data("id");
          axios
            .delete(`program/` + id)
            .then((res) => {
              location.reload(), console.log(res);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
      $(document).on(`click`, "#ubah", function () {
        let encryptor = new JSEncrypt(); // New JSEncrypt Object

      let publicKey = `MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv
9LlLFAhvMArM60egsmU4jcULw8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX
/EesghuvDYjG1YCYIfDKm4sdNdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5UR
G8Q2M7D8WRHEqpvxAgMBAAE=`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
        const id = $(this).data('id');
        localStorage.setItem('laporanpemiliksistemID', encryptor.encrypt(String(id)))
        router.push('/detail-program-pemilik-sistem')
        // router.push({name: "detailProgramPemilikSistem", params: {id: id}});
      });
    },
    async deleteProgram(id) {
      try {
        await axios.delete(`program/${id}`);
        this.getProgram();
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {},
};
</script>

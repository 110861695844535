<template>
  <div id="editprogrampemiliksistem">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->
    <section id="detail" class="detail">
      <div class="container">
        <div class="row mt-lg-4">
          <div class="col-lg-12 mt-lg-5">
            <a class="btn btn-danger mb-3" href="/daftar-program-pemilik-sistem"
              >Daftar Program</a
            >
            <div class="card">
              <div
                class="card-header text-white"
                style="background-color: #e10000"
              >
                <h3 class="text-white">Ubah Program</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <form
                    @submit.prevent="submitProgram"
                    enctype="multipart/form-data"
                  >
                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-6">
                          <label class="form-label">Nama Program </label>
                          <input
                            type="text"
                            v-model="program_name"
                            class="form-control"
                            required
                          />
                        </div>
                        <div class="col-6">
                          <label class="form-label">Nama Perusahaan</label>
                          <input
                            type="text"
                            v-model="company_name"
                            class="form-control"
                            readonly
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-6">
                          <label class="form-label">Tanggal Mulai</label>
                          <input
                            type="date"
                            v-model="date_start"
                            class="form-control"
                            required
                          />
                        </div>
                        <div class="col-6">
                          <label class="form-label">Tanggal Selesai</label>
                          <input
                            type="date"
                            v-model="date_end"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-4">
                          <label class="form-label">Kategori</label>
                          <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                          <select
                            v-model="category"
                            class="form-control"
                            required
                          >
                            <option
                              v-for="item1 in categoryList"
                              v-bind:key="item1.id"
                              :value="item1.name"
                            >
                              {{ item1.name }}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <label class="form-label">Status</label>
                          <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                          <select
                            v-model="status"
                            class="form-control"
                            required
                          >
                            <option
                              v-for="item2 in statusList"
                              v-bind:key="item2.id"
                              :value="item2.name"
                            >
                              {{ item2.name }}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <label class="form-label">Tipe</label>
                          <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                          <select
                            v-model="type"
                            class="form-control"
                            required
                            id="target"
                          >
                            <option
                              v-for="item3 in typeList"
                              v-bind:key="item3.id"
                              :value="item3.name"
                            >
                              {{ item3.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3" id="hadiah">
                      <div class="row col-lg-12">
                        <label class="form-label"
                          >Hadiah Per-Tingkat Kerentanan</label
                        >
                        <div class="col-2 me-4">
                          <label class="form-label">Sangat Rendah</label>
                          <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                         
                          <vue-numeric   class="form-control" v-bind:minus="false" currency="Rp." separator="." v-model="price_1"></vue-numeric>
                        </div>
                        <div class="col-2 me-4 ms-4">
                          <label class="form-label">Rendah</label>
                          <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                           <vue-numeric   class="form-control" v-bind:minus="false" currency="Rp." separator="." v-model="price_2"></vue-numeric>
                        </div>
                        <div class="col-2 me-4 ms-3">
                           <label class="form-label">Sedang</label>
                          <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                           <vue-numeric   class="form-control" v-bind:minus="false" currency="Rp." separator="." v-model="price_3"></vue-numeric>
                        </div>
                        <div class="col-2 me-4 ms-3">
                          <label class="form-label">Tinggi</label>
                          <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                           <vue-numeric   class="form-control" v-bind:minus="false" currency="Rp." separator="." v-model="price_4"></vue-numeric>
                        </div>
                        <div class="col-2 ms-4">
                           <label class="form-label" style="margin-left: 4px"
                            >Sangat Tinggi</label>
                          <!-- <input type="number" v-model="phone_number" class="form-control" required > -->
                           <vue-numeric   class="form-control" v-bind:minus="false" currency="Rp." separator="." v-model="price_5"></vue-numeric>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-12">
                          <label class="form-label">Informasi Perusahaan</label>
                          <!-- <input
                        type="text"
                        v-model="description"
                        class="form-control"
                        required
                      /> -->
                          <ckeditor v-model="description" required></ckeditor>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row col-lg-12">
                        <div class="col-12">
                          <label class="form-label">Informasi Target</label>
                          <!-- <input
                        type="text"
                        v-model="scope"
                        class="form-control"
                        required
                      /> -->
                          <ckeditor v-model="scope" required></ckeditor>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="mb-3">
                   <div class="row col-lg-12">
                     <div class="col-12">
                        <label  class="form-label">Gambar Program</label><label
                      style="color: red;">*</label>
                    <input type="file" v-on:change="onChange" class="form-control" >
                     </div>
                   </div>
                  </div> -->
                    <button type="submit" class="btn btn-danger">Simpan</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import axios from "axios";
import $ from "jquery";
import VueNumeric from 'vue-numeric'
import JSEncrypt from "jsencrypt/bin/jsencrypt";
// $(document).ready(function () {
//   // var s = $('#target').val();
//   // console.log(s);
//   $("#hadiah").hide();
//   $("#target").on("change", function () {
//     //ways to retrieve selected option and text outside handler
//     if (this.value === "Bug Bounty") {
//       $("#hadiah").show();
//     } else {
//       $("#hadiah").hide();
//     }

//     // console.log('Changed option value ' + this.value);
//     // console.log('Changed option text ' + $(this).find('option').filter(':selected').text());
//   });
// });
export default {
  name: "EditProgramPemilikSistem",
  components: {
    Footer,
    VueNumeric
  },
  data() {
    return {
      program: [],
      id: "",
      program_name: "",
      company_name: "",
      date_start: "",
      date_end: "",
      description: "",
      scope: "",
      price_1: "",
      price_2: "",
      price_3: "",
      price_4: "",
      price_5: "",
      status: "",
      statusList: [
        { id: 1, name: "Aktif" },
        { id: 2, name: "Tidak Aktif" },
      ],
      category: "",
      categoryList: [
        { id: 1, name: "Publik" },
        { id: 2, name: "Privat" },
      ],
      type: "",
      typeList: [
        { id: 1, name: "Bug Bounty" },
        { id: 2, name: "Vulnerability Disclosure" },
        { id: 3, name: "Penetration Testing" },
      ],
      // program_image: '',
      idProgram: localStorage.getItem('laporanpemiliksistemID'),
    };
  },
  methods: {
    onChange(e) {
      this.program_image = e.target.files[0];
    },
    async submitProgram() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      console.log(this.program_image);
       let encryptor = new JSEncrypt(); // New JSEncrypt Object
        let publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh3dKb3P9MAbMPij/CaVGmi0sg
onTq0ofRN8hx+6pfXErtXokw7Mtxns/DmDRlpsNcOZrgKdlT5OGu5XkITN7c/om3
bVnTZKYJnyABk0h3i3ecuVuyIkGFL4B2brs98+EBbaKFGTOYX2hhbwbPJV04//Vo
jFBLG0wm21UKu5N2RQIDAQAB`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key
      let data = new FormData();
      data.append("id", encryptor.encrypt(this.id));
      data.append("program_name", encryptor.encrypt(this.program_name));
      // data.append("company_name", this.company_name);
      data.append("date_start", encryptor.encrypt(this.date_start));
      data.append("date_end", encryptor.encrypt(this.date_end));
      data.append("description", this.description);
      data.append("scope", encryptor.encrypt(this.scope));
      data.append("price_1", this.price_1);
      data.append("price_2", this.price_2);
      data.append("price_3", this.price_3);
      data.append("price_4", this.price_4);
      data.append("price_5", this.price_5);
      data.append("category",encryptor.encrypt(this.category));
      data.append("status", encryptor.encrypt(this.status));
      data.append("type", encryptor.encrypt(this.type));
      // data.append('program_image', this.program_image);
      await axios
        .post("update_program", data, config)
        .then((res) => {
          console.log(res);
          this.$router.push("/daftar-program-pemilik-sistem/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
        let decrypt = new JSEncrypt(); // New JSEncrypt Object
       let decrypt2 = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      let privateKeyID = `MIICWwIBAAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv9LlLFAhvMArM60egsmU4jcUL
w8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX/EesghuvDYjG1YCYIfDKm4sd
NdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5URG8Q2M7D8WRHEqpvxAgMBAAEC
gYAdzV2u7I9+gY3GzVXkoQPnUfil4oycI2zawsRb4IwIOu0hOt2mViYnEiRZY0SL
heJiGtnQroi6cHSZKkoB+xjlmUb2oK2atmIJXLQKBkmVfn7ho6lJ7Eu+ux9ZDV1H
WcT2OZchSZz7oEaargMiu6A+HNBnXKkHr8dXjtphrw/EoQJBAM3W5Fucaaaa80fI
7a5PQ6+J4e1rFOn3y8IXx9r1KGwd2eoc7Zn5LonpI9XAp2v9bNBKvKo7mtn4fScA
wXGsFgUCQQCe6RaOPXHnAiAVlZ4JsVlpRjpueUlB2gKAGqfsgHVMPQWxOsE+kwKj
oyUyQa4VyXcMX9KFwE1jFJgc/bwvu8X9AkEAmOqvBTvi5Smx2D92C4vJPexSEl39
0H8NOWkPSSPd3swiaqEJBt0ubLftrkuMVVaU8vzCwji7pnjLCSGvlDICvQJAQrKM
cAkkLJR8yPJyUZkpcQEz9zha4hPR5tjJUH+eO/CE2h1SDKSBgBMywoREwAo+OGhj
oRXUpVh4e/qXqOVENQJAHEmeKGpj9IVyBLipW9ijaRa8/TXsx62LLvydTFoDenE5
eVglVTP1p8viOTzSNXXxOw6hQdyYWdswdGClZkvirw==`;

      decrypt.setPrivateKey(privateKey);
      decrypt2.setPrivateKey(privateKeyID);
    axios
      .get("program/" + decrypt2.decrypt(this.idProgram))
      .then((res) => {
        (this.program = res.data),
          (this.id =  decrypt.decrypt(res.data.id)),
          (this.program_name =  decrypt.decrypt(res.data.program_name)),
          (this.company_name =  decrypt.decrypt(res.data.nama)),
          (this.date_start =  decrypt.decrypt(res.data.date_start)),
          (this.date_end =  decrypt.decrypt(res.data.date_end)),
          (this.description = res.data.description),
          (this.scope =  decrypt.decrypt(res.data.scope)),
          (this.price_1 =  decrypt.decrypt(res.data.price_1)),
          (this.price_2 =  decrypt.decrypt(res.data.price_2)),
          (this.price_3 =  decrypt.decrypt(res.data.price_3)),
          (this.price_4 =  decrypt.decrypt(res.data.price_4)),
          (this.price_5 =  decrypt.decrypt(res.data.price_5)),
          (this.status =  decrypt.decrypt(res.data.status)),
          (this.category =  decrypt.decrypt(res.data.category)),
          (this.type =  decrypt.decrypt(res.data.type));
          console.log(res.data)
        if ( decrypt.decrypt(this.type) === "Bug Bounty") {
          $("#hadiah").show();
        } else {
          $("#hadiah").hide();
        }
        $("#target").on("change", function () {
          //ways to retrieve selected option and text outside handler
          if (this.value === "Bug Bounty") {
            $("#hadiah").show();
            console.log(this.value)
          } else {
            $("#hadiah").hide();
          }

          // console.log('Changed option value ' + this.value);
          // console.log('Changed option text ' + $(this).find('option').filter(':selected').text());
        });
        // (this.program_image = res.data.program_image),
        // console.log(this.type);
      })
      .catch((error) => console.log(error));
  },
};
</script>

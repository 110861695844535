<template>
  <div id="HalamanUtamaPenelitiKeamanan">
    <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->
    <section id="detail" class="detail">
      <div class="container">
        <div class="row mt-lg-4">
          <div class="col-lg-12 mt-lg-5">
            <div class="box">
              <div class="row">
                <div class="col-md-3 justify-content-center border-end">
                  <div class="row justify-content-center">
                    <img
                      v-if="user.foto_pengguna == 'default'"
                      src="img/topiputih.png"
                      class="img-fluid detail-img-3 rounded-circle"
                      alt=""
                    />
                    <img
                      v-if="user.foto_pengguna != 'default'"
                      :src="path + `img/profile_user/` + user.foto_pengguna"
                      class="img-fluid detail-img-3 rounded-circle"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-md-5 justify-content-center border-end">
                  <div class="mt-lg-3 ms-3">
                    <h2 class="fw-bold" style="color: #e10000">
                      {{ user.nama }}
                    </h2>
                    <div class="row">
                      <div class="col-lg-8">
                        <h3 style="color: #807a7a">Peneliti Keamanan</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 justify-content-center">
                  <div class="mt-lg-3 ms-3">
                    <!-- <div class="col-lg-5">
                    <div class="form-check form-switch">
                      <label class="form-check-label" for="flexSwitchCheckChecked">Profil Pubik</label>
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
                    </div>
                  </div>
                  <p class="fs-12 fst-italic">Anda dapat mengubah profil anda ke publik agar orang lain dapat melihat
                    anda</p> -->
                    <div class="row mt-5">
                      <div class="col-lg-6 border-end">
                        <h3 class="text-center">Ranking Akun:</h3>
                        <h1 v-if="rank > 0"  class="fw-bolder text-center">{{rank}}</h1>
                        <h1 v-else  class="fw-bolder text-center">-</h1>
                      
                      </div>
                      <div class="col-lg-6">
                        <h3 class="text-center">Total Point:</h3>
                        <h1 class="fw-bolder text-center">{{point}}</h1>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row col-lg-12 mt-lg-5">
            <div class="col-9">
              <div class="card text-center">
                <div
                  class="card-header text-white"
                  style="background-color: #e10000">
                  <h3 class="text-white">
                    Mulai Jadi Peneliti Keamanan di topiputih.id
                  </h3>
                </div>
                <div class="card-body">
                  <h5 class="card-title">Apa kabar peneliti keamanan?</h5>
                  <p class="card-text">
                    Anda mendapat undangan dari perusahaan dalam melakukan penelitian terhadap keamanan sistem mereka, segera lihat daftar berikut : 
                  </p>
                  <div class="row mb-3 justify-content-center">
                    <div v-for="listInvited in invited" v-bind:key="listInvited.id" class="card ms-4" style="width: 14rem">
                      <img
                        :src="path+`img/program_image/`+listInvited.program_image"
                        class="card-img-top mt-2"
                        width="50px"
                        height="150px"
                        style="object-fit: cover"
                        alt="..."/>
                      <div class="card-body">
                        <h3 class="card-title">{{listInvited.program_name}}</h3>
                        <p class="card-text">{{listInvited.type}}</p>
                        <a @click="detail(listInvited.program_id)" class="btn btn-danger">Lihat Program</a>
                      </div>
                    </div>
                    <!-- <div class="col-3">
                    <img src="img/clients/banklampung.png" class="img-thumbnail border-0" alt="...">
                  </div>
                  <div class="col-3">
                    <img src="img/clients/banknagari.png" class="img-thumbnail border-0" alt="...">
                  </div>
                  <div class="col-3">
                    <img src="img/clients/banksumsel.png" class="img-thumbnail border-0" alt="...">
                  </div>
                  <div class="col-3">
                    <img src="img/clients/banksumut.png" class="img-thumbnail border-0" alt="...">
                  </div> -->
                  </div>

                  <!-- <a href="#" class="btn btn-danger">Lihat Semua Program</a> -->
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card text-center mb-2" style="width: 18rem">
                <div class="card-body">
                  <h3 class="card-title" style="color: #e10000">
                    Prosedur Peneliti Keamanan
                  </h3>
                  <p class="card-text fs-6">
                    Harap pahami terlebih dahulu prosedur dari topiputih.id
                  </p>
                  <a
                    :href="path + `pdf/prosedur_topi_putih.pdf`"
                    target="_blank"
                    class="btn btn-danger"
                    >Unduh Prosedur</a
                  >
                </div>
              </div>
              <div class="card text-center" style="width: 18rem">
                <div class="card-body">
                  <h3 class="card-title" style="color: #e10000">
                    Pembayaran Hadiah
                  </h3>
                  <p class="card-text fs-6">
                    Pemabayaran akan masuk ke dalam saldo topiputih.id sebelum
                    dapat ditarik.
                  </p>
                  <a
                    :href="path + `pdf/prosedur_topi_putih.pdf`"
                    target="_blank"
                    class="btn btn-danger"
                    >Lihat Disini</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import path from "../../path";
import axios from 'axios'
import { mapGetters } from "vuex";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
export default {
  name: "HalamanUtamaPenelitiKeamanan",
  components: {
    Footer,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      path: path,
      point: '',
      rank: [],
      invited: []
    };
  },
  mounted(){
    this.getInvited()
     this.getPoint()
     this.getRank()
  },
  methods:{
    detail(detailID){
          let encryptor = new JSEncrypt(); // New JSEncrypt Object

      let publicKey = `MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv
9LlLFAhvMArM60egsmU4jcULw8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX
/EesghuvDYjG1YCYIfDKm4sdNdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5UR
G8Q2M7D8WRHEqpvxAgMBAAE=`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key


      // localStorage.setItem('detail', encryptor.encrypt(String(detailID)));
      // localStorage.setItem('detail1', detailID);
      localStorage.setItem('detail-program',encryptor.encrypt(String(detailID)))
      this.$router.push('/detail-program')
    },
    getInvited(){
            axios.get("get_program/"+localStorage.getItem("id"))
            .then(res =>{ this.invited = res.data; console.log(res)})
            .catch(err =>{console.log(err)})
        },
        getPoint(){
          axios.get("point_user/"+localStorage.getItem("id"))
          .then(res => {
            if(res.data != null){
              this.point = res.data
            }else{
              this.point = 0
            }
            console.log(res.data)})
          .catch(err => {console.log(err)})
        },
        getRank(){
          axios.get("get_rank/"+localStorage.getItem("id"))
          .then(res => {
            if(res.data === null){
              console.log('gk ada')
            }
            this.rank = res.data[0].ranking
            ,console.log(res.data[0].ranking)})
          .catch(err => {console.log(err)})
        },
  }
};
</script>

<style scoped></style>

<template>
  <div id="detailprogrampemiliksistem">
    <section id="detail" class="detail">
      <div class="container">
        <div class="row mt-lg-4">
          <div class="col-lg-12 mt-lg-5">
            <div class="card">
              <div class="card-header" style="background-color: #e10000">
                <h3 class="text-white">Detail Program</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-4">
                    <div class="row justify-content-center mb-3">
                      <img
                        :src="
                          program.program_image === 'default'
                            ? path + 'img/program_image/topiputih.png'
                            : path +
                              'img/program_image/' +
                              program.program_image
                        "
                        class="img-fluid detail-img-2"
                        alt=""
                      />
                    </div>
                    <form
                      @submit.prevent="submitUpload"
                      enctype="multipart/form-data"
                    >
                      <div class="mb-3">
                        <label class="form-label">Unggah Gambar Program</label>
                        <input
                          type="file"
                          v-on:change="onChange"
                          class="form-control"
                          required
                        />
                      </div>
                      <button
                        id="btn1"
                        type="submit"
                        class="btn btn-danger"
                        style="margin-top: 5px"
                      >
                        Simpan Gambar Program
                      </button>
                    </form>
                  </div>
                  <div class="col-8">
                    <div class="row">
                      <div class="col-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label"
                            >Nama Program</label
                          >
                          <h5 class="fw-bold">{{ program.program_name }}</h5>
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword1" class="form-label"
                            >Tipe Program</label
                          >
                          <h5 class="fw-bold">{{ program.type }}</h5>
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword1" class="form-label"
                            >Kategori</label
                          >
                          <h5 class="fw-bold">{{ program.category }}</h5>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label"
                            >Nama Perusahaan</label
                          >
                          <h5 class="fw-bold">{{ program.nama }}</h5>
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword1" class="form-label"
                            >Tanggal Program</label
                          >
                          <h5 class="fw-bold">
                            {{ moment(program.date_start).format('D-MM-YYYY') }} - {{ moment(program.date_end).format('D-MM-YYYY') }}
                          </h5>
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword1" class="form-label"
                            >Status</label
                          >
                          <h5 class="fw-bold">{{ program.status }}</h5>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <router-link
                        :to="`/ubah-program-pemilik-sistem`"
                        class="btn btn-danger mt-3"
                        >Ubah Detail Program</router-link
                      >
                       <a
                        v-if="program.category === 'Privat'"
                        :href="`/undang-peneliti-keamanan`"
                        class="btn btn-danger mt-3"
                        style="margin-left: 193px"
                        >Undang Peneliti Keamanan</a>
                    </div>
                  </div>
                  <div class="card-body border-top mt-2">
                    <p class="card-text fw-bold">Informasi Perusahaan</p>
                    <p v-html="program.description"></p>
                  </div>
                  <div class="card-body border-top mt-2">
                    <p class="card-text fw-bold">Informasi Target</p>
                   <p>
                      Target yang termasuk dalam program ini adalah aplikasi
                      berbasis web dengan URL :
                    </p>
                    <p v-html="program.scope"></p>
                    <br />
                    <p>
                      Uji penetrasi dilakukan secara black box dan tidak akan
                      diberikan akses melebihi apa yang sudah dimiliki oleh para
                      Bug Bounty Hunters saat ini sebagai peneliti keamanan
                      (security researcher) yang sudah terdaftar.
                    </p>
                    <h3>ATURAN MAIN</h3>
                    <p>
                      Perlaporan harus melalui fitur ‘Kirim Laporan‘ pada
                      aplikasi web RedStorm dengan menyertakan bukti serta cara
                      melakukan eksploitasi. Tingkat risiko dari temuan dapat
                      dipilih dan dipertimbangkan oleh peneliti dengan
                      ketentuan:
                    </p>
                    <table class="table table-bordered">
                      <thead
                        class="text-white"
                        style="background-color: #e10000"
                      >
                        <tr>
                          <th scope="col">Tingkat Kerentanan</th>
                          <th scope="col">Jumlah Poin</th>
                          <th v-if="program.type === 'Bug Bounty'" scope="col">
                            Jumlah Hadiah
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Sangat Rendah</th>
                          <td>12,5 Poin</td>
                          <td v-if="program.type === 'Bug Bounty'">
                            Rp.{{
                              program.price_1
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Rendah</th>
                          <td>25 Poin</td>
                          <td v-if="program.type === 'Bug Bounty'">
                            Rp.{{
                              program.price_2
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Sedang</th>
                          <td>37,5 Poin</td>
                          <td v-if="program.type === 'Bug Bounty'">
                            Rp.{{
                              program.price_3
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Tinggi</th>
                          <td>62,5 Poin</td>
                          <td v-if="program.type === 'Bug Bounty'">
                            Rp.{{
                              program.price_4
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Sangat Tinggi</th>
                          <td>100 Poin</td>
                          <td v-if="program.type === 'Bug Bounty'">
                            Rp.{{
                              program.price_5
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h3>PANDUAN PERILAKU (GUIDELINES FOR CONDUCT)</h3>
                    <p>
                      RedStorm ingin menciptakan lingkungan usaha yang aman
                      serta menjunjung tinggi etika profesionalisme.<br />
                      Untuk itu, terdapat tiga aspek utama yang perlu
                      diperhatikan oleh setiap peneliti keamanan yang
                      berpartisipasi dalam program pada platform kami:
                    </p>
                    <ul>
                      <li>
                        KERAHASIAAN (CONFIDENTIALITY) — Semua kerentanan
                        keamanan yang dilaporkan tidak boleh bocor ke ruang
                        publik.
                      </li>
                      <li>
                        INTEGRITAS (INTEGRITY) — Hanya melaporkan permasalahan
                        keamanan yang dianggap valid dan tidak menggunakan
                        temuan kerentanan keamanan tersebut untuk tujuan
                        merusak. Apabila terdapat lebih dari 1 laporan mengenai
                        permasalahan keamanan yang sama, maka yang pertama
                        melaporkan yang akan dianggap valid.
                      </li>
                      <li>
                        PROFESIONALISME (PROFESSIONALISM) — Peneliti keamanan
                        bertanggung jawab penuh atas temuan yang dilaporkan dan
                        harus dapat dihubungi apabila diperlukan informasi
                        penjelas atau pelengkap lainnya.
                      </li>
                    </ul>
                    <h3>
                      KRITERIA LAPORAN YANG DITERIMA (ACCEPTANCE CRITERIA)
                    </h3>
                    <p>
                      <strong>Proses Pengiriman</strong><br />
                      Jika Anda yakin telah menemukan kerentanan keamanan
                      (security vulnerability), silakan mengajukan laporan untuk
                      program terkait melalui platform RedStorm.<br />
                      Setiap pengiriman laporan kerentanan keamanan akan
                      diperlakukan dengan prioritas tinggi, termasuk ketika
                      laporan divalidasi, ketika kami membutuhkan lebih banyak
                      informasi dari Anda, atau ketika Anda memenuhi syarat
                      untuk mendapatkan hadiah.
                    </p>
                    <p>
                      Setiap pengiriman dievaluasi oleh kami atas dasar first-in
                      first-served..
                    </p>
                    <p>
                      <strong>Peraturan Dasar</strong><br />
                      RedStorm berkomitmen untuk melindungi kepentingan para
                      peneliti keamanan. <br />
                      Semakin perilaku Anda mengikuti aturan ini, semakin kami
                      dapat melindungi Anda jika terjadi situasi pengungkapan
                      yang tidak mudah.
                    </p>
                    <p>
                      Setiap program dapat memiliki peraturan yang bervariasi.
                      <br />
                      Harap baca dengan cermat Ringkasan Program untuk peraturan
                      khusus, jika ada. <br />
                      Hanya saja, sejumlah peraturan berikut ini berlaku untuk
                      semua program:
                    </p>
                    <ul>
                      <li>
                        Penelitian harus dilakukan hanya pada sistem yang
                        terdaftar di bawah bagian ‘Pengarahan‘ pada Program
                        Brief. Sistem selain itu adalah di luar ruang lingkup.
                      </li>
                      <li>
                        Kecuali jika dinyatakan lain dalam Ringkasan Program,
                        Anda harus membuat akun pengujian untuk tujuan
                        penelitian.
                      </li>
                      <li>
                        Pengajuan harus dilakukan secara EKSKLUSIF melalui
                        RedStorm untuk dapat dipertimbangkan sebagai hadiah.
                      </li>
                      <li>
                        Komunikasi terkait pengiriman harus tetap berada dalam
                        RedStorm atau saluran dukungan RedStorm resmi selama
                        proses pengungkapan (disclosure).
                      </li>
                      <li>
                        Tindakan yang mempengaruhi integritas atau mengganggu
                        stabilitas target program adalah dilarang. Jika Anda
                        melihat penurunan kinerja pada sistem target, Anda harus
                        segera menangguhkan semua penggunaan alat otomatis.
                      </li>
                      <li>
                        Pengajuan harus berdampak pada postur keamanan target.
                        Dampak berarti masalah yang dilaporkan mempengaruhi
                        pengguna target, sistem, atau keamanan data dengan cara
                        yang berarti. Pengirim dapat diminta untuk merahasiakan
                        dampak agar memenuhi syarat untuk mendapatkan hadiah.
                      </li>
                      <li>
                        Pengajuan dapat ditutup jika peneliti tidak responsif
                        terhadap permintaan informasi tambahan setelah 7 hari
                        kerja.
                      </li>
                      <li>
                        Keberadaan atau perincian program bersifat pribadi atau
                        undangan tidak boleh dikomunikasikan kepada siapa pun
                        yang bukan karyawan RedStorm atau karyawan yang
                        berwenang dari organisasi yang bertanggung jawab atas
                        program tersebut.
                      </li>
                      <li>
                        Kami mendorong para peneliti untuk menyertakan video
                        atau screenshot (proof-of-concept) dalam pelaporan
                        kerentanan keamanan. Semua file ini tidak boleh
                        dibagikan secara publik. Ini termasuk mengunggah ke
                        situs web apa pun yang dapat diakses publik (seperti
                        Vimeo, Imgur, dll.). Jika file melebihi 50 MB, unggahlah
                        file tersebut ke folder online yang aman seperti Dropbox
                        dengan dilindungi oleh kata sandi (password).
                      </li>
                      <li>
                        Kebijakan Pengungkapan RedStorm berlaku untuk semua
                        pengiriman yang dilakukan melalui platform RedStorm,
                        termasuk untuk laporan temuan yang bersifat Duplicate,
                        Out of Scope, dan Not Applicable. Pemilik program dapat
                        memilih Kebijakan Pengungkapan, Pengungkapan
                        Terkoordinasi, atau Pengungkapan Informasi Khusus untuk
                        diterapkan pada ringkasan program mereka. Silakan lihat
                        Kebijakan Pengungkapan Tambahan kami untuk detail
                        tentang Kebijakan Pengungkapan Publik yang berbeda di
                        RedStorm.
                      </li>
                      <li>
                        Jika seorang peneliti ingin mempertahankan hak
                        pengungkapan untuk kerentanan yang berada di luar ruang
                        lingkup cakupan suatu program, dia harus melaporkan
                        masalah tersebut ke vendor terkait secara langsung.
                        RedStorm dapat membantu peneliti dalam mengidentifikasi
                        alamat email yang sesuai untuk dihubungi. Pemilik
                        program didorong untuk memastikan bahwa cakupan program
                        mereka telah mencakup semua komponen penting yang ingin
                        mereka terima laporan kerentanannya.
                      </li>
                      <li>
                        Pelanggaran terhadap kebijakan pengungkapan yang
                        dinyatakan oleh program dapat mengakibatkan tindakan
                        penegakan sebagaimana diuraikan dalam Ketentuan Layanan
                        RedStorm.
                      </li>
                    </ul>
                    <p>
                      Pelanggaran terhadap peraturan ini dapat mengakibatkan
                      pembatalan pengiriman dan penyitaan semua hadiah, baik
                      untuk program yang saat ini berjalan maupun untuk masa
                      mendatang di platform RedStorm.
                    </p>
                    <p>
                      <strong>Jenis Kiriman yang Dikecualikan</strong><br />
                      Beberapa jenis pengiriman dikecualikan karena mereka
                      berbahaya untuk dinilai, atau karena mereka memiliki
                      dampak keamanan yang rendah kepada pemilik program. Bagian
                      ini berisi masalah yang tidak dapat diterima oleh
                      RedStorm, yang akan segera ditandai sebagai tidak valid
                      dan tidak mendapatkan hadiah.
                    </p>
                    <ul>
                      <li>
                        Temuan dari pengujian fisik seperti akses kantor
                        (misalnya pintu terbuka, tailgating).
                      </li>
                      <li>
                        Temuan yang berasal dari rekayasa sosial atau social
                        engineering (misalnya phishing, vishing).
                      </li>
                      <li>
                        Temuan dari aplikasi atau sistem yang tidak terdaftar di
                        bagian ‘Target’.
                      </li>
                      <li>
                        Bug fungsional, UI, dan UX serta kesalahan ejaan (typo).
                      </li>
                      <li>
                        Kerentanan denial-of-service (DoS ataupun DDoS) pada
                        tingkat jaringan (network).
                      </li>
                      <li>
                        Jenis pengajuan umum yang tidak termasuk kualifikasi
                      </li>
                    </ul>
                    <p>
                      Beberapa jenis pengajuan kami nilai tidak memenuhi syarat
                      untuk hadiah karena mereka memiliki dampak keamanan yang
                      rendah bagi pemilik program. <br />
                      Di bawah ini adalah daftar masalah yang pada umumnya
                      dilaporkan dan dapat direproduksi, tetapi tidak memenuhi
                      syarat. <br />
                      Kami sangat menyarankan Anda untuk tidak melaporkan
                      masalah ini kecuali Anda dapat menunjukkan serangan yang
                      dapat dilakukan disertai dengan dampak keamanan yang valid
                      bagi pemilik program.
                    </p>
                    <ul>
                      <li>
                        Pesan kesalahan deskriptif (misalnya stack traces,
                        kesalahan aplikasi atau server).
                      </li>
                      <li>
                        Kode/halaman HTTP 404 atau kode/halaman non-200 HTTP
                        lainnya.
                      </li>
                      <li>
                        Pengungkapan spanduk (banner disclosure) pada layanan
                        umum.
                      </li>
                      <li>
                        Pengungkapan file publik atau direktori yang dikenal
                        (misalkan Robots.txt).
                      </li>
                      <li>
                        Click-jacking dan masalah yang hanya dieksploitasi
                        melaluinya.
                      </li>
                      <li>
                        CSRF pada formulir yang tersedia untuk pengguna anonim
                        (misalnya formulir kontak).
                      </li>
                      <li>Login/Logout CSRF.</li>
                      <li>
                        Kehadiran aplikasi atau fungsi ‘pelengkap otomatis‘ atau
                        ‘simpan sandi’ pada web browser.
                      </li>
                      <li>Kurangnya flag Secure dan HttpOnly pada cookie.</li>
                      <li>
                        Kurangnya speed bump keamanan saat meninggalkan situs
                        web.
                      </li>
                      <li>Captcha yang lemah atau mem-bypass captcha.</li>
                      <li>
                        Enumerasi nama pengguna melalui pesan kesalahan halaman
                        login.
                      </li>
                      <li>
                        Enumerasi nama pengguna melalui pesan ‘Lupa Kata Sandi‘.
                      </li>
                      <li>
                        Brute force pada halaman login atau pada halaman lupa
                        kata sandi halaman serta mengakibatkan account lockout.
                      </li>
                      <li>Opsi HTTP TRACE yang diaktifkan.</li>
                      <li>
                        Serangan SSL seperti BEAST, BREACH, atau sekisar
                        renegotiation vulnerability.
                      </li>
                      <li>SSL forward secrecy.</li>
                      <li>
                        SSL yang mendukung algoritma cipher suite tidak aman.
                      </li>
                      <li>Header MIME-Sniffing atau X-Content-Type-Options.</li>
                      <li>HTTP security header yang tidak ditemukan.</li>
                      <li>
                        EXIF Geolocation Data Not Stripped From Uploaded Images.
                      </li>
                      <li>
                        Exploitasi yang berhubungan dengan email
                        (SPF/DMARC/DKIM).
                      </li>
                      <li>
                        Hasil pemindaian otomatis (automated scanning)
                        menggunakan tool seperti vulnerability scanner (Nessus,
                        nikto, Qualys, OpenVAS, Core Impact, Acunetix, Nexpose,
                        SecureCheq, Retina, MBSA, HIAB, dan sebagainya) meskipun
                        hasilnya mencantumkan potensi risiko keamanan.
                      </li>
                    </ul>
                    <h3>IMBALAN PROGRAM (PROGRAM REWARDS)</h3>
                    <p>
                      Anda akan memenuhi syarat untuk hadiah jika Anda adalah
                      orang pertama yang melaporkan untuk masalah yang
                      sebelumnya tidak diketahui DAN menyangkut kerentanan pada
                      kode atau konfigurasi yang dapat berdampak pada perubahan
                      kode, konfigurasi, atau data.<br />
                      Pengiriman imbalan akan dilakukan berkala setiap sebulan
                      sekali.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
    >
      <i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import path from "../../path";
import axios from "axios";
import moment from 'moment'
import JSEncrypt from "jsencrypt/bin/jsencrypt";
export default {
  name: "DetailProgramPemilikSistem",
  components: {
    Footer,
  },
  data() {
    return {
      program: [],
      program_image: "",
      moment : moment,
      path: path,
    };
  },
  created() {
    this.getProgram();
  },
  mounted(){
     let data = this.$route.params.id;
    console.log("data is", data);
  },
  methods: {
    onChange(e) {
      this.program_image = e.target.files[0];
    },
    async submitUpload() {
      //  let existingObj = this;

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let data = new FormData();
      data.append("id", localStorage.getItem('laporanpemiliksistemID'));
      data.append("program_image", this.program_image);

      const response = await axios
        .post("program_image", data, config)
        .then((res) => {
          console.log(res);
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(response);
    },
    getProgram() {
          let decrypt = new JSEncrypt(); // New JSEncrypt Object
       let decrypt2 = new JSEncrypt(); // New JSEncrypt Object
      let privateKey = `MIICXAIBAAKBgQDhDrtXz/3Lh3hpjL8NkQPmYgG5ESmCCTcWTvs9lTz7Ztt0P/3C
eeOb7doK4BdOWAHGnwA9PSlnbN2GqN+5Klac6bhZL0is6jJxsosTJEdVva7g8Ilq
J71z4Af8Y+RvODGt6m+YiP7Lv3WX8sh1iJbVSCMtNixRn+7lIbwEu8BynQIDAQAB
AoGAJpalDrBt/sjSn55vX78eoRDq5LWJnW9BD8UpFO0S0LRZKnySYRcPLLxCp5y2
wksautimbNmg0Mf8z8sC2TfmM5zzXLCYz4kqxuIG1Xeqa/0w3Bb7NPAN2PKpgxWp
gPukFeL83PCwcddi6X3vyrMFDYJaVjiWIrBzTiDI4xAVysECQQD/R/bRnlS6dRcb
/usnAFNlOIo9o5U6ft+SfZ+s+TLrKYgHgYUDWQUBPk0xyP4a4vjqBDchVLWYDKKi
e4Z7zViNAkEA4bD6pBKjDFou5Ztop97hjGiMmrgtv1IxyE0y1mPZ1ZW5sZE94j9E
wOMXy1Wc9F82zW/NE2Yal/Th1N2rOMymUQJBAPwEVINOdsD4tU67E/UV2j60Wa1O
YBU2iYr6H/fIsF7+Sj92uxS5/jnjApxim5F5f1ivvqlWEkxANRKJNhPkfkUCQGP2
481wg8+g0kzY/Q395qEuXbjaA0lOusYhi3byWzYDT3CbCHIB3A4jiBKEJTUIR1nP
P2JsQzMzQCPs2z4ZR0ECQFjWgcf1EudfzVZz25dHMHegUd4YHrpbSoyjAW41Etep
DIWNhAarTIvsFV4DkMEGFNsECAEj4YRTXkyL8hkmBx0=`;
      let privateKeyID = `MIICWwIBAAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv9LlLFAhvMArM60egsmU4jcUL
w8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX/EesghuvDYjG1YCYIfDKm4sd
NdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5URG8Q2M7D8WRHEqpvxAgMBAAEC
gYAdzV2u7I9+gY3GzVXkoQPnUfil4oycI2zawsRb4IwIOu0hOt2mViYnEiRZY0SL
heJiGtnQroi6cHSZKkoB+xjlmUb2oK2atmIJXLQKBkmVfn7ho6lJ7Eu+ux9ZDV1H
WcT2OZchSZz7oEaargMiu6A+HNBnXKkHr8dXjtphrw/EoQJBAM3W5Fucaaaa80fI
7a5PQ6+J4e1rFOn3y8IXx9r1KGwd2eoc7Zn5LonpI9XAp2v9bNBKvKo7mtn4fScA
wXGsFgUCQQCe6RaOPXHnAiAVlZ4JsVlpRjpueUlB2gKAGqfsgHVMPQWxOsE+kwKj
oyUyQa4VyXcMX9KFwE1jFJgc/bwvu8X9AkEAmOqvBTvi5Smx2D92C4vJPexSEl39
0H8NOWkPSSPd3swiaqEJBt0ubLftrkuMVVaU8vzCwji7pnjLCSGvlDICvQJAQrKM
cAkkLJR8yPJyUZkpcQEz9zha4hPR5tjJUH+eO/CE2h1SDKSBgBMywoREwAo+OGhj
oRXUpVh4e/qXqOVENQJAHEmeKGpj9IVyBLipW9ijaRa8/TXsx62LLvydTFoDenE5
eVglVTP1p8viOTzSNXXxOw6hQdyYWdswdGClZkvirw==`;

      decrypt.setPrivateKey(privateKey);
      decrypt2.setPrivateKey(privateKeyID);
      axios
        .get("program/" + decrypt2.decrypt(localStorage.getItem('laporanpemiliksistemID')))
        .then((res) => {
          (this.program = {
            id: decrypt.decrypt(res.data.id),
            nama: decrypt.decrypt(res.data.nama),
            user_id: decrypt.decrypt(res.data.user_id),
            program_name: decrypt.decrypt(res.data.program_name),
            date_start: decrypt.decrypt(res.data.date_start),
            date_end: decrypt.decrypt(res.data.date_end),
            scope: decrypt.decrypt(res.data.scope),
            status: decrypt.decrypt(res.data.status),
            type: decrypt.decrypt(res.data.type),
            category: decrypt.decrypt(res.data.category),
            price_1: decrypt.decrypt(res.data.price_1),
            price_2: decrypt.decrypt(res.data.price_2),
            price_3: decrypt.decrypt(res.data.price_3),
            price_4: decrypt.decrypt(res.data.price_4),
            price_5: decrypt.decrypt(res.data.price_5),
            program_image: res.data.program_image,
            description: res.data.description
            }) 
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped></style>

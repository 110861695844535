<template>
    <div id="program">
         <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
      <Hero :title="title" :subtitle="subtitle" :img="img"  />
    <!-- End Hero -->
    <section id="pilih" class="pilih">
    <div class="container">
      <div class="row mt-2 align-items-center justify-content-center">
        <header class="section-header">
          <p>Daftar Program</p>
        </header>
        <div class="row gy-4 justify-content-left">
          <div class="col-lg-12">
            <div class="row col-lg-6">
            <div class="col-3">
          <span>Pilih Tipe :</span>
            </div>
            <!-- <input class="form-control" type="text" v-model="searchQuery" placeholder="Search" /> -->
            <div class="col-5">
            <select v-model="searchQuery" class="form-control" >
              <option v-for="item3 in typeList" v-bind:key="item3.id" :value="item3.name">
                  {{ item3.name }}
              </option>
            </select>

            </div>
          </div> 
          </div>  
          <div v-for="listprogram in resultQuery" v-bind:key="listprogram.id" class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member">
              <a @click="detail(listprogram.id)">
                <div class="m-2 row justify-content-center">
                  <img :src="listprogram.program_image === 'default' ? 'img/topiputih.png' : path+'img/program_image/'+listprogram.program_image" class="img-fluid  member-img-2" />
                </div>
                <div class="member-info ">
                  <span class="fw-bolder" style="color: red; font-size: 19px;">{{listprogram.program_name}}</span>
                  <div class="member-text-limit">
                    <p class="text-center">{{listprogram.type}} </p>
                  <!-- <p v-if="listprogram.price_1 != null" class="bi bi-coin mt-3 text-start"> Rp.{{listprogram.price_1.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")}} - Rp.{{listprogram.price_5.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")}} </p> -->
                  </div>
                  <a class="btn btn-danger"  @click="detail(listprogram.id)" > Detail <i class="bi bi-arrow-right"></i></a>

                </div>
              </a>
            </div>
          </div>
          <div v-if="resultQuery.length <= 0" class="col-lg-12">
            <div class="alert alert-secondary" role="alert">
              Tidak Ada Program Pada Kategori Yang Dipilih
            </div>
          </div>
       
        </div>
      </div>
    </div>
  </section>
   <!-- ======= Footer ======= -->
    <Footer/>

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
import path from "../path";
import JSEncrypt from "jsencrypt/bin/jsencrypt";
export default {
    name: 'Program',
    components:{
    Hero,
    Footer,
    },
  data() {
      return {
              title: "Program Publik",
              subtitle: "Mari membantu perusahaan menemukan kelemahan sistem mereka",
              img: "img/landing/program.png",
              searchQuery: 'Bug Bounty',
              program: [],
              // type: "",
              typeList: [
                      { id: 1, name: "Bug Bounty" },
                      { id: 2, name: "Vulnerability Disclosure" }
                        ],
              path: path
          
      }
  },
  created(){
    this.getProgram()
    
  },
  computed: {
    resultQuery(){
      if(this.searchQuery){
      return this.program.filter((listprogram)=>{
        return this.searchQuery.toLowerCase().split(' ').every(v => listprogram.type.toLowerCase().includes(v))
      })
      }else{
        return this.program;
      }
    }
  },
  methods:{
    detail(detailID){
       let encryptor = new JSEncrypt(); // New JSEncrypt Object

      let publicKey = `MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgH/GC6k4JOVBWldW3Qfrgpn5GXyv
9LlLFAhvMArM60egsmU4jcULw8wFapsS9PXB/+7N7JCz9Qewqx46Igj+ve6jHwlX
/EesghuvDYjG1YCYIfDKm4sdNdaL2mpda7aw9TeKJiAjLahFzVCCzC+A0mhrD5UR
G8Q2M7D8WRHEqpvxAgMBAAE=`; //Paste in previous generations, which can be passed in from the background during actual development

      encryptor.setPublicKey(publicKey); // Set Public Key


      // localStorage.setItem('detail', encryptor.encrypt(String(detailID)));
      // localStorage.setItem('detail1', detailID);
      localStorage.setItem('detail-program',encryptor.encrypt(String(detailID)))
      this.$router.push('/detail-program')
    },
    getProgram(){
      axios.get("program")
      .then(res =>{ this.program = res.data, console.log(res.data)})
      .catch(error =>{ console.log(error)})
    }
  }
};
</script>
<style scoped>

</style>
<template>
    <div id="pilihregistrasi">
        <!-- ======= Header ======= -->
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <!-- End Hero -->
    
    <section id="pilih" class="pilih">
    <div class="container">
        <div class="row  mt-5 align-items-center justify-content-center">
            <header class="section-header">
                <p>Selamat Datang</p>
                <!-- <h2 class="mt-4">Silahkan memilih sesuai klasifikasi anda</h2> -->
            </header>
            <div class="row gy-4 justify-content-center">
                <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                    <div class="member">
                        <a href="/registrasi-pemilik-sistem">
                            <div class="member-img">
                                <img src="img/company.png" class="img-fluid" alt="" />
                            </div>
                            <div class="member-info ">
                                <h4>Pemilik Sistem</h4>
                                <a href="/registrasi-pemilik-sistem"  class="btn btn-danger mt-2">Daftar</a>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                    <div class="member">
                        <a href="/registrasi-peneliti-keamanan">
                            <div class="member-img">
                                <img src="img/hacker.png" class="img-fluid" alt="" />
                            </div>
                            <div class="member-info">
                                <h4>Peneliti Keamanan</h4>
                                <a  href="/registrasi-peneliti-keamanan"   class="btn btn-danger mt-2">Daftar</a>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    <!-- ======= Footer ======= -->
    <Footer/>

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
export default {
    name: 'PilihRegistrasi',
    components: {
    Footer,
    }
}
</script>
<style scoped>

</style>